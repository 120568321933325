import { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Navbar from './components/Navbar';
import Portfolio from './components/Portfolio';
import Asset from './components/Asset';
import Settings from './components/Settings';
import Auth from './components/Auth';
import { Toaster } from 'react-hot-toast';
import Strategies from './components/AiStrategies';
import DataLayer from './components/DataLayer/DataLayer';

function App() {
  ReactGA.initialize('G-50RJ4P43LD');
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const appHeightHandle = () => {
    document.querySelector('body')?.style.setProperty('--app-height', window.innerHeight + 'px');
  };

  window.addEventListener('resize', () => {
    if (window.innerWidth < 1200) {
      appHeightHandle();
    }
  });

  appHeightHandle();

  return (
    <>
      <Toaster />
      <Auth>
        <DataLayer>
          <Navbar />
          <main>
            <Routes>
              <Route path="*" element={<Portfolio />} />
              <Route path="/asset/:assetChain/:assetAddress" element={<Asset />} />
              <Route path="/ai-strategies" element={<Strategies />} />
              <Route path="/ai-strategies/:assetChain/:assetAddress" element={<Strategies />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/link/:app" element={<Settings />} />
            </Routes>
          </main>
          {/*<Footer />*/}
        </DataLayer>
      </Auth>
    </>
  );
}

export default App;
