import { FC, useEffect, useState } from 'react';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LanguageIcon from '@mui/icons-material/Language';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import telegramIcon from '../../assets/img/socials/socials-telegram-icon.svg';
import xIcon from '../../assets/img/socials/socials-x-icon.svg';
import discordIcon from '../../assets/img/socials/socials-discord-icon.svg';
import NoThumbnail from '../../assets/img/nocoin.png';
import EtherscanLogo from '../../assets/img/socials/etherscan-logo.svg';
import { TokenInfo } from '../../services/api/apes/interfaces';
import { formatNumber } from '../../utils/formatter';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import { toastError, toastSuccess } from '../../utils/toastUtils';
import { getChainscanTokenUrl } from '../../blockchain/utils';

interface AssetInfoProps {
  data: {
    info: TokenInfo | null;
    cards: {
      name: string;
      amount: string | number;
      coloredAmount?: boolean;
      increase?: boolean;
      change?: string;
      derisked?: boolean;
      isNumber?: boolean;
      prefix?: string;
    }[];
  };
}

const WidgetsAssetInfo: FC<AssetInfoProps> = ({ data }) => {
  const [activeTab, setActiveTab] = useState('position');
  const [chainscanUrl, setChainscanUrl] = useState<string | null>(null);
  const thumbnail = data?.info?.metadata.thumbnail || NoThumbnail;
  const symbol = data?.info?.metadata.symbol;
  const name = data?.info?.metadata.name;
  const website = data?.info?.metadata.website;
  const telegram = data?.info?.metadata.telegram;
  const discord = data?.info?.metadata.discord;
  const twitter = data?.info?.metadata.twitter;
  const tokenAddress = data?.info?.metadata.address;
  const chainId = data?.info?.metadata.chain;
  const { isCopied, copyError, copyToClipboard } = useCopyToClipboard();

  useEffect(() => {
    if (!tokenAddress || !chainId) {
      return;
    }

    const url = getChainscanTokenUrl(chainId, tokenAddress);

    if (url) {
      setChainscanUrl(url);
    }
  }, [tokenAddress, chainId]);

  useEffect(() => {
    if (isCopied) {
      toastSuccess('Token address copied to clipboard');
    }

    if (copyError) {
      toastError('Failed to copy token address');
    }
  }, [isCopied, copyError]);

  return (
    <>
      <div className="widgets-asset-headline-wrap">
        <div className="token-wrap">
          <div className="logo-wrap">
            <img src={thumbnail} alt="Logo" />
          </div>
          <div className="text-wrap">
            <div className="symbol-wrap">{symbol}</div>
            <div className="asset-name-wrap">{name}</div>
          </div>
        </div>
        <div className="tabs-wrap">
          <div
            className={`item-tab ${activeTab === 'position' ? 'active' : ''}`}
            onClick={() => setActiveTab('position')}>
            Your Position
          </div>
          <div
            className={`item-tab disabled ${activeTab === 'ideas' ? 'active' : ''}`}
            onClick={() => setActiveTab('ideas')}>
            Ideas
          </div>
        </div>
        <div className="socials-wrap">
          {chainscanUrl && (
            <a href={chainscanUrl} target="_blank" rel="noreferrer">
              <img src={EtherscanLogo} alt="Etherscan" />
            </a>
          )}
          {telegram && (
            <a href={telegram} target="_blank" rel="noreferrer">
              <img src={telegramIcon} alt="Telegram" />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noreferrer">
              <img src={xIcon} alt="X" />
            </a>
          )}
          {discord && (
            <a href={discord} target="_blank" rel="noreferrer">
              <img src={discordIcon} alt="Discord" />
            </a>
          )}
          {website && (
            <a href={website} target="_blank" rel="noreferrer">
              <span className="social-icon">
                <LanguageIcon />
              </span>
            </a>
          )}
          {tokenAddress && (
            <span className="social-icon-button" onClick={() => copyToClipboard(tokenAddress)}>
              <CopyAllIcon />
            </span>
          )}
        </div>
      </div>
      <div className="widgets-asset-tabs-content-wrap">
        {activeTab === 'position'
          ? data?.cards.map((widget, index) => {
              const { name, amount, coloredAmount, increase, change, derisked, isNumber, prefix } = widget;

              let value = amount;
              let sub: number | undefined = 0;
              let rest: number | undefined = 0;

              if (isNumber) {
                ({ value, sub, rest } = formatNumber(amount));
              }

              return (
                <div className="widget-wrap widget-asset-info-wrap" key={index}>
                  <div className="name-wrap">{name}</div>
                  <div className="details-wrap">
                    {!isNumber && (
                      <div
                        className={`amount-wrap ${coloredAmount ? (amount.toString().includes('+') ? 'positive' : 'negative') : ''}`}>
                        {amount}
                      </div>
                    )}
                    {isNumber && (
                      <div className="amount-wrap">
                        <span>
                          {prefix}
                          {value}
                          {sub ? <sub>{sub}</sub> : ''}
                          {rest ? rest : ''}
                        </span>
                      </div>
                    )}
                    {change ? (
                      <div className={`change-wrap ${increase ? 'increase' : 'reduce'}`}>
                        {increase ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        {change}
                      </div>
                    ) : null}
                    {derisked ? <div className="derisked-wrap">Derisked</div> : null}
                  </div>
                </div>
              );
            })
          : null}
        {activeTab === 'ideas' ? (
          <div className="widget-wrap">
            <div className="name-wrap">Ideas tab content</div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default WidgetsAssetInfo;
