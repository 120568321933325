import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { RootState } from '../store/store';
import SearchModal from './SearchModal';
import logo from '../assets/img/logo.svg';
import searchIcon from '../assets/img/search-icon.svg';
import walletIcon from '../assets/img/wallet-icon.svg';
import connectIcon from '../assets/img/connect-wallet-icon.svg';
import arrowIcon from '../assets/img/dropdown-arrow-icon.svg';
import openIcon from '../assets/img/burger-open-icon.svg';
import closeIcon from '../assets/img/burger-close-icon.svg';
import { formatWalletAddress, tierToDisplayText } from '../utils/formatter';
import { selectUserMasterWalletAddress, selectUserProfile } from '../store/slices/userSlice';

const Navbar: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  const [isResourcesOpened, setIsResourcesOpened] = useState(false);
  const [isMobileNavbarOpened, setIsMobileNavbarOpened] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [formattedWalletAddress, setFormattedWalletAddress] = useState('');
  const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
  const walletAddress = useSelector(selectUserMasterWalletAddress);
  const userProfile = useSelector(selectUserProfile);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (walletAddress) {
      setFormattedWalletAddress(
        formatWalletAddress(walletAddress, {
          numChars: 3,
          separator: '...',
          toLowerCase: true,
          toChecksum: false,
          ignorePrefix: true,
        }),
      );
    }
  }, [walletAddress]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as Node)) {
        setIsResourcesOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownMenuRef]);

  return (
    <>
      <header>
        <nav>
          <div className={`nav-wrap ${isMobileNavbarOpened ? 'opened' : ''}`}>
            <div className="close-wrap" onClick={() => setIsMobileNavbarOpened(false)}>
              <img src={closeIcon} alt="Close" />
            </div>
            <Link to="/" className="logo-wrap">
              <img src={logo} alt="Logo" />
            </Link>
            <div className="links-wrap">
              <Link to="/" className={`link-item ${pathname === '/' || pathname.includes('/asset/') ? 'active' : ''}`}>
                <span className="text">Portfolio</span>
              </Link>
              <Link to="/ai-strategies" className={`link-item ${pathname.includes('/ai-strategies') ? 'active' : ''}`}>
                <span className="text">Strategies</span>
              </Link>
              <a href="/" className="link-item disabled">
                <span className="text">Research</span>
              </a>
              <Link to="/settings" className={`link-item ${pathname === '/settings' ? 'active' : ''}`}>
                <span className="text">Settings</span>
              </Link>
              <div
                className={`link-item dropdown-item ${isResourcesOpened ? 'opened' : ''}`}
                onClick={() => setIsResourcesOpened(!isResourcesOpened)}
                ref={dropdownMenuRef}>
                <div className="dropdown-btn">
                  <span className="text">Resources</span>
                  <span className="icon">
                    <img src={arrowIcon} alt="Arrow" />
                  </span>
                </div>
                <div className="dropdown-wrap">
                  <a href="https://docs.apescreener.xyz" target="_blank" rel="noreferrer">
                    Whitepaper
                  </a>
                  <a href="https://medium.com/@apescreener" target="_blank" rel="noreferrer">
                    Tutorials
                  </a>
                </div>
              </div>
            </div>
            <div className="wallet-wrap">
              {isAuthenticated && walletAddress ? (
                <>
                  <div className="search-wrap">
                    <button onClick={() => setIsSearchModalOpen(true)}>
                      <span className="icon">
                        <img src={searchIcon} alt="Search" />
                      </span>
                    </button>
                  </div>
                  <div className="divider-wrap"></div>
                  <div className="status-wrap">
                    <button onClick={() => navigate('/settings')}>
                      <span className="icon">
                        <img src={tierToDisplayText(userProfile?.tier).icon} alt="Icon" />
                      </span>
                      <span className="text">
                        <span>{tierToDisplayText(userProfile?.tier).text}</span>
                      </span>
                    </button>
                  </div>
                  <div className="divider-wrap"></div>
                  <div className="address-wrap">
                    <button onClick={() => open({ view: 'Account' })}>
                      <span className="icon">
                        <img src={walletIcon} alt="Wallet" />
                      </span>
                      <span className="text">{formattedWalletAddress}</span>
                      <span className="arrow">
                        <img src={arrowIcon} alt="Arrow" />
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <button onClick={() => open()}>
                  <span className="icon">
                    <img src={connectIcon} alt="Connect" />
                  </span>
                  <span className="text">Connect Wallet</span>
                </button>
              )}
            </div>
          </div>
          <div className="mobile-nav-wrap">
            <Link to="/" className="logo-wrap">
              <img src={logo} alt="Logo" />
            </Link>
            <div className="search-wrap">
              <button onClick={() => setIsSearchModalOpen(true)}>
                <span className="icon">
                  <img src={searchIcon} alt="Search" />
                </span>
              </button>
            </div>
            <div className="burger-wrap" onClick={() => setIsMobileNavbarOpened(true)}>
              <img src={openIcon} alt="Open" />
            </div>
          </div>
          <div
            className={`overlay ${isMobileNavbarOpened ? 'active' : ''}`}
            onClick={() => setIsMobileNavbarOpened(false)}></div>
        </nav>
      </header>
      <SearchModal open={isSearchModalOpen} onClose={() => setIsSearchModalOpen(false)} />
    </>
  );
};

export default Navbar;
