import { BLOCKCHAIN_IDS, BlockchainIds } from '../constants';

type ContractAddresses = {
  [key: string]: Partial<Record<BlockchainIds, string>>;
};

export const CONTRACT_ADDRESSES: ContractAddresses = {
  APES: {
    [BLOCKCHAIN_IDS.ETHEREUM]: '0x09675e24CA1EB06023451AC8088EcA1040F47585',
  },
  USDT: {
    [BLOCKCHAIN_IDS.ETHEREUM]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  },
  WETH: {
    [BLOCKCHAIN_IDS.ETHEREUM]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },
  UNISWAPV2_ROUTER: {
    [BLOCKCHAIN_IDS.ETHEREUM]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  },
};

export const getContractAddress = (
  contractName: keyof typeof CONTRACT_ADDRESSES,
  chainId: BlockchainIds,
): string | null => {
  const contract = CONTRACT_ADDRESSES[contractName];
  if (contract && contract[chainId]) {
    return contract[chainId] || null;
  }
  return null;
};

export const getContractsByChain = (chainId: BlockchainIds): { [contractName: string]: string } => {
  const contracts: { [contractName: string]: string } = {};

  for (const [contractName, addresses] of Object.entries(CONTRACT_ADDRESSES)) {
    if (addresses[chainId]) {
      contracts[contractName] = addresses[chainId] as string;
    }
  }

  return contracts;
};

export const doesContractExistOnChain = (
  contractName: keyof typeof CONTRACT_ADDRESSES,
  chainId: BlockchainIds,
): boolean => {
  const contract = CONTRACT_ADDRESSES[contractName];
  return !!contract && !!contract[chainId];
};
