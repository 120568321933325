import React, { FC } from 'react';

import levelIcon from '../../assets/img/streak-level-icon.svg';
import { UserScore } from '../../services/api/apes/interfaces';

const WidgetProfileStreak: FC<{ userScore: UserScore | null }> = ({ userScore }) => {
  const streakDays = userScore ? userScore.streak : 0;
  const dayLabel = streakDays === 1 ? 'Day' : 'Days';

  return (
    <>
      <div className="level-wrap">
        <div className="icon">
          <img src={levelIcon} alt="Level" />
        </div>
        <div className="text">
          <span className="label">LV.</span>
          <span className="value">{userScore ? userScore.level : '-'}</span>
        </div>
      </div>
      <div className="info-wrap">
        <div className="title">Your Streak</div>
        <div className="days">
          {streakDays} {dayLabel}
        </div>
      </div>
    </>
  );
};

export default WidgetProfileStreak;
