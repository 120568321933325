import { FC, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { PortfolioAssetDistributionData } from '../../services/api/apes/interfaces';
import { useSelector } from 'react-redux';
import { selectPortfolio } from '../../store/slices/userSlice';

const WidgetAssetRatio: FC = () => {
  const [currentDistribution, setCurrentDistribution] = useState<PortfolioAssetDistributionData>();
  const portfolio = useSelector(selectPortfolio);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  const data = [
    {
      name: 'Blue Chip',
      y: currentDistribution?.blueChip ?? 0,
      category: 'Blue Chip',
      color: '#4cb7d9',
    },
    {
      name: 'Stable',
      y: currentDistribution?.stable ?? 0,
      category: 'Stable',
      color: '#7ee42f',
    },
    {
      name: 'Mid Cap',
      y: currentDistribution?.mid ?? 0,
      category: 'Mid Cap',
      color: '#9a70df',
    },
    {
      name: 'Low Cap',
      y: currentDistribution?.low ?? 0,
      category: 'Low Cap',
      color: '#fe981a',
    },
    {
      name: 'Micro Cap',
      y: currentDistribution?.micro ?? 0,
      category: 'Micro Cap',
      color: '#ff1589',
    },
  ];

  const emptyData = [
    {
      name: 'Blue Chip',
      category: 'Blue Chip',
      y: 100,
      color: '#FFFFFF26',
    },
    {
      name: 'Stable',
      category: 'Stable',
      y: 40,
      color: '#FFFFFF0D',
    },
    {
      name: 'Mid Cap',
      category: 'Mid Cap',
      y: 90,
      color: '#FFFFFF0D',
    },
    {
      name: 'Low Cap',
      category: 'Low Cap',
      y: 60,
      color: '#FFFFFF0D',
    },
    {
      name: 'Micro Cap',
      category: 'Micro Cap',
      y: 70,
      color: '#FFFFFF0D',
    },
  ];

  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      styledMode: false,
      backgroundColor: 'transparent',
    },
    title: {
      text: undefined,
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        borderWidth: 0,
        size: '100%',
        dataLabels: {
          enabled: true,
          useHTML: true,
          backgroundColor: 'none',
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          distance: -0.1,
          crop: false,
          overflow: 'allow',
          formatter: function () {
            if (this.percentage === 0) {
              return null;
            }

            const name = this.point.name;
            let color = '';
            let backgroundColor = '';

            switch (name) {
              case 'Stable':
                color = '#99FF4A';
                backgroundColor = '#202e18';
                break;
              case 'Blue Chip':
                color = '#4CB7D9';
                backgroundColor = '#1b3640';
                break;
              case 'Mid Cap':
                color = '#9a70df';
                backgroundColor = '#392c52';
                break;
              case 'Low Cap':
                color = '#FE981A';
                backgroundColor = '#281e14';
                break;
              case 'Micro Cap':
                color = '#FF49A3';
                backgroundColor = '#5c0d35';
                break;
              default:
                color = '#ffffff';
                backgroundColor = '#333';
            }

            return `<div style="color: ${color}; background-color: ${backgroundColor}; padding: 4px 8px; border-radius: 50px; min-width: 50px;">
                      ${Math.round(this.percentage)}%
                    </div>`;
          },
        },
        showInLegend: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'pie',
        name: 'Asset Distribution',
        data: data,
      },
    ],
  };

  const emptyOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
      styledMode: false,
      backgroundColor: 'transparent',
    },
    title: {
      text: undefined,
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        borderWidth: 0,
        size: '100%',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'pie',
        name: 'Asset Distribution',
        data: emptyData,
      },
    ],
  };

  useEffect(() => {
    if (!portfolio.data || portfolio.isLoading) {
      return;
    }

    setCurrentDistribution(portfolio.data.distribution);
    setIsEmpty(portfolio.data.portfolio.length === 0 || !portfolio.data.distribution);
  }, [portfolio]);

  return (
    <>
      {/* <div className="text-wrap">
        <div className="name-wrap">Asset Ratio</div>
      </div> */}
      <div className="chart-wrap">
        <HighchartsReact highcharts={Highcharts} options={isEmpty ? emptyOptions : options} />
        {!isEmpty && (
          <div className="legend-wrap">
            {data?.map(({ name, color }) => (
              <div className="item" key={name}>
                <span className="icon" style={{ backgroundColor: color }}></span>
                <span className="text">{name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetAssetRatio;
