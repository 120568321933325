import useSWR from 'swr';
import { fetchTokenTimestamps } from './backtest';
import { DEFAULT_SWR_CONFIG } from './config';

// Fetch average entry prices for a list of tokens
export const useBacktestTokenTimestamps = () => {
  const { data, error, isLoading } = useSWR(
    `backtest-token-timestamps`,
    () => fetchTokenTimestamps(),
    DEFAULT_SWR_CONFIG,
  );

  return {
    tokenData: data,
    isLoading: isLoading,
    isError: !!error,
  };
};
