import React from 'react';

interface Props {
  name: string;
  imageUrl: string | undefined;
  symbol: string;
  percentage?: number | undefined;
}

export const TokenLabel: React.FC<Props> = ({ name, imageUrl, symbol, percentage }) => {
  return (
    <div className="asset-col-wrap">
      <div className="asset-logo-wrap">
        <img src={imageUrl} alt={name} />
      </div>
      <div className="asset-text-wrap">
        <div className="symbol-wrap">
            <div className="symbol">{symbol}</div>
            {percentage ? <div className="percentage">{percentage?.toFixed(2)}%</div> : null}
        </div>
        <div className="name">{name}</div>
      </div>
    </div>
  );
};
