import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import { formatNumber, investorProfileToDisplayText } from '../../utils/formatter';
import {selectBalanceVisibility, selectUserProfile, setBalanceVisibility} from '../../store/slices/userSlice';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import eyeVisibleIcon from '../../assets/img/eye-visible-icon.svg';
import eyeHiddenIcon from '../../assets/img/eye-hidden-icon.svg';
import { useNavigate } from 'react-router-dom';

export interface ProfileStrategyProps {
  data: {
    balance: number | 'hidden';
    increase?: boolean;
    change?: number;
  } | null;
}

const WidgetProfileStrategy: FC<ProfileStrategyProps> = ({ data }) => {
  const dispatch = useDispatch();
  const balance = data?.balance;
  const increase = data?.increase;
  const change = data?.change;
  const navigate = useNavigate();
  const userProfile = useSelector(selectUserProfile);
  const currentBalanceVisibility = useSelector(selectBalanceVisibility);
  const [balanceDisplay, setBalanceDisplay] = useState<string | undefined>(undefined);
  const [changeDisplay, setChangeDisplay] = useState<string | undefined>(undefined);
  const [strategyImg, setStrategyImg] = useState<string | undefined>(undefined);
  const [isBalanceVisible, setIsBalanceVisible] = useState<boolean>(currentBalanceVisibility || false);

  useEffect(() => {
    if (!balance) {
      return;
    }

    if (balance === 'hidden') {
      setBalanceDisplay('*****');
      return;
    }

    setBalanceDisplay(`$${formatNumber(balance, 'M', true, 0).value.toString()}`);
  }, [balance]);

  useEffect(() => {
    if (change === undefined) {
      return;
    }

    setChangeDisplay(`${Math.abs(change).toFixed(2)}%`);
  }, [change]);

  useEffect(() => {
    if (!userProfile) {
      return;
    }

    const { investorProfile } = userProfile;
    const { img } = investorProfileToDisplayText(investorProfile);

    if (!investorProfile) {
      setStrategyImg(img);
      return;
    }

    setStrategyImg(img);
  }, [userProfile]);

  const handleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
    dispatch(setBalanceVisibility(!isBalanceVisible));
    localStorage.setItem('isBalanceVisible', JSON.stringify(!isBalanceVisible));
    //navigate(0);
  }

  return (
    <div className="strategy-wrap">
      {strategyImg ? (
        <div className="img-wrap">
          <img src={strategyImg} alt="Strategy" onClick={() => navigate('/settings')} />
        </div>
      ) : null}
      <div className="info-wrap">
        {balanceDisplay ? (
          <div className="price-wrap">
            <span className="text">
              {isBalanceVisible
                ? <Tooltip title={balance} placement="top-start"><span>{balanceDisplay}</span></Tooltip>
                : '*****'
              }
            </span>
            <span className="icon" onClick={handleBalanceVisibility}>
              <img src={isBalanceVisible ? eyeHiddenIcon : eyeVisibleIcon} alt="Eye" />
            </span>
          </div>
        ) : null}
        {changeDisplay ? (
          <div className={`change-wrap ${increase ? 'increase' : 'reduce'}`}>
            {increase ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            {changeDisplay}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WidgetProfileStrategy;
