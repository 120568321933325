import { getAddress } from 'viem';

import profileModerate from '../assets/img/investor-profile-moderate.png';
import profileConservative from '../assets/img/investor-profile-conservative.png';
import profileDegen from '../assets/img/investor-profile-degen.png';
import profileUnknown from '../assets/img/investor-profile-unknown.png';
import tierStarterIcon from '../assets/img/tier-starter-icon.svg';
import tierGoldIcon from '../assets/img/tier-gold-icon.svg';
import tierDiamondIcon from '../assets/img/tier-diamond-icon.svg';
import tierEliteIcon from '../assets/img/tier-elite-icon.svg';
import { Profile } from '../components/AiStrategies/data';

export function formatNumber(
  input: number | string,
  convertAboveThreshold: 'K' | 'M' | 'B' | 'none' = 'none',
  commify = false,
  numDecimals: number | null = null,
): { value: string | number; sub?: number; rest?: number } {
  // Format numbers with a maximum of 10 decimal places to avoid excessive length
  const num = typeof input === 'string' ? parseFloat(input) : input;
  const precision = numDecimals !== null ? numDecimals : Math.abs(num) < 1 ? 5 : 2;
  const thresholds = {
    K: 1_000,
    M: 1_000_000,
    B: 1_000_000_000,
    none: 0,
  };
  const threshold = thresholds[convertAboveThreshold];
  let value = num.toString();

  if (num < 1) {
    const sub = -Math.floor(Math.log(num) / Math.log(10) + 1);
    const rest = Math.ceil(num * Math.pow(10, sub + 4));

    if (sub === 0) {
      return {
        value: `0.${rest}`,
      };
    }

    if (sub === 1) {
      return {
        value: `0.0${rest}`,
      };
    }

    if (sub === 2) {
      return {
        value: `0.00${rest}`,
      };
    }

    return {
      value: `0.0`,
      sub: sub,
      rest: rest,
    };
  }

  if (num < threshold) {
    value = num.toFixed(precision);
  } else if (num < 1000) {
    value = num.toFixed(precision);
  } else if (num < 1_000_000) {
    value = `${(num / 1_000).toFixed(precision)}K`;
  } else if (num < 1_000_000_000) {
    value = `${(num / 1_000_000).toFixed(precision)}M`;
  } else if (num < 1_000_000_000_000_000) {
    value = `${(num / 1_000_000_000).toFixed(precision)}B`;
  }

  if (commify) {
    value = parseFloat(value).toLocaleString(undefined, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  }

  return {
    value: value,
  };
}

export const toChecksumAddress = (address: string): string => {
  return getAddress(address);
};

export const formatWalletAddress = (
  address: string,
  { numChars = 4, separator = '...', toLowerCase = false, toChecksum = false, ignorePrefix = false },
): string => {
  let formattedAddress = address;

  if (!ignorePrefix) {
    // Remove 0x prefix if present
    formattedAddress = formattedAddress.replace(/^0x/, '');
  }

  // Convert to lowercase if requested
  if (toLowerCase) {
    formattedAddress = address.toLowerCase();
  }

  // Convert to checksum address if requested
  if (toChecksum) {
    formattedAddress = toChecksumAddress(address);
  }

  formattedAddress = `${formattedAddress.slice(0, numChars)}${separator}${formattedAddress.slice(-numChars)}`;

  if (!ignorePrefix) {
    formattedAddress = `0x` + formattedAddress;
  }

  // Return the formatted address
  return formattedAddress;
};

export const investorProfileToDisplayText = (
  profile: string,
): {
  text: string;
  img?: string;
} => {
  switch (profile) {
    case 'conservative':
      return {
        text: 'Conservative',
        img: profileConservative,
      };
    case 'moderate_conservative':
      return {
        text: 'Conservative',
        img: profileConservative,
      };
    case 'moderate':
      return {
        text: 'Moderate',
        img: profileModerate,
      };
    case 'moderate_aggressive':
      return {
        text: 'Degen',
        img: profileDegen,
      };
    case 'aggressive':
      return {
        text: 'Degen',
        img: profileDegen,
      };
    case 'degen':
      return {
        text: 'Degen',
        img: profileDegen,
      };
    default:
      return {
        text: 'Unknown',
        img: profileUnknown,
      };
  }
};

export const investorProfileToStrategy = (profile: string): Profile => {
  switch (profile) {
    case 'conservative':
      return Profile.Conservative;
    case 'moderate_conservative':
      return Profile.Conservative;
    case 'moderate':
      return Profile.Moderate;
    case 'moderate_aggressive':
      return Profile.Degen;
    case 'aggressive':
      return Profile.Degen;
    case 'degen':
      return Profile.Degen;
    default:
      return Profile.Degen;
  }
};

export const isConservativeProfile = (profile: string): boolean => {
  return profile === 'conservative' || profile === 'moderate_conservative' || profile === Profile.Conservative;
};

export const isModerateProfile = (profile: string): boolean => {
  return profile === 'moderate' || profile === Profile.Moderate;
};

export const isDegenProfile = (profile: Profile | string): boolean => {
  return (
    profile === 'moderate_aggressive' || profile === 'aggressive' || profile === 'degen' || profile === Profile.Degen
  );
};

export const tierToDisplayText = (
  tier: string | undefined,
): {
  text: string;
  icon: string | undefined;
} => {
  if (!tier) {
    return {
      text: 'Unknown',
      icon: undefined,
    };
  }

  switch (tier.toLowerCase()) {
    case 'starter':
      return {
        text: 'Starter',
        icon: tierStarterIcon,
      };
    case 'gold':
      return {
        text: 'Gold',
        icon: tierGoldIcon,
      };
    case 'diamond':
      return {
        text: 'Diamond',
        icon: tierDiamondIcon,
      };
    case 'elite':
      return {
        text: 'Elite',
        icon: tierEliteIcon,
      };
    default:
      return {
        text: 'Unknown',
        icon: tierStarterIcon,
      };
  }
};
