import { BacktestData, BacktestResult, TokenTimestamps } from '../../services/api/apes/backtest';

export enum Strategy {
  Derisk = 'Derisk',
  RebalanceDerisk = 'Rebalance & Derisk',
}

export enum Profile {
  Conservative = 'Conservative',
  Moderate = 'Moderate',
  Degen = 'Degen',
}

export enum Feel {
  Uncertain = 'Uncertain',
  Bullish = 'Bullish',
  VeryBullish = 'Very Bullish',
}

export enum PeriodUnit {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum Trigger {
  Time = 'Time',
  Price = 'Price',
}

export interface ChartProps {
  backtestResults: BacktestResult[][];
  strategies: { strategy: string; data: BacktestData }[];
  allTokens: TokenTimestamps;
}

export interface CardProps {
  backtestResults: BacktestResult[][];
  strategies: { strategy: string; data: BacktestData }[];
}

export interface StrategyProps {
  selectedStrategy: Strategy;
  setSelectedStrategy: (strategy: Strategy) => void;
  selectedPercentChangeTrigger: number;
  setSelectedPercentChangeTrigger: (percentChangeTrigger: number) => void;
  selectedMinDerisk: number;
  setSelectedMinDerisk: (minDerisk: number) => void;
  selectedTakeProfit: number;
  setSelectedTakeProfit: (takeProfit: number) => void;
  selectedTrailedDerisk: number;
  setSelectedTrailedDerisk: (trailedDerisk: number) => void;
  selectedFeel: Feel;
  setSelectedFeel: (feel: Feel) => void;
  handleSubmit: () => void;
  handleShowCard: () => void;
  submitButtonState: ISubmitButtonState;
  enabled: boolean;
}

export interface ISubmitButtonState {
  label: string;
  disabled: boolean;
}

export type SubmitButtonStateKeys = 'Loading' | 'Ready' | 'Error' | 'NoData' | 'NoToken';

export const SubmitButtonState: Record<SubmitButtonStateKeys, ISubmitButtonState> = {
  Loading: {
    label: 'Loading...',
    disabled: true,
  },
  Ready: {
    label: 'Run Backtest',
    disabled: false,
  },
  Error: {
    label: 'Unavailable',
    disabled: true,
  },
  NoData: {
    label: '-',
    disabled: true,
  },
  NoToken: {
    label: 'No Token',
    disabled: true,
  },
};

export const profileMarks = [
  {
    value: 0,
    label: Profile.Conservative,
  },
  {
    value: 50,
    label: Profile.Moderate,
  },
  {
    value: 100,
    label: Profile.Degen,
  },
];

export const takeProfitMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
];

export const feelMarks = [
  {
    value: 0,
    label: Feel.Uncertain,
  },
  {
    value: 50,
    label: Feel.Bullish,
  },
  {
    value: 100,
    label: Feel.VeryBullish,
  },
];

export const profileMarkToProfile = (mark: number) => {
  if (mark === 0) {
    return Profile.Conservative;
  } else if (mark === 50) {
    return Profile.Moderate;
  } else if (mark === 100) {
    return Profile.Degen;
  }
  return Profile.Moderate;
};

export const profileToProfileMark = (profile: Profile) => {
  if (profile === Profile.Conservative) {
    return 0;
  } else if (profile === Profile.Moderate) {
    return 50;
  } else if (profile === Profile.Degen) {
    return 100;
  }
  return 50;
};

export const feelMarkToFeel = (mark: number) => {
  if (mark === 0) {
    return Feel.Uncertain;
  } else if (mark === 50) {
    return Feel.Bullish;
  } else if (mark === 100) {
    return Feel.VeryBullish;
  }
  return Feel.Bullish;
};

export const feelToFeelMark = (feel: Feel) => {
  if (feel === Feel.Uncertain) {
    return 0;
  } else if (feel === Feel.Bullish) {
    return 50;
  } else if (feel === Feel.VeryBullish) {
    return 100;
  }
  return 50;
};

export const deriskConfig = {
  [Profile.Degen]: {
    [Feel.Uncertain]: {
      minDerisk: 3,
      trailedDerisk: 20,
      takeProfit: 50,
      percentChangeTrigger: 10,
      principal: 'keep',
    },
    [Feel.Bullish]: {
      minDerisk: 4,
      trailedDerisk: 25,
      takeProfit: 30,
      percentChangeTrigger: 10,
      principal: 'keep',
    },
    [Feel.VeryBullish]: {
      minDerisk: 5,
      trailedDerisk: 30,
      takeProfit: 20,
      percentChangeTrigger: 10,
      principal: 'keep',
    },
  },
  [Profile.Moderate]: {
    [Feel.Uncertain]: {
      minDerisk: 2,
      trailedDerisk: 15,
      takeProfit: 60,
      percentChangeTrigger: 10,
      principal: 'keep',
    },
    [Feel.Bullish]: {
      minDerisk: 3,
      trailedDerisk: 20,
      takeProfit: 50,
      percentChangeTrigger: 10,
      principal: 'keep',
    },
    [Feel.VeryBullish]: {
      minDerisk: 4,
      trailedDerisk: 25,
      takeProfit: 30,
      percentChangeTrigger: 10,
      principal: 'keep',
    },
  },
  [Profile.Conservative]: {
    [Feel.Uncertain]: {
      minDerisk: 1.5,
      trailedDerisk: 10,
      takeProfit: 70,
      percentChangeTrigger: 10,
      principal: 'derisk',
    },
    [Feel.Bullish]: {
      minDerisk: 2,
      trailedDerisk: 15,
      takeProfit: 60,
      percentChangeTrigger: 10,
      principal: 'derisk',
    },
    [Feel.VeryBullish]: {
      minDerisk: 3,
      trailedDerisk: 20,
      takeProfit: 50,
      percentChangeTrigger: 10,
      principal: 'derisk',
    },
  },
};
