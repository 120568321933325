import {FC} from 'react';
import {ChartProps} from './data';
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock';

const BacktestChart: FC<ChartProps> = ({ backtestResults, strategies }) => {
    const seriesData = backtestResults.map((result, index) => {
        const dates = result.map((r) => new Date(r.date).getTime());
        const values = result.map((r) => r.valueInUsd);
        const rebalancedDates = result.filter((r) => r.rebalanced).map((r) => new Date(r.date).getTime());
        const deriskedDates = result.filter((r) => r.derisked).map((r) => new Date(r.date).getTime());

        return {
            name: `${strategies[index].strategy.charAt(0).toUpperCase() + strategies[index].strategy.slice(1)} Strategy`,
            data: values.map((value, i) => [dates[i], value]),
            rebalancedDates,
            deriskedDates,
        };
    });

    const plotLines = seriesData.flatMap((data) => [
        ...data.rebalancedDates.map((date) => ({
            color: 'rgba(255, 0, 0, 0.8)', // Red color
            width: 2,
            value: date,
            zIndex: 2,
            label: {
                text: `Rebalanced`,
                style: {
                    color: '#606060',
                },
            },
        })),
        ...data.deriskedDates.map((date) => ({
            color: 'rgba(0, 0, 255, 0.8)', // Blue color
            width: 2,
            value: date,
            zIndex: 5,
            label: {
                text: `Derisked`,
                style: {
                    color: '#606060',
                },
            },
        })),
    ]);
    const totalVolume = backtestResults.length
        ? backtestResults[0].map((_, i) =>
            backtestResults.reduce(
                (sum, response) =>
                    sum +
                    response[i].tokens.reduce(
                        (tokenSum, token) =>
                            token.tokenId === '0xdac17f958d2ee523a2206206994597c13d831ec7:1'
                                ? tokenSum
                                : tokenSum + (token.volume || 0),
                        0,
                    ),
                0,
            ),
        )
        : [];
    const chartOptions = {
        chart: {
            backgroundColor: 'transparent',
        },
        xAxis: {
            type: 'datetime', // Specify the xAxis type as datetime
            title: {
                text: 'Date',
                style: {
                    color: '#ffffff',
                },
            },
            labels: {
                style: {
                    color: '#ffffff',
                },
            },
            plotLines: plotLines,
        },
        yAxis: [
            {
                labels: {
                    style: {
                        color: '#ffffff',
                    },
                },
                gridLineColor: '#FFFFFF1A', // Set the grid line color as per your specification
                gridLineDashStyle: 'Dash', // Make the grid lines dashed
                gridLineWidth: 1, // Set the width of the grid lines
                gridZIndex: 4, // Ensure the grid lines appear behind the series
            },
            {
                labels: {
                    style: {
                        color: '#ffffff',
                    },
                },
                opposite: true,
                gridLineColor: '#FFFFFF1A', // Set the grid line color as per your specification
                gridLineDashStyle: 'Dash', // Make the grid lines dashed
                gridLineWidth: 1, // Set the width of the grid lines
                gridZIndex: 4, // Ensure the grid lines appear behind the series
            },
        ],
        series:
            seriesData.length > 0
                ? [
                    {
                        name: seriesData[0].name,
                        data: seriesData[0].data,
                        color: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 1,
                                y2: 0,
                            },
                            stops: [
                                [0, '#EE1F8F'], // Starting color
                                [1, '#FF9900'], // Ending color
                            ],
                        },
                        lineWidth: 1, // Simulate 1px border
                    },
                    {
                        name: seriesData[1].name,
                        data: seriesData[1].data,
                        color: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 1,
                                y2: 0,
                            },
                            stops: [
                                [0, '#00CDDA'], // Starting color
                                [0.58, '#8FFF00'], // Ending color
                            ],
                        },
                        lineWidth: 2, // Simulate 2px border
                    },
                    {
                        name: 'Total Volume',
                        type: 'column',
                        yAxis: 1,
                        data: totalVolume.map((volume, i) => [seriesData[0].data[i][0], volume]),
                        tooltip: {
                            shared: true,
                            valueSuffix: ' USD',
                            split: false,
                        },
                        borderRadius: 5, // This will apply the border radius (Highcharts applies the same radius to all corners)
                        borderColor: 'transparent', // Set border color to transparent if no border is needed
                        pointWidth: 4, // Set the width of the column
                        states: {
                            hover: {
                                enabled: false, // Disable hover effect if needed
                            },
                        },
                        opacity: 100, // Set initial opacity (can be animated or controlled later)
                        color: 'rgba(255, 255, 255, 0.15)', // Semi-transparent white background color
                    },
                ]
                : [],
        legend: {
            itemStyle: {
                color: '#ffffff',
            },
        },
        tooltip: {
            split: false,
            shared: true, // Enable shared tooltips
            crosshairs: true, // Add crosshairs for better alignment
        },
        rangeSelector: {
            buttonPosition: {
                align: 'right', // Align the buttons to the right
            },
            inputEnabled: false, // Disable the date picker
            labelStyle: {
                display: 'none', // Hide the Zoom label
            },
            buttonTheme: {
                fill: 'none', // No background color
                stroke: 'none', // No border
                'stroke-width': 0, // No border width
                style: {
                    fontFamily: 'Inter', // Font family
                    fontSize: '14px', // Font size
                    fontWeight: '400', // Font weight
                    lineHeight: '16.94px', // Line height
                    textAlign: 'left', // Text alignment
                    color: '#FFFFFF80', // Text color
                    textTransform: 'uppercase', // Make text uppercase
                },
                states: {
                    hover: {
                        fill: 'none', // No background color on hover
                        style: {
                            color: '#FFFFFF', // Text color on hover
                        },
                    },
                    select: {
                        fill: 'none', // No background color when selected
                        style: {
                            color: '#FFFFFF', // Text color when selected
                        },
                    },
                },
            },
            inputBoxBorderColor: 'none', // Remove input box border
            inputStyle: {
                color: '#FFFFFF80', // Input box text color
            },
        },
    };

    return (
        <>
            {backtestResults.length > 0 && (
                <div className="chart-wrap">
                    <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={chartOptions} />
                </div>
            )}
        </>
    );
};

export default BacktestChart;
