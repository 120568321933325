import {FC} from 'react';
import {Slider} from '@mui/material';
import {feelMarks, feelMarkToFeel, feelToFeelMark, StrategyProps} from './data';
import {minDeriskMarks, takeProfitMarks, trailedDeriskMarks} from '../WidgetAssetDerisk/data';

const StrategySection: FC<StrategyProps> = (
    {
        selectedMinDerisk,
        setSelectedMinDerisk,
        selectedTakeProfit,
        setSelectedTakeProfit,
        selectedTrailedDerisk,
        setSelectedTrailedDerisk,
        selectedFeel,
        setSelectedFeel,
        handleSubmit,
        submitButtonState,
        enabled,
    }
) => {
    return (
        <div className="widgets-column widgets-column-3-wrap">
            <div className="widget-wrap widget-asset-derisk-wrap">
                <div className="headline-wrap">
                    <div className="title">Derisk</div>
                </div>
                <div className="content-wrap">
                    <div className="sliders-wrap">
                        {/* Feel */}
                        <div className="item-wrap">
                            <div className="title-wrap">
                                <span className="text-wrap">Feeling</span>
                            </div>
                            <Slider
                                className={enabled ? 'red-to-green' : 'disabled'}
                                shiftStep={1}
                                step={50}
                                min={0}
                                max={100}
                                marks={feelMarks}
                                value={feelToFeelMark(selectedFeel)}
                                onChange={(_, value) => setSelectedFeel(feelMarkToFeel(value as number))}
                            />
                        </div>

                        {/* Min Derisk */}
                        <div className="item-wrap">
                            <div className="title-wrap">
                                <span className="text-wrap">Min. Derisk</span>
                            </div>
                            <Slider
                                shiftStep={0.1}
                                step={0.1}
                                min={1.1}
                                max={10}
                                marks={minDeriskMarks}
                                value={selectedMinDerisk}
                                onChange={(_, value) => setSelectedMinDerisk(value as number)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value}x`}
                                className={enabled ? '' : 'disabled'}
                            />
                        </div>

                        {/* Take Profit */}
                        <div className="item-wrap">
                            <div className="title-wrap">
                                <span className="text-wrap">Take Profit</span>
                            </div>
                            <Slider
                                shiftStep={1}
                                step={0.1}
                                min={0}
                                max={100}
                                marks={takeProfitMarks}
                                value={selectedTakeProfit}
                                onChange={(_, value) => setSelectedTakeProfit(value as number)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value}%`}
                                className={enabled ? '' : 'disabled'}
                            />
                        </div>

                        {/* Trailed Derisk */}
                        <div className="item-wrap">
                            <div className="title-wrap">
                                <span className="text-wrap">Trailed Derisk</span>
                            </div>
                            <Slider
                                shiftStep={1}
                                step={1}
                                min={0}
                                max={90}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value}%`}
                                marks={trailedDeriskMarks}
                                value={selectedTrailedDerisk}
                                onChange={(_, value) => setSelectedTrailedDerisk(value as number)}
                                className={enabled ? '' : 'disabled'}
                            />
                        </div>
                    </div>
                </div>
                <div className="action-wrap action-wrap-backtest">
                    <button className="bn-backtest-run" onClick={handleSubmit} disabled={submitButtonState.disabled}>
                        {submitButtonState.label}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StrategySection;
