import axios from 'axios';
import { env } from '../../../config/env';

const API_URL = env.APES_BACKEND_URL;

export const ENDPOINTS = {
  AUTH: `${API_URL}/auth`,
  LOGOUT: `${API_URL}/logout`,
  LINK_TELEGRAM: `${API_URL}/user/link/telegram`,
  SIWE: {
    SESSION: `${API_URL}/siwe/session`,
    NONCE: `${API_URL}/siwe/nonce`,
  },
  USER_DETAILS: `${API_URL}/user/details`,
  PORTFOLIO_ASSETS: `${API_URL}/user/portfolio/assets`,
  PORTFOLIO_HISTORY: `${API_URL}/user/portfolio/value/history`,
  PORTFOLIO_TOTAL_VALUE: `${API_URL}/user/portfolio/value/total`,
  PORTFOLIO_DISTRIBUTION: `${API_URL}/user/portfolio/distribution`,
  PORTFOLIO_TRANSACTIONS: `${API_URL}/user/portfolio/transactions`,
  PORTFOLIO_PNL_DATA: `${API_URL}/user/portfolio/pnl`,
  ADD_WALLET: `${API_URL}/user/wallets/add`,
  REMOVE_WALLET: `${API_URL}/user/wallets/remove`,
  TOKEN_DATA: `${API_URL}/token/data`,
  TOKEN_CHART: `${API_URL}/token/chart`,
  USER_SETTINGS: `${API_URL}/user/settings`,
  UNHIDE_TOKEN: `${API_URL}/user/token/unhide`,
  HIDE_TOKEN: `${API_URL}/user/token/hide`,
  USER_NOTIFICATIONS: `${API_URL}/user/settings/notifications`,
  USER_STRATEGY: `${API_URL}/user/settings/strategy`,
  DERISK_NONCE: `${API_URL}/derisk/get-next-nonce`,
  DERISK_CREATE_ORDER: `${API_URL}/user/order/add`,
  USER_DERISK_ORDERS: `${API_URL}/user/orders`,
  PORTFOLIO_AVG_ENTRY_PRICES: `${API_URL}/user/portfolio/avg-entry-prices`,
  BACKTEST_TOKEN_TIMESTAMPS: `${API_URL}/token-timestamps`,
  BACKTEST_PREMADE_SIMULATIONS: `${API_URL}/backtest/premade-simulations`,
  BACKTEST_ADD_TOKEN: `${API_URL}/backtest/tokens/add`,
  BACKTEST_RUN: `${API_URL}/backtest/run`,
  WALLET_TRADES: `${API_URL}/trades`,
  TOKEN_SEARCH: `${API_URL}/token/search`,
  USER_SCORE: `${API_URL}/user/score`,
};

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
