import { useState, useCallback, useEffect, useRef } from 'react';

type UseCopyToClipboardResult = {
  isCopied: boolean;
  copyError: Error | null;
  copyToClipboard: (text: string) => Promise<void>;
};

const useCopyToClipboard = (timeout: number = 2000): UseCopyToClipboardResult => {
  const [isCopied, setIsCopied] = useState(false);
  const [copyError, setCopyError] = useState<Error | null>(null);
  const copyTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const copyToClipboard = useCallback(
    async (text: string) => {
      if (!navigator?.clipboard) {
        setCopyError(new Error('Clipboard API not supported'));
        return;
      }

      try {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        setCopyError(null);

        // Reset the isCopied state after the timeout
        if (copyTimeout.current) {
          clearTimeout(copyTimeout.current);
        }
        copyTimeout.current = setTimeout(() => {
          setIsCopied(false);
        }, timeout);
      } catch (error) {
        setCopyError(error as Error);
      }
    },
    [timeout],
  );

  // Clear the timeout if the component unmounts
  useEffect(() => {
    return () => {
      if (copyTimeout.current) {
        clearTimeout(copyTimeout.current);
      }
    };
  }, []);

  return { isCopied, copyError, copyToClipboard };
};

export default useCopyToClipboard;
