import { FC } from 'react';

export interface NotificationCard {
  name?: string;
  message?: string;
  isEmpty?: boolean;
}

const WidgetNotificationCard: FC<NotificationCard> = ({ name, message, isEmpty }) => {
  return (
    <>
      {!isEmpty && (
        <>
          <div className="headline-wrap">
            <div className="token-wrap">
              <div className="text-wrap">
                <div className="name-wrap">{name}</div>
              </div>
            </div>
          </div>
          <div className="message-wrap">
            <span>{message}</span>
          </div>
        </>
      )}
      {isEmpty && (
        <>
          <div className="message-wrap-empty">
            <p>You have no</p>
            <p>new notifications</p>
          </div>
        </>
      )}
    </>
  );
};

export default WidgetNotificationCard;
