import useSWR from 'swr';
import {
  fetchUserDetails,
  fetchUserPortfolioAssets,
  fetchUserPortfolioHistory,
  fetchTotalPortfolioValue,
  fetchPortfolioDistribution,
  fetchPortfolioTransactions,
  fetchPortfolioPnl,
  fetchUserSettings,
  fetchUserDeriskOrders,
  fetchAverageEntryPrices,
  fetchUserScore,
} from './userFetchers';
import { TokenData } from './interfaces';
import { DEFAULT_SWR_CONFIG } from './config';

// Fetch user details
export const useUserDetails = (walletAddress: string | null) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, mutate, isLoading } = useSWR(
    shouldFetch ? `userDetails-${walletAddress}` : null,
    () => fetchUserDetails(),
    DEFAULT_SWR_CONFIG,
  );

  return {
    userDetails: data,
    isLoading: isLoading,
    isError: !!error,
    mutateUserDetails: mutate,
  };
};

// Fetch user portfolio assets
export const useUserPortfolioAssets = (walletAddress: string | null, targetWalletAddress?: string) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, mutate, isLoading } = useSWR(
    shouldFetch ? `portfolioAssets-${walletAddress}-${targetWalletAddress}` : null,
    () => fetchUserPortfolioAssets(targetWalletAddress),
    DEFAULT_SWR_CONFIG,
  );

  return {
    data,
    isLoading,
    isError: !!error,
    mutatePortfolioAssets: mutate,
  };
};

// Fetch user portfolio value history
export const useUserPortfolioHistory = (walletAddress: string | null, startDate?: number, endDate?: number) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, mutate, isLoading } = useSWR(
    shouldFetch ? `portfolioHistory-${walletAddress}-${startDate}-${endDate}` : null,
    () => fetchUserPortfolioHistory(startDate, endDate),
    DEFAULT_SWR_CONFIG,
  );

  return {
    portfolioHistory: data,
    isLoading: isLoading,
    isError: !!error,
    mutatePortfolioHistory: mutate,
  };
};

// Fetch total portfolio value
export const useTotalPortfolioValue = (walletAddress: string | null) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, mutate, isLoading } = useSWR(
    shouldFetch ? `totalPortfolioValue-${walletAddress}` : null,
    () => fetchTotalPortfolioValue(),
    DEFAULT_SWR_CONFIG,
  );

  return {
    totalPortfolioValue: data,
    isLoading: isLoading,
    isError: !!error,
    mutateTotalPortfolioValue: mutate,
  };
};

// Fetch portfolio distribution
export const usePortfolioDistribution = (walletAddress: string | null, targetWalletAddress?: string) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, isLoading } = useSWR(
    shouldFetch ? `portfolioDistribution-${walletAddress}-${targetWalletAddress}` : null,
    () => fetchPortfolioDistribution(targetWalletAddress),
    DEFAULT_SWR_CONFIG,
  );

  return {
    portfolioDistribution: data,
    isLoading: isLoading,
    isError: !!error,
  };
};

// Fetch portfolio transactions
export const usePortfolioTransactions = (
  walletAddress: string | null,
  targetWalletAddress?: string,
  page?: number,
  limit?: number,
) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, isLoading } = useSWR(
    shouldFetch ? `portfolioTransactions-${walletAddress}-${targetWalletAddress}-${page}-${limit}` : null,
    () => fetchPortfolioTransactions(targetWalletAddress, page, limit),
    DEFAULT_SWR_CONFIG,
  );

  return {
    transactions: data,
    isLoading: isLoading,
    isError: !!error,
  };
};

// Fetch portfolio pnl
export const usePortfolioPnl = (targetWalletAddress: string | null, tokenAddress: string, chain: number) => {
  const shouldFetch = Boolean(targetWalletAddress) && Boolean(tokenAddress) && Boolean(chain);

  const { data, error, isLoading } = useSWR(
    shouldFetch ? `portfolioPnl-${targetWalletAddress}-${tokenAddress}-${chain}` : null,
    () => fetchPortfolioPnl(tokenAddress, chain),
    DEFAULT_SWR_CONFIG,
  );

  return {
    pnl: data,
    isLoading: isLoading,
    isError: !!error,
  };
};

// Fetch user settings
export const useUserSettings = (walletAddress: string | null) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, mutate, isLoading } = useSWR(
    shouldFetch ? `userSettings-${walletAddress}` : null,
    () => fetchUserSettings(),
    DEFAULT_SWR_CONFIG,
  );

  return {
    userSettings: data,
    isLoading: isLoading,
    isError: !!error,
    mutateUserSettings: mutate,
  };
};

// Fetch user derisk orders
export const useUserDeriskOrders = (walletAddress: string | null) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, isLoading } = useSWR(
    shouldFetch ? `deriskOrders-${walletAddress}` : null,
    () => fetchUserDeriskOrders(),
    DEFAULT_SWR_CONFIG,
  );

  return {
    deriskOrders: data,
    isLoading: isLoading,
    isError: !!error,
  };
};

// Fetch average entry prices for a list of tokens
export const useAverageEntryPrices = (walletAddress: string | null, tokenData: TokenData[]) => {
  const shouldFetch = tokenData.length > 0;

  const { data, error, isLoading } = useSWR(
    shouldFetch ? `averageEntryPrices-${walletAddress}` : null,
    () => fetchAverageEntryPrices(tokenData),
    DEFAULT_SWR_CONFIG,
  );

  return {
    averageEntryPrices: data,
    isLoading: isLoading,
    isError: !!error,
  };
};

// Fetch user score
export const useUserScore = (walletAddress: string | null) => {
  const shouldFetch = Boolean(walletAddress);

  const { data, error, isLoading } = useSWR(
    shouldFetch ? `userScore-${walletAddress}` : null,
    () => fetchUserScore(),
    DEFAULT_SWR_CONFIG,
  );

  return {
    userScore: data,
    isLoading: isLoading,
    isError: !!error,
  };
};
