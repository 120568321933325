import { ApiResponse, WalletAddRequest } from './interfaces';
import { api, ENDPOINTS } from './endpoints';

export const addWallet = async (walletAddress: string): Promise<ApiResponse<WalletAddRequest>> => {
  return await api.post(ENDPOINTS.ADD_WALLET, { newWalletAddress: walletAddress }, { withCredentials: true });
};

export const removeWallet = async (walletAddress: string): Promise<ApiResponse<WalletAddRequest>> => {
  return await api.post(ENDPOINTS.REMOVE_WALLET, { targetWalletAddress: walletAddress }, { withCredentials: true });
};

export const unhideToken = async (tokenId: string): Promise<ApiResponse<null>> => {
  return await api.post(ENDPOINTS.UNHIDE_TOKEN, { tokenId }, { withCredentials: true });
};

export const hideToken = async (tokenAddress: string): Promise<ApiResponse<null>> => {
  return await api.post(ENDPOINTS.HIDE_TOKEN, { tokenAddress }, { withCredentials: true });
};

export const setUserNotifications = async (notifications: {
  priceAlerts: boolean;
  deriskRecommendations: boolean;
}): Promise<ApiResponse<null>> => {
  return await api.post(ENDPOINTS.USER_NOTIFICATIONS, notifications, { withCredentials: true });
};

export const setUserStrategy = async (strategy: string): Promise<ApiResponse<null>> => {
  return await api.post(ENDPOINTS.USER_STRATEGY, { strategy }, { withCredentials: true });
};

export const linkTelegram = async (userKey: string): Promise<ApiResponse<null>> => {
  return await api.post(ENDPOINTS.LINK_TELEGRAM, { key: userKey }, { withCredentials: true });
};
