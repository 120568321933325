import { Address } from 'viem';
import { env } from '../../config/env';
import { Feel, Profile } from '../AiStrategies/data';

export interface DeriskSignaturePayload {
  wallet: string;
  token: string;
  nonce: string;
  deadline: number;
}

export const feelingMarks = [
  {
    value: 0,
    label: 'Uncertain',
  },
  {
    value: 50,
    label: 'Bullish',
  },
  {
    value: 100,
    label: 'Very Bullish',
  },
];

export const trailedDeriskMarks = [
  { value: 0, label: '0%' },
  { value: 30, label: '30%' },
  { value: 60, label: '60%' },
  { value: 90, label: '90%' },
];

export const minDeriskMarks = [
  { value: 1.1, label: '1.1x' },
  { value: 2, label: '2x' },
  { value: 5, label: '5x' },
  { value: 10, label: '10x' },
];

export const takeProfitMarks = [
  { value: 0, label: '0%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
];

export const stopLossMarks = [
  { value: 0, label: '0%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
];

export const signDomain = {
  name: 'Apescreener',
  version: '1',
  chainId: 1,
  verifyingContract: env.DERISK_ROUTER_ADDRESS as Address,
};

export const signTypes = {
  Data: [
    { name: 'wallet', type: 'address' },
    { name: 'token', type: 'address' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ],
};

export const prepareSignData = (
  wallet: string,
  token: string,
  nonce: string,
  deadline: Date,
): DeriskSignaturePayload => {
  const deadlinInSeconds = Math.floor(deadline.getTime() / 1000);

  return {
    wallet: wallet,
    token: token,
    nonce: nonce,
    deadline: deadlinInSeconds,
  } as DeriskSignaturePayload;
};

export const deriskConfig = {
  [Profile.Degen]: {
    [Feel.Uncertain]: { minDerisk: 3, trailedDerisk: 20, takeProfit: 50 },
    [Feel.Bullish]: { minDerisk: 4, trailedDerisk: 25, takeProfit: 30 },
    [Feel.VeryBullish]: { minDerisk: 5, trailedDerisk: 30, takeProfit: 20 },
  },
  [Profile.Moderate]: {
    [Feel.Uncertain]: { minDerisk: 2, trailedDerisk: 15, takeProfit: 60 },
    [Feel.Bullish]: { minDerisk: 3, trailedDerisk: 20, takeProfit: 50 },
    [Feel.VeryBullish]: { minDerisk: 4, trailedDerisk: 25, takeProfit: 30 },
  },
  [Profile.Conservative]: {
    [Feel.Uncertain]: { minDerisk: 1.5, trailedDerisk: 10, takeProfit: 70 },
    [Feel.Bullish]: { minDerisk: 2, trailedDerisk: 15, takeProfit: 60 },
    [Feel.VeryBullish]: { minDerisk: 3, trailedDerisk: 20, takeProfit: 50 },
  },
};
