import React, {useState} from 'react';

import arrowIcon from '../../assets/img/dropdown-arrow-icon.svg';

interface DateRangeDropdownProps {
    onDateRangeChange: (days: number) => void;
}

const DateRangeDropdown: React.FC<DateRangeDropdownProps> = ({onDateRangeChange}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedTitle, setSelectedTitle] = useState<string>('1M');

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClick = (days: number, title: string) => {
        setSelectedTitle(title);
        setIsOpen(false);
        onDateRangeChange(days); // Call the external function with the selected days
    };

    return (
        <>
            <button onClick={toggleDropdown} className={`dropdown-main-btn ${isOpen ? 'opened' : ''}`}>
                <span>{selectedTitle}</span>
                <span className="icon">
                    <img src={arrowIcon} alt="Icon"/>
                </span>
            </button>

            {isOpen && (
                <div className="dropdown-items-wrap">
                    <button onClick={() => handleClick(7, '7D')}>7D</button>
                    <button onClick={() => handleClick(30, '1M')}>1M</button>
                    <button onClick={() => handleClick(365, '1Y')}>1Y</button>
                    <button onClick={() => handleClick(0, 'All')}>All</button>
                </div>
            )}
        </>
    );
};

export default DateRangeDropdown;
