import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import bs58 from 'bs58';
import { useDebounce } from 'use-debounce';
import strategiesItemOne from '../assets/img/strategies-item-3.png';
import strategiesItemTwo from '../assets/img/strategies-item-2.png';
import strategiesItemThree from '../assets/img/strategies-item-1.png';
import { Switch } from '@mui/material';
import { selectUserMasterWalletAddress, selectUserProfile, selectUserSettings } from '../store/slices/userSlice';
import { useSelector } from 'react-redux';
import {
  investorProfileToStrategy,
  isDegenProfile,
  isConservativeProfile,
  isModerateProfile,
  tierToDisplayText,
} from '../utils/formatter';
import { getChainNameById, validateEthOrSolAddress } from '../blockchain/utils';
import {
  addWallet,
  hideToken,
  linkTelegram,
  removeWallet,
  setUserNotifications,
  setUserStrategy,
  unhideToken,
} from '../services/api/apes/userSettings';
import { toastError, toastInfo, toastSuccess } from '../utils/toastUtils';
import { AxiosError } from 'axios';
import { ApiResponse } from '../services/api/apes/interfaces';
import {
  useUserDetails,
  useUserPortfolioAssets,
  useUserPortfolioHistory,
  useUserSettings,
} from '../services/api/apes/useUserFetchers';
import { parseTokenId } from './WidgetAssetsList';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Profile } from './AiStrategies/data';

type NotificationsState = {
  priceAlerts: boolean;
  deriskRecommendations: boolean;
};

const Settings: FC = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Settings' });
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const userProfile = useSelector(selectUserProfile);
  const userWalletAddress = useSelector(selectUserMasterWalletAddress);
  const userSettings = useSelector(selectUserSettings);
  const { mutateUserDetails } = useUserDetails(userWalletAddress);
  const { mutateUserSettings } = useUserSettings(userWalletAddress);
  const { mutatePortfolioHistory } = useUserPortfolioHistory(userWalletAddress);
  const { mutatePortfolioAssets } = useUserPortfolioAssets(userWalletAddress);
  const [activeTab, setActiveTab] = useState('profile');
  // const [defaultCurrency, setDefaultCurrency] = useState('usd');
  const [notifications, setNotifications] = useState<NotificationsState>({
    priceAlerts: true,
    deriskRecommendations: true,
  });
  const [walletsInputValue, setWalletsInputValue] = useState('');
  const [tokenToHideAddress, setTokenToHideAddress] = useState('');
  const [isDegen, setIsDegen] = useState(false);
  const [isModerate, setIsModerate] = useState(false);
  const [isConservative, setIsConservative] = useState(false);
  const [listWallets, setListWallets] = useState<string[]>([]);
  const [inputWalletAddressDebounce] = useDebounce(walletsInputValue, 500);
  const [inputTokenToHideAddressDebounce] = useDebounce(tokenToHideAddress, 200);
  const [debouncedNotifications] = useDebounce(notifications, 2000);
  const [isInputWalletAddressValid, setIsInputWalletAddressValid] = useState(false);
  const [isInputTokenToHideAddressValid, setIsInputTokenToHideAddressValid] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState<Profile | null>(null);
  const [isNewStrategy, setIsNewStrategy] = useState(false);
  const [hiddenTokens, setHiddenTokens] = useState<
    {
      tokenId: string;
      name: string;
      symbol: string;
    }[]
  >([]);
  const saveAvailable = isNewStrategy;

  const getImgWrap = (type: string) => {
    if (type === 'degen') {
      return `img-wrap ${isDegen ? 'active' : ''}`;
    } else if (type === 'moderate') {
      return `img-wrap ${isModerate ? 'active' : ''}`;
    } else if (type === 'conservative') {
      return `img-wrap ${isConservative ? 'active' : ''}`;
    } else {
      return 'img-wrap';
    }
  };

  const getTelegramBlock = () => {
    if (userProfile?.telegramId) {
      return <span className="value-wrap">@{userProfile.username}</span>;
    } else {
      return <span className="value-wrap">Not linked</span>;
    }
  };

  const startTgLinking = () => {
    const shortenAddress = (address: string): string => {
      const addressWithoutPrefix = address.slice(2);
      const buffer = Buffer.from(addressWithoutPrefix, 'hex');
      const shortened = bs58.encode(buffer);
      return shortened;
    };

    if (!userWalletAddress) {
      return;
    }

    const key = shortenAddress(userWalletAddress);
    window.open(`https://t.me/ApescreenerBot?start=la_${key}`, '_blank');
  };

  const getTelegramLinkingBlock = () => {
    if (userProfile?.telegramId) {
      return null;
    } else {
      return <button onClick={() => startTgLinking()}>Link</button>;
    }
  };

  const mutateUserData = () => {
    mutateUserDetails();
    mutateUserSettings();
    mutatePortfolioAssets();
    mutatePortfolioHistory();
  };

  const handleAddWallet = async () => {
    if (!isInputWalletAddressValid) {
      return;
    }

    try {
      await addWallet(walletsInputValue);
      toastSuccess('Wallet added successfully');
      mutateUserData();
    } catch (e: unknown) {
      const error = e as AxiosError<ApiResponse<null>>;
      const reqError = error.response?.data.error;
      const valErrors = error.response?.data.errors;
      let errorText = 'Unknown error occurred';

      if (valErrors && valErrors.length) {
        errorText = valErrors[0].msg;
      } else {
        errorText = reqError || errorText;
      }

      toastError(errorText);
    }
  };

  const handleRemoveWallet = async (walletAddress: string) => {
    if (!walletAddress) {
      return;
    }

    try {
      await removeWallet(walletAddress);
      toastSuccess('Wallet removed successfully');
      mutateUserData();
    } catch (e: unknown) {
      const error = e as AxiosError<ApiResponse<null>>;
      const reqError = error.response?.data.error;
      const valErrors = error.response?.data.errors;
      let errorText = 'Unknown error occurred';

      if (valErrors && valErrors.length) {
        errorText = valErrors[0].msg;
      } else {
        errorText = reqError || errorText;
      }

      toastError(errorText);
    }
  };

  const handleTokenUnhide = async (tokenId: string) => {
    try {
      await unhideToken(tokenId);
      toastSuccess('Token unhidden successfully');
      mutateUserData();
    } catch (e: unknown) {
      const error = e as AxiosError<ApiResponse<null>>;
      const reqError = error.response?.data.error;
      const valErrors = error.response?.data.errors;
      let errorText = 'Unknown error occurred';

      if (valErrors && valErrors.length) {
        errorText = valErrors[0].msg;
      } else {
        errorText = reqError || errorText;
      }

      toastError(errorText);
    }
  };

  const handleTokenHide = async (tokenAddress: string) => {
    try {
      await hideToken(tokenAddress);
      toastSuccess('Token hidden successfully');
      mutateUserData();
    } catch (e: unknown) {
      const error = e as AxiosError<ApiResponse<null>>;
      const reqError = error.response?.data.error;
      const valErrors = error.response?.data.errors;
      let errorText = 'Unknown error occurred';

      if (valErrors && valErrors.length) {
        errorText = valErrors[0].msg;
      } else {
        errorText = reqError || errorText;
      }

      toastError(errorText);
    }
  };

  const handleNotificationsChange = async () => {
    try {
      await setUserNotifications({
        priceAlerts: debouncedNotifications.priceAlerts,
        deriskRecommendations: debouncedNotifications.deriskRecommendations,
      });
      toastSuccess('Notifications updated successfully');
      mutateUserSettings();
    } catch (e: unknown) {
      const error = e as AxiosError<ApiResponse<null>>;
      const reqError = error.response?.data.error;
      const valErrors = error.response?.data.errors;
      let errorText = 'Unknown error occurred';

      if (valErrors && valErrors.length) {
        errorText = valErrors[0].msg;
      } else {
        errorText = reqError || errorText;
      }

      toastError(errorText);
    }
  };

  const handleNotificationSliderChange = (updatedNotifications: Partial<NotificationsState>) => {
    setNotifications((prev) => ({ ...prev, ...updatedNotifications }));
  };

  const handleStrategyChange = async () => {
    try {
      if (!selectedStrategy) {
        return;
      }

      await setUserStrategy(selectedStrategy);
      toastSuccess('Strategy updated successfully');
      mutateUserDetails();
    } catch (e: unknown) {
      const error = e as AxiosError<ApiResponse<null>>;
      const reqError = error.response?.data.error;
      const valErrors = error.response?.data.errors;
      let errorText = 'Unknown error occurred';

      if (valErrors && valErrors.length) {
        errorText = valErrors[0].msg;
      } else {
        errorText = reqError || errorText;
      }

      toastError(errorText);
    }
  };

  useEffect(() => {
    if (userProfile?.investorProfile) {
      setSelectedStrategy(investorProfileToStrategy(userProfile.investorProfile));
    }

    if (userProfile?.walletAddresses) {
      setListWallets(userProfile.walletAddresses);
    }
  }, [userProfile]);

  useEffect(() => {
    if (!selectedStrategy) {
      return;
    }

    setIsDegen(isDegenProfile(selectedStrategy));
    setIsModerate(isModerateProfile(selectedStrategy));
    setIsConservative(isConservativeProfile(selectedStrategy));

    if (userProfile?.investorProfile) {
      setIsNewStrategy(investorProfileToStrategy(userProfile.investorProfile) !== selectedStrategy);
    } else {
      setIsNewStrategy(true);
    }
  }, [selectedStrategy, userProfile?.investorProfile]);

  useEffect(() => {
    const checkValid = async () => {
      const isValid = (await validateEthOrSolAddress(inputWalletAddressDebounce)).isValid;
      setIsInputWalletAddressValid(isValid);
    };

    checkValid();
  }, [inputWalletAddressDebounce]);

  useEffect(() => {
    const checkValid = async () => {
      try {
        const isValid = (await validateEthOrSolAddress(inputTokenToHideAddressDebounce)).isValid;
        setIsInputTokenToHideAddressValid(isValid);
      } catch {
        setIsInputTokenToHideAddressValid(false);
        return;
      }
    };

    checkValid();
  }, [inputTokenToHideAddressDebounce]);

  useEffect(() => {
    if (!userSettings) {
      return;
    }

    setHiddenTokens(userSettings.preferences.hiddenTokens);
    setNotifications({
      priceAlerts: userSettings.notifications.priceAlerts.enabled,
      deriskRecommendations: userSettings.notifications.deriskRecommendations.enabled,
    });
  }, [userSettings]);

  useEffect(() => {
    if (
      !userSettings?.notifications.priceAlerts ||
      (debouncedNotifications.priceAlerts === userSettings?.notifications.priceAlerts.enabled &&
        debouncedNotifications.deriskRecommendations === userSettings?.notifications.deriskRecommendations.enabled)
    ) {
      return;
    }

    handleNotificationsChange();
  }, [debouncedNotifications]);

  useEffect(() => {
    if (pathname.includes('link/telegram')) {
      const key = searchParams.get('key');

      if (key) {
        toastInfo('Linking Telegram...');
        linkTelegram(key)
          .then(() => {
            toastSuccess('Telegram linked successfully');
            mutateUserDetails();
          })
          .catch(() => {
            toastError('Failed to link Telegram');
          });
      } else {
        toastError('Invalid link');
      }
    }
  }, [pathname]);

  return (
    <section className="settings-section">
      <div className="widget-wrap">
        <div className="headline-wrap">
          <div className="tabs-wrap">
            <div
              className={`item-tab ${activeTab === 'profile' ? 'active' : ''}`}
              onClick={() => setActiveTab('profile')}>
              Profile
            </div>
            <div
              className={`item-tab ${activeTab === 'general' ? 'active' : ''}`}
              onClick={() => setActiveTab('general')}>
              General
            </div>
          </div>
        </div>
        {activeTab === 'profile' ? (
          <div className="settings-profile-wrap">
            <div className="info-wrap">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span className="label-wrap">Your Subscription:</span>
                    </td>
                    <td>
                      <span className="value-wrap">
                        <img src={tierToDisplayText(userProfile?.tier).icon} alt="Icon" />
                        <span className="status">{tierToDisplayText(userProfile?.tier).text}</span>
                      </span>
                    </td>
                    <td>
                      <span className="action-wrap">
                        <a
                          href="https://app.uniswap.org/swap?outputCurrency=0x09675e24CA1EB06023451AC8088EcA1040F47585"
                          target="_blank">
                          Buy $APES
                        </a>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="label-wrap">Linked Telegram:</span>
                    </td>
                    <td>
                      <span className="value-wrap">{getTelegramBlock()}</span>
                    </td>
                    <td>
                      <span className="action-wrap">{getTelegramLinkingBlock()}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="strategies-items-wrap">
              <div className="title-wrap">Choose your strategy</div>
              <div className="item item-1" onClick={() => setSelectedStrategy(Profile.Degen)}>
                <div className={getImgWrap('degen')}>
                  <img src={strategiesItemOne} alt="Item" />
                </div>
                <div className="description-wrap">
                  <h4>Degen</h4>
                  <p>
                    Aims to find the next gem. Will lose in most plays. Will win big in others. Ge 10 tokens, aiming to
                    make at least one 10x.
                  </p>
                </div>
                <div className="info-wrap">
                  <h4>Degen</h4>
                  <ul>
                    <li>
                      <h6>Take profit style:</h6>
                      <p>All or nothing!</p>
                    </li>
                    <li>
                      <h6>Derisk:</h6>
                      <p>What is that?</p>
                    </li>
                    <li>
                      <h6>Balance:</h6>
                      <p>Largest part of its portfolio are microcaps</p>
                    </li>
                    <li>
                      <h6>Cut Loss:</h6>
                      <p>Diamond hands or if project rugs</p>
                    </li>
                    <li>
                      <h6>DCA:</h6>
                      <p>Buys microcaps in a regular basis</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item item-2" onClick={() => setSelectedStrategy(Profile.Moderate)}>
                <div className={getImgWrap('moderate')}>
                  <img src={strategiesItemTwo} alt="Item" />
                </div>
                <div className="description-wrap">
                  <h4>Moderate</h4>
                  <p>A balanced profile that makes calculated risks and has moderate returns.</p>
                </div>
                <div className="info-wrap">
                  <h4>Moderate</h4>
                  <ul>
                    <li>
                      <h6>Take profit style:</h6>
                      <p>Calculated goals</p>
                    </li>
                    <li>
                      <h6>Derisk:</h6>
                      <p>Derisks before taking profit</p>
                    </li>
                    <li>
                      <h6>Balance:</h6>
                      <p>Strategic allocations for each mcap range</p>
                    </li>
                    <li>
                      <h6>Cut Loss:</h6>
                      <p>Willing to lose half of an investment</p>
                    </li>
                    <li>
                      <h6>DCA:</h6>
                      <p>DCA out after derisking</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item item-3" onClick={() => setSelectedStrategy(Profile.Conservative)}>
                <div className={getImgWrap('conservative')}>
                  <img src={strategiesItemThree} alt="Item" />
                </div>
                <div className="description-wrap">
                  <h4>Conservative</h4>
                  <p>Cannot accept high fluctuations. Prefers to make a small, steady return.</p>
                </div>
                <div className="info-wrap">
                  <h4>Conservative</h4>
                  <ul>
                    <li>
                      <h6>Take profit style:</h6>
                      <p>Jeet</p>
                    </li>
                    <li>
                      <h6>Derisk:</h6>
                      <p>Takes profit before derisking</p>
                    </li>
                    <li>
                      <h6>Balance:</h6>
                      <p>Prefers blue-chip and stables</p>
                    </li>
                    <li>
                      <h6>Cut Loss:</h6>
                      <p>Not willing to lose much of its investment</p>
                    </li>
                    <li>
                      <h6>DCA:</h6>
                      <p>DCA in into blue-chips</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {saveAvailable ? (
              <div className="save-wrap">
                <button onClick={handleStrategyChange}>Save</button>
              </div>
            ) : null}
          </div>
        ) : null}
        {activeTab === 'general' ? (
          <div className="settings-general-wrap">
            <div className="items-wrap">
              {/* <div className="item-wrap">
                <div className="title-wrap">Default Currency (soon):</div>
                <div className="content-wrap">
                  <div className="buttons-wrap">
                    <button
                      onClick={() => setDefaultCurrency('usd')}
                      className={defaultCurrency === 'usd' ? 'active' : ''}>
                      USD
                    </button>
                    <button onClick={() => {}} className={defaultCurrency === 'eth' ? 'active' : ''}>
                      ETH
                    </button>
                    <button onClick={() => {}} className={defaultCurrency === 'btc' ? 'active' : ''}>
                      BTC
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="item-wrap">
                <div className="title-wrap">Notifications:</div>
                <div className="content-wrap">
                  <div className="switches-wrap">
                    <div className="switch-wrap">
                      <Switch
                        checked={notifications.priceAlerts}
                        onChange={(event) => handleNotificationSliderChange({ priceAlerts: event.target.checked })}
                        focusVisibleClassName=".Mui-focusVisible"
                        disableRipple
                      />
                      <div className="switch-label-wrap">Price Alerts</div>
                    </div>
                    <div className="switch-wrap">
                      <Switch
                        checked={notifications.deriskRecommendations}
                        onChange={(event) =>
                          handleNotificationSliderChange({ deriskRecommendations: event.target.checked })
                        }
                        focusVisibleClassName=".Mui-focusVisible"
                        disableRipple
                      />
                      <div className="switch-label-wrap">Derisk Recommendation</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-wrap">
                <div className="title-wrap">Wallets:</div>
                <div className="content-wrap">
                  <div className="wallets-wrap">
                    {listWallets.map((wallet, index) => (
                      <div className="wallets-item-wrap" key={index}>
                        <div className="value-wrap">{wallet}</div>
                        <div className="action-wrap">
                          <button onClick={() => handleRemoveWallet(wallet)}>Remove</button>
                        </div>
                      </div>
                    ))}
                    <div className="wallets-item-wrap">
                      <div className="form-wrap">
                        <div className="row">
                          <input
                            type="text"
                            placeholder="Enter wallet address"
                            onChange={(e) => setWalletsInputValue(e.target.value)}
                          />
                          <button
                            className={!walletsInputValue || !isInputWalletAddressValid ? 'disabled' : ''}
                            onClick={handleAddWallet}>
                            Add
                          </button>
                        </div>
                        <span
                          className={`error-message ${walletsInputValue !== '' ? (isInputWalletAddressValid ? 'hidden' : '') : 'hidden'}`}>
                          Please enter a valid wallet address.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-wrap">
                <div className="title-wrap">Hidden Tokens:</div>
                <div className="content-wrap">
                  <div className="tokens-wrap">
                    {hiddenTokens.map((token, index) => {
                      const { chainId } = parseTokenId(token.tokenId);
                      const chainName = getChainNameById(chainId);

                      return (
                        <div className="tokens-item-wrap" key={index}>
                          <div className="value-wrap">{`${token.name} @${chainName}`}</div>
                          <div className="action-wrap">
                            <button onClick={async () => await handleTokenUnhide(token.tokenId)}>Unhide</button>
                          </div>
                        </div>
                      );
                    })}
                    <div className="tokens-item-wrap">
                      <div className="form-wrap">
                        <input
                          type="text"
                          placeholder="Enter token address"
                          onChange={(e) => setTokenToHideAddress(e.target.value)}
                        />
                        <span
                          className={`error-message ${tokenToHideAddress !== '' ? (isInputTokenToHideAddressValid ? 'hidden' : '') : 'hidden'}`}>
                          Please enter a valid token address.
                        </span>
                        <button
                          className={!tokenToHideAddress || !isInputTokenToHideAddressValid ? 'disabled' : ''}
                          onClick={async () => await handleTokenHide(tokenToHideAddress)}>
                          Hide
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Settings;
