import React, { useEffect } from 'react';
import { WagmiProvider } from 'wagmi';
import { CircularProgress } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  type SIWESession,
  type SIWEVerifyMessageArgs,
  type SIWECreateMessageArgs,
  createSIWEConfig,
  formatMessage,
} from '@web3modal/siwe';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { mainnet } from 'wagmi/chains';
import { ENDPOINTS } from '../services/api/apes/endpoints';
import { getEthereumBalance } from '../blockchain/utils';
import {
  login,
  logout,
  selectUserMasterWalletAddress,
  setBalance,
  setDeriskOrders,
  setProfile,
  setUserScore,
  setUserSettings,
} from '../store/slices/userSlice';
import {
  useUserDeriskOrders,
  useUserDetails,
  useUserScore,
  useUserSettings,
} from '../services/api/apes/useUserFetchers';
import { wagmiConfig, wcMetadata, wcProjectId } from '../config/wagmi';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import axios from 'axios';

interface AuthProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient();

const Auth: React.FC<AuthProps> = ({ children }) => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <AuthContent>{children}</AuthContent>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

const AuthContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const { userDetails, isLoading: isUserLoading } = useUserDetails(useSelector(selectUserMasterWalletAddress));
  const { userScore, isLoading: isUserScoreLoading } = useUserScore(useSelector(selectUserMasterWalletAddress));
  const { userSettings, isLoading: areSettingsLoading } = useUserSettings(useSelector(selectUserMasterWalletAddress));
  const { deriskOrders } = useUserDeriskOrders(useSelector(selectUserMasterWalletAddress));
  const masterWalletAddress = useSelector(selectUserMasterWalletAddress);
  const getNonce = async (): Promise<string> => {
    const res = await axios.get(ENDPOINTS.SIWE.NONCE, {
      withCredentials: true,
    });

    if (res.status !== 200) {
      throw new Error('Network response was not ok');
    }

    return res.data;
  };

  const verifyMessage = async ({ message, signature }: SIWEVerifyMessageArgs) => {
    try {
      const res = await axios.post(
        ENDPOINTS.AUTH,
        {
          message,
          signature,
        },
        {
          withCredentials: true,
        },
      );

      if (res.status !== 200 || res.data.success !== true) {
        throw new Error('Network response was not ok');
      }

      return res.data.success;
    } catch {
      return false;
    }
  };

  const onUserSignIn = async (session?: SIWESession) => {
    if (!session) {
      return;
    }

    dispatch(login({ walletAddress: session.address, session }));
    const balance = await getEthereumBalance(session.address);
    dispatch(setBalance(balance.toString()));
  };

  const getSession = async () => {
    const res = await axios.get(ENDPOINTS.SIWE.SESSION, {
      withCredentials: true,
    });

    if (res.status !== 200) {
      throw new Error('Network response was not ok');
    }

    await onUserSignIn(res.data);
    return res.data;
  };

  const signOut = async (): Promise<boolean> => {
    const res = await axios.post(ENDPOINTS.LOGOUT, {
      withCredentials: true,
    });

    if (res.status !== 200) {
      throw new Error('Network response was not ok');
    }

    return res.data.success === true;
  };

  const onUserSignOut = async () => {
    dispatch(logout());
  };

  const siweConfig = createSIWEConfig({
    getMessageParams: async () => ({
      domain: window.location.host,
      uri: window.location.origin,
      chains: [mainnet.id] as const,
      statement: 'Sign in to Apescreener',
    }),
    createMessage: ({ address, ...args }: SIWECreateMessageArgs) => formatMessage(args, address),
    getNonce,
    getSession,
    verifyMessage,
    signOut,
    signOutOnAccountChange: false,
    signOutOnNetworkChange: false,
    onSignIn: onUserSignIn,
    onSignOut: onUserSignOut,
    signOutOnDisconnect: true,
    enabled: true,
  });

  createWeb3Modal({
    metadata: wcMetadata,
    wagmiConfig: wagmiConfig,
    projectId: wcProjectId,
    enableAnalytics: false,
    siweConfig: siweConfig,
    isSiweEnabled: true,
  });

  const { open } = useWeb3Modal();

  useEffect(() => {
    if (!userDetails || !userDetails.data) {
      return;
    }

    dispatch(setProfile(userDetails.data));
  }, [userDetails, isUserLoading]);

  useEffect(() => {
    if (!userScore || !userScore.data) {
      return;
    }

    dispatch(setUserScore(userScore.data));
  }, [userScore, isUserScoreLoading]);

  useEffect(() => {
    if (!userSettings || !userSettings.data) {
      return;
    }

    dispatch(setUserSettings(userSettings.data));
  }, [userSettings, areSettingsLoading]);

  useEffect(() => {
    if (!deriskOrders || !deriskOrders.data) {
      return;
    }

    dispatch(setDeriskOrders(deriskOrders.data));
  }, [deriskOrders]);

  if (isUserLoading) {
    return (
      <section className="connect-wallet-section">
        <CircularProgress />
      </section>
    );
  }

  return masterWalletAddress ? (
    <>{children}</>
  ) : (
    <section className="connect-wallet-section">
      <h2>Connect Wallet to continue</h2>
      <button className="connect-wallet-btn" onClick={() => open()}>
        Connect Wallet
      </button>
    </section>
  );
};

export default Auth;
