import NoCrypto from '../../assets/img/no-crypto.svg';
import NoOrder from '../../assets/img/derisk-no.svg';

export type EmptyTokenData = {
  order: string;
  symbol: string;
  thumbnail: string;
  usd_price: string;
  balance_formatted: string;
  usd_value: string;
  usd_price_24hr_percent_change: string;
  entry_price: string;
  streak: string;
};

export const emptyData: Array<EmptyTokenData> = Array.from({ length: 10 }, () => ({
  order: NoOrder,
  symbol: '-',
  thumbnail: NoCrypto,
  usd_price: '-',
  balance_formatted: '-',
  usd_value: '-',
  usd_price_24hr_percent_change: '-',
  entry_price: '-',
  streak: '-',
}));
