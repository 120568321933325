import React, { FC, useState, useMemo, useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';

import { useNavigate } from 'react-router-dom';

import closeIcon from '../../assets/img/modal-close-icon.svg';
import searchInputIcon from '../../assets/img/search-icon.svg';
import startSearchingIcon from '../../assets/img/start-searching-icon.svg';
import nothingFoundIcon from '../../assets/img/nothing-found-icon.svg';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NoThumbnail from '../../assets/img/nocoin.png';
import { useDebounce } from 'use-debounce';
import { searchTokens } from '../../services/api/apes/tokenFetchers';
import { TokenSearchResult } from '../../services/api/apes/interfaces';
import { formatNumber } from '../../utils/formatter';
import { getChainNameById } from '../../blockchain/utils';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const SearchModal: FC<ModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 300);
  const [searchResultsData, setSearchResultsData] = useState<TokenSearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current?.focus();
      }
    }, 1);
  }, [open, inputRef]);

  useEffect(() => {
    setIsLoading(true);

    if (debouncedInputValue.length) {
      searchTokens(debouncedInputValue).then((res) => {
        setIsLoading(false);
        setSearchResultsData(res.data ? res.data : []);
      });
    } else {
      setSearchResultsData([]);
    }
  }, [debouncedInputValue]);

  const onSearchItemClick = (item: TokenSearchResult) => {
    onClose();
    navigate(`/asset/${item.token.networkId}/${item.token.address}`);
  };

  const SearchResults = useMemo(() => {
    // Condition for Start searching UI
    if (!debouncedInputValue.length) {
      return (
        <div className="message-wrap">
          <div className="icon-wrap">
            <img src={startSearchingIcon} alt="Start searching" />
          </div>
          <div className="title-wrap">Start searching</div>
          <div className="text-wrap">Token contract, symbol or name</div>
        </div>
      );
    }

    // Condition for loading UI
    if (isLoading && debouncedInputValue.length) {
      return (
        <div className="message-wrap">
          <div className="icon-wrap">
            <img src={startSearchingIcon} alt="Start searching" />
          </div>
          <div className="title-wrap">Searching...</div>
          <div className="text-wrap">By token contract, symbol or name</div>
        </div>
      );
    }

    // Condition for items UI
    if (!isLoading && searchResultsData.length) {
      return (
        <div className="items-wrap">
          {searchResultsData.map((item) => {
            const increase = parseFloat(item.change24) >= 0;

            return (
              <div
                className="item-wrap"
                onClick={() => onSearchItemClick(item)}
                key={item.token.address + item.token.networkId}>
                <div className="logo-wrap">
                  <img src={item.token.info.imageThumbUrl ? item.token.info.imageThumbUrl : NoThumbnail} alt="Logo" />
                </div>
                <div className="info-wrap">
                  <div className="token-wrap">
                    <div className="pair-wrap">
                      {item.token.name} ({item.token.symbol}) | {getChainNameById(item.token.networkId)}
                    </div>
                    <div className="summary-wrap">${formatNumber(item.priceUSD).value}</div>
                    <div className={`change-wrap ${increase ? 'increase' : 'reduce'}`}>
                      {increase ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      {parseFloat(item.change24).toFixed(2)}%
                    </div>
                  </div>
                  <div className="details-wrap">
                    {item.liquidity ? (
                      <div className="details-item-wrap">
                        <span className="label">Liquidity:</span>
                        <span className="value">${formatNumber(item.liquidity).value}</span>
                      </div>
                    ) : null}
                    {item.volume24 ? (
                      <div className="details-item-wrap">
                        <span className="label">24H Volume:</span>
                        <span className="value">${formatNumber(item.volume24).value}</span>
                      </div>
                    ) : null}
                    {item.marketCap ? (
                      <div className="details-item-wrap">
                        <span className="label">Market Cap:</span>
                        <span className="value">${formatNumber(item.marketCap).value}</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="favorite-wrap"></div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    // Condition for Nothing found here UI
    if (!isLoading && !searchResultsData.length) {
      return (
        <div className="message-wrap">
          <div className="icon-wrap">
            <img src={nothingFoundIcon} alt="Nothing found" />
          </div>
          <div className="title-wrap">Nothing found here</div>
          <div className="text-wrap">Please try different request</div>
        </div>
      );
    }

    return null;
  }, [debouncedInputValue, searchResultsData]);

  return (
    <Modal open={open} onClose={onClose} className="modal-wrap modal-search-wrap">
      <div className="modal-content-wrap">
        <div className="close-wrap" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="form-wrap">
          <div className="icon-wrap">
            <img src={searchInputIcon} alt="Search" />
          </div>
          <input
            ref={inputRef}
            autoFocus
            type="text"
            placeholder="Type here"
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <div className="search-results-wrap">{SearchResults}</div>
      </div>
    </Modal>
  );
};

export default SearchModal;
