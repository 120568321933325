import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import arrowIcon from '../../assets/img/dropdown-arrow-icon.svg';
import { TokenData } from '../../services/api/apes/interfaces';
import { formatNumber, formatWalletAddress } from '../../utils/formatter';
import {selectBalanceVisibility, setSelectedWalletAddress} from '../../store/slices/userSlice';

interface AssetsListItem {
  thumbnail: string | undefined;
  symbol: string;
  name: string;
  amount: number;
  increase: boolean;
  change: number;
  tokenAddress: string;
  chain: string;
}

interface AssetsListProps {
  data: TokenData[];
  page?: string;
}

export const getTokenId = (tokenAddress: string | undefined, chain: string | undefined | number) =>
  `${tokenAddress}:${chain}`;

export const getTokenIdForAllChains = (tokenAddress: string | undefined) => getTokenId(tokenAddress, 0);

export const parseTokenId = (tokenId: string) => {
  const [address, chainId] = tokenId.split(':');
  return { address, chainId: parseInt(chainId, 10) };
};

const WidgetAssetsList: FC<AssetsListProps> = ({ data }) => {
  const dispatch = useDispatch();
  const [isFullView, setIsFullView] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tokenAddress = pathname.split(`/asset/`)[1]?.split('/').pop() || '';
  const chain = parseInt(pathname.split(`/asset/`)[1]?.split('/')[0], 10);
  const tokenId = getTokenId(tokenAddress, chain);
  const isMobileListView = useMediaQuery('(max-width: 991.98px)');
  const [assetsData, setAssetsData] = useState<AssetsListItem[]>([]);
  const [walletData, setWalletData] = useState<{ address: string; amount: number }[] | null>(null);
  const isBalanceVisible = useSelector(selectBalanceVisibility);

  const handleSetActiveAsset = (item: AssetsListItem) => {
    const { symbol, name, thumbnail, tokenAddress, chain } = item;
    const selectedAssetData = { symbol, name, thumbnail, tokenAddress, chain };
    setIsFullView(false);
    navigate(`/asset/${chain}/${tokenAddress}`);
    localStorage.setItem('selectedAssetData', JSON.stringify(selectedAssetData));
  };

  const onWalletSelect = (e: React.MouseEvent<HTMLSpanElement>) => {
    const selectedWalletAddress = e.currentTarget.textContent;

    if (!selectedWalletAddress) {
      return;
    }

    dispatch(setSelectedWalletAddress(selectedWalletAddress));
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setAssetsData(
      data.map((item: TokenData) => {
        const {
          token_address: tokenAddress,
          chain,
          symbol,
          name,
          thumbnail,
          usd_value: amount,
          usd_price_24hr_percent_change: change,
        } = item;
        const increase = change > 0;

        return {
          tokenAddress,
          chain,
          symbol,
          name,
          thumbnail,
          amount,
          increase,
          change,
        };
      }),
    );
  }, [data]);

  useEffect(() => {
    if (!assetsData || !tokenAddress || !chain) {
      return;
    }

    const selectedAsset = data.find(
      (item) => getTokenId(item.token_address, item.chain).toLowerCase() === tokenId.toLowerCase(),
    );

    if (!selectedAsset) {
      return;
    }

    setWalletData(
      selectedAsset.wallet_addresses.map((wallet) => ({ address: wallet.address, amount: wallet.usd_value })),
    );
  }, [assetsData, tokenAddress, chain]);

  useEffect(() => {
    if (!walletData) {
      return;
    }

    dispatch(setSelectedWalletAddress(walletData[0].address));
  }, [walletData]);

  return (
    <div className="text-wrap">
      <div className="name-wrap">My assets</div>
      <div className={`list-wrap ${isFullView ? 'full' : ''}`}>
        {assetsData?.map((item: AssetsListItem, index) => {
          const { thumbnail, symbol, name, amount, increase, change, chain, tokenAddress } = item;
          const isCurrentAsset = getTokenId(tokenAddress, chain).toLowerCase() === tokenId.toLowerCase();

          return (
            <div
              className={`list-item ${isCurrentAsset ? 'active' : ''}`}
              onClick={
                isCurrentAsset && isMobileListView ? () => setIsFullView(!isFullView) : () => handleSetActiveAsset(item)
              }
              key={index}>
              <div className="headline-wrap">
                <div className="token-wrap">
                  <div className="logo-wrap">
                    <img src={thumbnail} alt="Logo" />
                  </div>
                  <div className="text-wrap">
                    <div className="symbol-wrap">{symbol}</div>
                    <div className="asset-name-wrap">{name}</div>
                  </div>
                </div>
                <div className={`change-wrap ${increase ? 'increase' : 'reduce'}`}>
                  <div className="info-wrap">
                    <span className="amount-wrap">
                        ${isBalanceVisible
                            ? formatNumber(amount, 'M', true).value.toString()
                            : '*****'
                        }
                    </span>
                    <span className="value-wrap">
                      {increase ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      {`${Math.abs(change).toFixed(2)}%`}
                    </span>
                  </div>
                  {isMobileListView && isCurrentAsset ? (
                    <div className="action-wrap">
                      <img src={arrowIcon} alt="Arrow" />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="wallets-wrap">
                {walletData?.map((wallet, index) => {
                  const { address, amount } = wallet;

                  return (
                    <div className="wallet-item-wrap" key={index}>
                      <span className="address-wrap" onClick={onWalletSelect}>
                        {formatWalletAddress(address, {
                          numChars: 3,
                          separator: '...',
                          toLowerCase: true,
                          toChecksum: false,
                          ignorePrefix: true,
                        })}
                      </span>
                      <span className="amount-wrap">{`$${isBalanceVisible ? formatNumber(amount).value : '*****'}`}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WidgetAssetsList;
