import { AxiosResponse } from 'axios';
import { ApiResponse } from './interfaces';
import { api, ENDPOINTS } from './endpoints';

export const fetchCurrentNonce = async (walletAddress: string, tokenId: string) => {
  const response: AxiosResponse<ApiResponse<{ nextNonce: number }>> = await api.get(ENDPOINTS.DERISK_NONCE, {
    params: { walletAddress, tokenId },
    withCredentials: true,
  });
  return response.data;
};

export const createDeriskOrder = async (
  tokenId: string,
  walletAddress: string,
  nonce: number,
  principalSize: number,
  trailedDeriskPercentage: number,
  minDeriskX: number,
  takeProfitPercentage: number,
  stopLossPercentage: number,
  automatic: boolean,
  signature?: string,
  deadline?: number,
) => {
  const response: AxiosResponse<ApiResponse<null>> = await api.post(ENDPOINTS.DERISK_CREATE_ORDER, {
    tokenId,
    walletAddress,
    nonce,
    principalSize,
    trailedDeriskPercentage,
    minDeriskX,
    takeProfitPercentage,
    stopLossPercentage,
    automatic,
    signature,
    deadline,
  });
  return response.data;
};
