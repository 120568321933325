import { FC, useEffect, useState } from 'react';
import { BLOCKCHAIN_IDS } from '../../blockchain/constants';
import chartExample from '../../assets/img/asset-chart-example.svg';
import { wrapTokenAddressIfNeeded } from '../../blockchain/utils';

interface AssetChartProps {
  data: {
    increase: boolean;
    change: string;
    cap: string;
    volume: string;
  };
  tokenAddress: string;
  chain: number;
}

const WidgetAssetChart: FC<AssetChartProps> = ({ tokenAddress, chain }) => {
  const [chainName, setChainName] = useState<string | null>('ethereum');
  const wrappedTokenAddress = wrapTokenAddressIfNeeded(tokenAddress, chain);

  useEffect(() => {
    switch (chain) {
      case BLOCKCHAIN_IDS.ETHEREUM:
        setChainName('ethereum');
        break;
      case BLOCKCHAIN_IDS.BSC:
        setChainName('bsc');
        break;
      case BLOCKCHAIN_IDS.BASE:
        setChainName('base');
        break;
      case BLOCKCHAIN_IDS.ARBITRUM:
        setChainName('arbitrum');
        break;
      case BLOCKCHAIN_IDS.POLYGON:
        setChainName('polygon');
        break;
      case BLOCKCHAIN_IDS.SOLANA:
        setChainName('solana');
        break;
      default:
        setChainName(null);
        break;
    }
  }, [chain]);
  return (
    <>
      <div className="chart-wrap">
        {chainName ? (
          <iframe
            src={`https://dexscreener.com/${chainName}/${wrappedTokenAddress}?embed=1&theme=dark&info=0&trades=0`}
          />
        ) : (
          <img src={chartExample} alt="Chart" />
        )}
      </div>
    </>
  );
};

export default WidgetAssetChart;
