import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import detailsIcon from '../../assets/img/details-arrow-icon.svg';
import { PnlHistory } from '../../services/api/apes/interfaces';
import { formatNumber } from '../../utils/formatter';
import {selectBalanceVisibility} from "../../store/slices/userSlice";

interface AssetHistoryProps {
  data: PnlHistory[] | null;
}

const WidgetAssetHistory: FC<AssetHistoryProps> = (props) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const isBalanceVisible = useSelector(selectBalanceVisibility);
  const [areMoreTransactionsAvailable, setAreMoreTransactionsAvailable] = useState(true);

  return (
    <>
      {/* <div className="headline-wrap">
        <div className="name-wrap">History</div>
        <div className="action-wrap">
          {areMoreTransactionsAvailable && (
            <button
              onClick={() =>
                setPage((prevState) => {
                  return prevState + 1;
                })
              }>
              See all
            </button>
          )}
        </div>
      </div> */}
      <div className="table-wrap">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Operation</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Swapped Token</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Tx</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data &&
                props.data.slice(0, page * itemsPerPage).map((row, index) => {
                  const { operation, amount, swappedAmount, swappedSymbol, date, chainscanUrl, token, tokenSymbol } =
                    row;

                  return (
                    <TableRow key={index} className={operation.toLowerCase()}>
                      <TableCell component="th" scope="row">
                        {operation}
                      </TableCell>
                      <TableCell>{isBalanceVisible && amount ? `${formatNumber(amount).value} ${tokenSymbol}` : `***** ${tokenSymbol}`}</TableCell>
                      <TableCell>
                        {isBalanceVisible && swappedAmount ? `${formatNumber(swappedAmount).value} ${swappedSymbol}` : `***** ${swappedSymbol}`}
                      </TableCell>
                      <TableCell>
                        <div className="date-wrap">{new Date(date).toLocaleDateString()}</div>
                      </TableCell>
                      <TableCell>
                        {isBalanceVisible ? (
                            <Link to={chainscanUrl} target="_blank" rel="noreferrer">
                              <span className="text">
                                {chainscanUrl.split('/')[4].substring(0, 6)}...{chainscanUrl.split('/')[4].slice(-2)}
                              </span>
                                  <span className="icon">
                                <img src={detailsIcon} alt="Details" />
                              </span>
                            </Link>
                        ) : (
                            <span>0x0000...00</span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default WidgetAssetHistory;
