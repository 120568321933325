import React, {ReactNode} from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
    valueTop: string | ReactNode;
    valueBottom: string | ReactNode;
    valueBottomPercentage: string | ReactNode;
    increase: boolean;
}

export const ChangeLabelDetailed: React.FC<Props> = ({valueTop, valueBottom, valueBottomPercentage, increase}) => (
    <div className="asset-price-mobile-wrap">
        <p className="price-value">{valueTop}</p>
        <p className={`price-change ${increase ? 'increase' : 'reduce'}`}>
            {increase ? <ArrowDropUpIcon className="mb-0.5"/> : <ArrowDropDownIcon className="mb-0.5"/>}
            {valueBottomPercentage}{valueBottom}
        </p>
    </div>
);
