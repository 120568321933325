import { createDefaultRpcTransport, createSolanaRpcFromTransport, address, type RpcTransport } from '@solana/web3.js';

const SOLANA_RPC_URL = 'https://api.mainnet-beta.solana.com';
const MAX_ATTEMPTS = 4;

const defaultTransport = createDefaultRpcTransport({ url: SOLANA_RPC_URL });

const calculateRetryDelay = (attempt: number): number => {
  return Math.min(100 * Math.pow(2, attempt), 1500);
};

const retryingTransport = async <TResponse>(...args: Parameters<RpcTransport>): Promise<TResponse> => {
  let requestError;
  for (let attempts = 0; attempts < MAX_ATTEMPTS; attempts++) {
    try {
      return await defaultTransport(...args);
    } catch (err) {
      requestError = err;
      // Only sleep if we have more attempts remaining.
      if (attempts < MAX_ATTEMPTS - 1) {
        const retryDelay = calculateRetryDelay(attempts);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }
  }
  throw requestError;
};

const rpc = createSolanaRpcFromTransport(retryingTransport);

export const getAccountInfo = async (
  walletAddress: string,
  config?: {
    commitment?: string;
    encoding?: 'base58' | 'base64' | 'base64+zstd' | 'jsonParsed';
    dataSlice?: { length: number; offset: number };
    minContextSlot?: number;
  },
): Promise<any> => {
  try {
    const result = await rpc.getAccountInfo(address(walletAddress)).send();
    return result;
  } catch (error) {
    // console.error('Error fetching account info:', error);
    throw error;
  }
};
