import { FC, useEffect, useState } from 'react';
import { Slider, Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  BaseError,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
  useSignTypedData,
  useAccount,
  useClient,
} from 'wagmi';
import { Address, Client, formatUnits, maxUint256 } from 'viem';
import {
  deriskConfig,
  DeriskSignaturePayload,
  feelingMarks,
  minDeriskMarks,
  prepareSignData,
  signDomain,
  signTypes,
  stopLossMarks,
  takeProfitMarks,
  trailedDeriskMarks,
} from './data';
import { selectSelectedWalletAddress, selectUserProfile } from '../../store/slices/userSlice';
import { formatNumber, formatWalletAddress, investorProfileToStrategy } from '../../utils/formatter';
import Erc20Abi from '../../blockchain/contracts/abis/Erc20Abi';
import DeriskRouterAbi from '../../blockchain/contracts/abis/DeriskRouterAbi';
import UniswapV2RouterAbi from '../../blockchain/contracts/abis/UniswapV2RouterAbi';
import { wrapTokenAddressIfNeeded } from '../../blockchain/utils';
import { BLOCKCHAIN_IDS } from '../../blockchain/constants';
import { env } from '../../config/env';
import { toastError, toastSuccess } from '../../utils/toastUtils';
import { createDeriskOrder, fetchCurrentNonce } from '../../services/api/apes/deriskFetchers';
import { getTokenId, parseTokenId } from '../WidgetAssetsList';
import { add } from 'date-fns';
import { DeriskOrder } from '../../services/api/apes/interfaces';
import { Feel, feelMarkToFeel, Profile } from '../AiStrategies/data';
import { getContractAddress } from '../../blockchain/contracts/addresses';
import { readContract } from 'viem/actions';

interface WidgetAssetDeriskProps {
  data: { tokenAddress: string; chain: number; principal: number | undefined; latestDeriskOrder: DeriskOrder | null };
}

const defaultDeriskParams = {
  feeling: 50,
  stopLossEnabled: true,
  autoDeriskEnabled: true,
  trailedDerisk: 45,
  minDerisk: 2,
  takeProfit: 25,
  stopLoss: 25,
};

const WidgetAssetDerisk: FC<WidgetAssetDeriskProps> = ({ data }) => {
  const getAmountsOut = async (
    client: Client | undefined,
    amountIn: bigint,
    path: string[],
  ): Promise<{
    amountOut: bigint;
    amountOutFormatted: string;
  }> => {
    if (!client) {
      throw new Error('Client is not available');
    }

    const wethAddress = getContractAddress('WETH', BLOCKCHAIN_IDS.ETHEREUM);

    if (path[1] != wethAddress) {
      throw new Error('Invalid path');
    }

    const result = await readContract(client, {
      abi: UniswapV2RouterAbi,
      address: getContractAddress('UNISWAPV2_ROUTER', BLOCKCHAIN_IDS.ETHEREUM) as Address,
      functionName: 'getAmountsOut',
      args: [amountIn, path as Address[]],
    });

    return {
      amountOut: result[result.length - 1],
      amountOutFormatted: formatUnits(result[result.length - 1], 18),
    };
  };

  const handleDerisk = async () => {
    // Step 0: Check initial conditions
    if (!principal) {
      toastError('Nothing to derisk');
      return;
    }

    if (!selectedWalletAddress) {
      toastError('Wallet address is missing');
      return;
    }

    if (!wrappedTokenAddress) {
      toastError('Token address is missing');
      return;
    }

    if (!chain) {
      toastError('Chain is missing');
      return;
    }

    const tokenId = getTokenId(wrappedTokenAddress, chain);
    let nextNonce;
    let signature;
    let signatureData: DeriskSignaturePayload | undefined;
    let result;

    // Step 1: Get the current nonce
    try {
      const { data } = await fetchCurrentNonce(
        selectedWalletAddress as Address,
        getTokenId(wrappedTokenAddress, chain),
      );
      nextNonce = data?.nextNonce;

      if (!nextNonce) {
        toastError('Failed to fetch nonce to sign the transaction');
        return;
      }
    } catch {
      toastError('Failed to fetch nonce to sign the transaction');
      return;
    }

    // Step 2: Generate a signature if auto-derisk is enabled
    if (autoDeriskEnabled) {
      try {
        signatureData = prepareSignData(
          selectedWalletAddress as Address,
          wrappedTokenAddress,
          nextNonce.toString(),
          add(new Date(), { months: 6 }),
        );
        signature = await signTypedDataAsync({
          types: signTypes,
          domain: signDomain,
          primaryType: 'Data',
          message: signatureData as unknown as Record<string, unknown>,
        });
      } catch {
        toastError('Failed to sign the transaction');
        return;
      }
    }

    // Step 3: Call the derisk API
    try {
      result = await createDeriskOrder(
        tokenId,
        selectedWalletAddress as Address,
        nextNonce,
        principal,
        trailedDerisk,
        minDerisk,
        takeProfit,
        stopLoss,
        autoDeriskEnabled,
        signature,
        signatureData?.deadline,
      );

      if (!result.success) {
        toastError('Failed to create derisk order');
        return;
      }

      toastSuccess('Derisk order created successfully');
    } catch {
      toastError('Failed to derisk the asset');
    }
  };

  const getDeriskPayload = async (order: DeriskOrder) => {
    const { address: tokenAddress } = parseTokenId(order.tokenId);

    if (!order.amountInBN) {
      return { error: 'AmountIn not found' };
    }

    const amountIn = BigInt(order.amountInBN);
    const signatureDeadline = order.signatureDeadline;
    const signature = order.signature;
    const slippage = BigInt(6);
    const amountOut = await getAmountsOut(client, amountIn, [
      tokenAddress,
      getContractAddress('WETH', BLOCKCHAIN_IDS.ETHEREUM)!,
    ]);
    const amountOutMin = (amountOut.amountOut * (BigInt(100) - slippage)) / BigInt(100);

    return {
      walletAddress: order.walletAddress,
      tokenAddress,
      nonce: order.nonce,
      amountIn,
      amountOutMin,
      signatureDeadline,
      signature,
    };
  };

  const handleManualDerisk = async () => {
    try {
      const { amountIn, amountOutMin, error, nonce, signature, signatureDeadline, tokenAddress, walletAddress } =
        await getDeriskPayload(latestDeriskOrder as DeriskOrder);

      if (error) {
        toastError('Failed to derisk the asset');
        return;
      }

      if (!walletAddress || !tokenAddress || !signatureDeadline || !amountIn || !amountOutMin || !signature) {
        toastError('Invalid derisk order');
        return;
      }

      await writeContractAsync({
        abi: DeriskRouterAbi,
        address: env.DERISK_ROUTER_ADDRESS as Address,
        functionName: 'derisk',
        args: [
          walletAddress as Address,
          tokenAddress as Address,
          BigInt(nonce),
          BigInt(signatureDeadline),
          amountIn,
          amountOutMin,
          signature as `0x${string}`,
        ],
      });
    } catch {
      toastError('Failed to derisk the asset');
    }
  };

  const handleApprove = async () => {
    try {
      await writeContractAsync({
        abi: Erc20Abi,
        address: wrappedTokenAddress,
        functionName: 'approve',
        args: [env.DERISK_ROUTER_ADDRESS as Address, maxUint256],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deriskActionButton = () => {
    if (chain !== BLOCKCHAIN_IDS.ETHEREUM) {
      return (
        <button disabled={true} onClick={() => {}}>
          Chain Not Supported
        </button>
      );
    }

    if (!connectedWalletAddress) {
      return (
        <button disabled={true} onClick={() => {}}>
          Connect Wallet
        </button>
      );
    }

    if (!selectedWalletAddress) {
      return (
        <button disabled={true} onClick={() => {}}>
          Select Wallet
        </button>
      );
    }

    if (connectedWalletAddress.toLowerCase() !== selectedWalletAddress.toLowerCase()) {
      return (
        <button disabled={true} onClick={() => {}}>
          Wrong Wallet
        </button>
      );
    }

    if (isAllowanceLoading || isBalanceLoading) {
      return (
        <button disabled={true} onClick={() => {}}>
          Loading...
        </button>
      );
    }

    if (balance === BigInt(0)) {
      return (
        <button disabled={true} onClick={() => {}}>
          No balance
        </button>
      );
    }

    if (minProfit && minProfit < 0) {
      return (
        <button disabled={true} onClick={() => {}}>
          Invalid Min. Derisk
        </button>
      );
    }

    if (isTxPending || isTxLoading) {
      return (
        <button disabled={true} onClick={() => {}}>
          Pending...
        </button>
      );
    }

    if (!isEnoughAllowance) {
      return (
        <button className="button" onClick={handleApprove}>
          Approve
        </button>
      );
    }

    return (
      <button className="button" onClick={handleDerisk}>
        Create Order
      </button>
    );
  };

  const manualDeriskActionButton = () => {
    if (latestDeriskOrder?.walletAddress.toLowerCase() !== selectedWalletAddress?.toLowerCase()) {
      return (
        <button disabled={true} onClick={() => {}}>
          Wrong Wallet
        </button>
      );
    }

    if (!isEnoughAllowance) {
      return (
        <button className="button" onClick={handleApprove}>
          Approve
        </button>
      );
    }
    return (
      <button className="button" onClick={handleManualDerisk}>
        Manual Derisk
      </button>
    );
  };

  const applyDeriskConfig = (profile: Profile, selectedFeeling: Feel) => {
    const config = deriskConfig[profile]?.[selectedFeeling];

    if (config) {
      setMinDerisk(config.minDerisk);
      setTrailedDerisk(config.trailedDerisk);
      setTakeProfit(config.takeProfit);
    }
  };

  const getMinDerisk = () => {
    const config = profile?.investorProfile
      ? deriskConfig[investorProfileToStrategy(profile.investorProfile)]?.[feelMarkToFeel(selectedFeeling)]
      : null;

    if (config) {
      return config.minDerisk;
    }

    return defaultDeriskParams.minDerisk;
  };

  const getTrailedDerisk = () => {
    const config = profile?.investorProfile
      ? deriskConfig[investorProfileToStrategy(profile.investorProfile)]?.[feelMarkToFeel(selectedFeeling)]
      : null;

    if (config) {
      return config.trailedDerisk;
    }

    return defaultDeriskParams.trailedDerisk;
  };

  const getTakeProfit = () => {
    const config = profile?.investorProfile
      ? deriskConfig[investorProfileToStrategy(profile.investorProfile)]?.[feelMarkToFeel(selectedFeeling)]
      : null;

    if (config) {
      return config.takeProfit;
    }

    return defaultDeriskParams.takeProfit;
  };

  const client = useClient();
  const { tokenAddress, chain, principal, latestDeriskOrder } = data;
  const { address: connectedWalletAddress } = useAccount();
  const selectedWalletAddress = useSelector(selectSelectedWalletAddress);
  const profile = useSelector(selectUserProfile);
  const [activeTab, setActiveTab] = useState('derisk');
  const [stopLossEnabled, setStopLossEnabled] = useState(defaultDeriskParams.stopLossEnabled);
  const [autoDeriskEnabled, setAutoDeriskEnabled] = useState(defaultDeriskParams.autoDeriskEnabled);
  const [selectedFeeling, setSelectedFeeling] = useState(defaultDeriskParams.feeling);
  const [trailedDerisk, setTrailedDerisk] = useState(getTrailedDerisk());
  const [minDerisk, setMinDerisk] = useState(getMinDerisk());
  const [takeProfit, setTakeProfit] = useState(getTakeProfit());
  const [stopLoss, setStopLoss] = useState(defaultDeriskParams.stopLoss);
  const [minProfit, setMinProfit] = useState<number | null>(null);
  const [isEnoughAllowance, setIsEnoughAllowance] = useState(false);
  const {
    writeContractAsync,
    failureReason: txFailureReason,
    error: txError,
    data: txHash,
    isPending: isTxPending,
  } = useWriteContract();
  const wrappedTokenAddress = wrapTokenAddressIfNeeded(tokenAddress, BLOCKCHAIN_IDS.ETHEREUM) as Address;
  const {
    data: allowance,
    isLoading: isAllowanceLoading,
    refetch: refetchAllowance,
  } = useReadContract({
    abi: Erc20Abi,
    address: wrapTokenAddressIfNeeded(tokenAddress, BLOCKCHAIN_IDS.ETHEREUM) as Address,
    functionName: 'allowance',
    args: [selectedWalletAddress as Address, env.DERISK_ROUTER_ADDRESS as Address],
  });
  const { data: balance, isLoading: isBalanceLoading } = useReadContract({
    abi: Erc20Abi,
    address: wrappedTokenAddress,
    functionName: 'balanceOf',
    args: [selectedWalletAddress as Address],
  });
  const {
    isLoading: isTxLoading,
    isSuccess: isTxSuccess,
    error: txRevertError,
  } = useWaitForTransactionReceipt({ hash: txHash });
  const { signTypedDataAsync } = useSignTypedData();
  const [manualDeriskAvailable, setManualDeriskAvailable] = useState(false);

  useEffect(() => {
    if (allowance === undefined || balance === undefined) {
      return;
    }

    setIsEnoughAllowance(allowance >= balance);
  }, [allowance, balance]);

  useEffect(() => {
    if (isTxSuccess) {
      refetchAllowance();
      toastSuccess('Transaction successful');
    }

    if (txError) {
      toastError((txError as BaseError).shortMessage || 'Transaction failed');
    }
  }, [isTxSuccess, txFailureReason, txError, isTxPending, txHash, txError, txRevertError]);

  useEffect(() => {
    if (!stopLossEnabled) {
      setStopLoss(0);
    }
  }, [stopLossEnabled]);

  useEffect(() => {
    if (!minDerisk || !principal || !trailedDerisk) {
      return;
    }

    setMinProfit(Math.round((minDerisk * principal * (100 - trailedDerisk)) / 100 - principal));
  }, [minDerisk, principal, trailedDerisk]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    applyDeriskConfig(investorProfileToStrategy(profile.investorProfile), feelMarkToFeel(selectedFeeling));
  }, [selectedFeeling, profile]);

  useEffect(() => {
    if (!latestDeriskOrder || latestDeriskOrder.executed || !env.DERISK_ROUTER_ADDRESS) {
      setManualDeriskAvailable(false);
      return;
    }

    setManualDeriskAvailable(true);
  }, [latestDeriskOrder]);

  return (
    <>
      <div className="headline-wrap">
        <div className="tabs-wrap tabs-underline-wrap">
          <div className={`item-tab ${activeTab === 'derisk' ? 'active' : ''}`} onClick={() => setActiveTab('derisk')}>
            Derisk
          </div>
          <div className={`item-tab disabled ${activeTab === 'dca' ? 'active' : ''}`} onClick={() => {}}>
            DCA
          </div>
          <div className={`item-tab disabled ${activeTab === 'swap' ? 'active' : ''}`} onClick={() => {}}>
            Swap
          </div>
        </div>
      </div>
      <div className="content-wrap">
        <div className="sliders-wrap">
          <div className="item-wrap">
            <div className="title-wrap">
              <span className="text-wrap">Feeling</span>
            </div>
            <Slider
              className="red-to-green"
              shiftStep={50}
              step={50}
              min={0}
              max={100}
              marks={feelingMarks}
              value={selectedFeeling}
              onChange={(_, value) => setSelectedFeeling(value as number)}
            />
          </div>
          <div className="item-wrap">
            <div className="title-wrap">
              <span className="text-wrap">Min. Derisk</span>
            </div>
            <Slider
              step={minDerisk < 10 ? 0.1 : 1}
              min={1.1}
              max={10}
              marks={minDeriskMarks}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}x`}
              value={minDerisk}
              onChange={(_, value) => setMinDerisk(value as number)}
            />
          </div>
          <div className="item-wrap">
            <div className="title-wrap">
              <span className="text-wrap">Take Profit</span>
            </div>
            <Slider
              shiftStep={1}
              step={1}
              min={0}
              max={100}
              marks={takeProfitMarks}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              value={takeProfit}
              onChange={(_, value) => setTakeProfit(value as number)}
            />
          </div>
          <div className="item-wrap">
            <div className="title-wrap">
              <span className="text-wrap">Trailed Derisk</span>
            </div>
            <Slider
              shiftStep={1}
              step={1}
              min={0}
              max={90}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              marks={trailedDeriskMarks}
              value={trailedDerisk}
              onChange={(_, value) => setTrailedDerisk(value as number)}
            />
          </div>
          <div className="item-wrap">
            <div className="title-wrap">
              <span className="text-wrap">Stop Loss</span>
              <div className="switch-wrap">
                <Switch
                  checked={stopLossEnabled}
                  onChange={(event) => setStopLossEnabled(event.target.checked)}
                  focusVisibleClassName=".Mui-focusVisible"
                  disableRipple
                />
              </div>
            </div>
            <Slider
              shiftStep={1}
              step={1}
              min={0}
              max={100}
              marks={stopLossMarks}
              disabled={!stopLossEnabled}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
              value={stopLoss}
              onChange={(_, value) => setStopLoss(value as number)}
            />
          </div>
          <div className="item-wrap">
            <div className="title-wrap">
              <span className="text-wrap">Auto-Derisk</span>
              <div className="switch-wrap">
                <Switch
                  checked={autoDeriskEnabled}
                  onChange={(event) => setAutoDeriskEnabled(event.target.checked)}
                  focusVisibleClassName=".Mui-focusVisible"
                  disableRipple
                />
              </div>
            </div>
          </div>
        </div>
        <div className="total-wrap">
          <div className="item-wrap">
            <span className="label">Wallet Address: </span>
            <span className="value">{selectedWalletAddress ? formatWalletAddress(selectedWalletAddress, {}) : ''}</span>
          </div>
          <div className="item-wrap">
            <span className="label">Min. Derisk: </span>
            <span className="value">
              {latestDeriskOrder
                ? `${latestDeriskOrder.minDeriskX.toFixed(0)}x ${principal ? `($${(Math.round(principal * latestDeriskOrder.minDeriskX) / 1000).toFixed(1)}K) ` : ''}->`
                : ``}{' '}
              {minDerisk >= 10 ? minDerisk.toFixed(0) : minDerisk.toFixed(1)}x $
              {principal ? `($${(Math.round(principal * minDerisk) / 1000).toFixed(1)}K)` : ''}
            </span>
          </div>
          <div className="item-wrap">
            <span className="label">Trailed Derisk:</span>
            <span className="value">
              {latestDeriskOrder ? `${latestDeriskOrder.trailedDeriskPercentage}% ->` : ``} {trailedDerisk}%
            </span>
          </div>
          <div className="item-wrap">
            <span className="label">Principal:</span>
            <span className="value">{principal ? `$${formatNumber(principal).value}` : '...'}</span>
          </div>
          <div className="item-wrap">
            <span className="label">Profit Taking:</span>
            <span className="value">Principal + {takeProfit}% of profit</span>
          </div>
          <div className="item-wrap">
            <span className="label">Min. Profit:</span>
            <span className="value">${minProfit ? minProfit : '...'}</span>
          </div>
          <div className="item-wrap">
            <span className="label">Max. Loss:</span>
            <span className="value">${principal ? Math.round((principal * stopLoss) / 100) : '...'}</span>
          </div>
        </div>
      </div>
      <div className="action-wrap">{deriskActionButton()}</div>
      {manualDeriskAvailable && <div className="action-wrap">{manualDeriskActionButton()}</div>}
    </>
  );
};

export default WidgetAssetDerisk;
