import { FC } from 'react';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  value: string;
  increase: boolean;
}

export const ChangeLabelSimple: FC<Props> = ({ value, increase }) => (
  <div className={`simple-label-wrap ${increase ? 'increase' : 'reduce'}`}>
    {increase ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    {value.split('-').join('')}
  </div>
);
