import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ReactGA from 'react-ga4';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';

import ResizeHandle from './ResizeHandle';
import WidgetAssetsList, { getTokenId } from './WidgetAssetsList';
import WidgetAssetChart from './WidgetAssetChart';
import WidgetAssetDerisk from './WidgetAssetDerisk';
import WidgetAssetHistory from './WidgetAssetHistory';
import WidgetProfileStrategy from './WidgetProfileStrategy';
import WidgetsAssetInfo from './WidgetsAssetInfo';
import NewWalletModal from './NewWalletModal';

import strategyIcon from '../assets/img/strategy-options-icon.svg';
import closeIcon from '../assets/img/modal-close-icon.svg';
import { useSelector } from 'react-redux';
import { usePortfolioPnl } from '../services/api/apes/useUserFetchers';
import { DeriskOrder, PnlHistory, PnlResults, TokenData, TokenInfo } from '../services/api/apes/interfaces';
import { formatNumber } from '../utils/formatter';
import { useTokenInfo } from '../services/api/apes/useTokenFetchers';
import { getExchangeUrl, wrapTokenAddressIfNeeded } from '../blockchain/utils';
import {
  selectBalanceVisibility,
  selectDeriskOrders,
  selectPortfolio,
  selectUserMasterWalletAddress
} from '../store/slices/userSlice';
import { BLOCKCHAIN_IDS } from '../blockchain/constants';
import { Address } from 'viem';
import { ReactComponent as InfoIcon } from '../assets/img/icons/ic-info.svg';
import { SvgIcon } from '@mui/material';

const Asset: FC = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Assets' });
  const { pathname } = useLocation();
  const tokenAddress = pathname.split('/asset/')[1].split('/').pop() || '';
  const chain = parseInt(pathname.split('/asset/')[1].split('/')[0], 10);
  const wrappedTokenAddress = wrapTokenAddressIfNeeded(tokenAddress, BLOCKCHAIN_IDS.ETHEREUM) as Address;
  const tokenId = getTokenId(wrappedTokenAddress, chain);
  const masterWalletAddress = useSelector(selectUserMasterWalletAddress);
  const isBalanceVisible = useSelector(selectBalanceVisibility);
  const deriskOrders = useSelector(selectDeriskOrders);
  const portfolio = useSelector(selectPortfolio);
  const [isNewWalletModalOpen, setIsNewWalletModalOpen] = useState(false);
  const [isStrategyModalOpen, setIsStrategyModalOpen] = useState(false);
  const { pnl: pnlData } = usePortfolioPnl(masterWalletAddress, tokenAddress, chain);
  const { tokenInfo: tokenInfoResult } = useTokenInfo(wrapTokenAddressIfNeeded(tokenAddress, chain), chain);
  const isStrategyModal = useMediaQuery('(max-width: 1199.98px)');
  const [tokenDatas, setTokenDatas] = useState<TokenData[]>([]);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null);
  const [totalPortfolioData, setTotalPortfolioData] = useState<{
    balance: number;
    increase: boolean | undefined;
    change: number | undefined;
    updated: number;
  }>({
    balance: 0,
    increase: undefined,
    change: undefined,
    updated: 0,
  });
  const [walletHistory, setWalletHistory] = useState<PnlHistory[] | null>(null);
  const [pnlInfo, setPnlInfo] = useState<PnlResults | null>(null);
  const [pnlStats, setPnlStats] = useState<
    {
      name: string;
      amount: string | number;
      coloredAmount?: boolean;
      increase?: boolean;
      change?: string;
      derisked?: boolean;
      isNumber?: boolean;
      prefix?: string;
    }[]
  >([]);
  const [chartData, setChartData] = useState<{
    increase: boolean;
    change: string;
    cap: string;
    volume: string;
  }>({
    increase: false,
    change: '-',
    cap: '-',
    volume: '-',
  });
  const [principal, setPrincipal] = useState<number | undefined>(undefined);
  const [latestDeriskOrder, setLatestDeriskOrder] = useState<DeriskOrder | null>(null);
  const [deriskData, setDeriskData] = useState({
    tokenAddress: tokenAddress,
    chain: chain,
    principal: principal,
    latestDeriskOrder: latestDeriskOrder,
  });
  const [hasEntry, setHasEntry] = useState(false);

  const StrategySection: FC<{ hasEntry: boolean; handleSwapClick: () => void }> = ({ hasEntry, handleSwapClick }) => (
    <div className="widgets-column widgets-column-3-wrap">
      <div className="widget-wrap widget-asset-derisk-wrap">
        <WidgetAssetDerisk data={deriskData} />
        {!hasEntry && (
          <div className="overlay">
            <div className="overlay-text">
              <SvgIcon className="overlay-icon" component={InfoIcon} />
              <p>Risk Management module is not available for this asset</p>
            </div>
            <button onClick={handleSwapClick}>Go to swap</button>
          </div>
        )}
      </div>
    </div>
  );

  const handleSwapClick = () => {
    const url = getExchangeUrl(chain, wrappedTokenAddress);
    if (url) {
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    if (!portfolio.data) {
      setTokenDatas([]);
      return;
    }

    setTokenDatas(portfolio.data.portfolio);
  }, [portfolio]);

  useEffect(() => {
    if (portfolio.data) {
      setTotalPortfolioData({
        balance: portfolio.data.totalPortfolioValue,
        increase: portfolio.data.portfolioPercentualChange > 0,
        change: portfolio.data.portfolioPercentualChange,
        updated: portfolio.data.updateTime,
      });
    }
  }, [portfolio]);

  useEffect(() => {
    setWalletHistory(pnlData?.data?.pnlHistory ? pnlData.data.pnlHistory : null);
    setPnlInfo(pnlData?.data?.pnlData ? pnlData.data.pnlData : null);
  }, [pnlData]);

  useEffect(() => {
    const setData = async () => {
      const currentTokenData = tokenDatas.find((data) => data.token_id.toLowerCase() === tokenId.toLowerCase());
      const priceUsd = tokenInfo?.data.priceUSD ? parseFloat(tokenInfo.data.priceUSD) : undefined;
      const avgEntryPriceInUsd = currentTokenData?.entry_price;
      const price = priceUsd;
      const change = tokenInfo?.data?.change24 ? parseFloat(tokenInfo.data.change24) * 100 : undefined;
      const changeText = change ? Math.abs(change).toFixed(2) + '%' : '-';
      const increase = change ? change > 0 : undefined;
      const performance = avgEntryPriceInUsd && priceUsd ? priceUsd / avgEntryPriceInUsd : undefined;
      const formatPnl = (pnl: number) => {
        if (pnl > 0) {
          return `+ $${formatNumber(pnl).value}`;
        }

        return `- $$${formatNumber(pnl).value}`;
      };

      setPnlStats([
        {
          name: 'Entry point',
          amount: avgEntryPriceInUsd && !isNaN(avgEntryPriceInUsd) ? avgEntryPriceInUsd : '-',
          isNumber: avgEntryPriceInUsd && !isNaN(avgEntryPriceInUsd) ? true : false,
          prefix: '$',
        },
        {
          name: 'Price',
          amount: price && !isNaN(price) ? price : '-',
          increase: increase,
          change: changeText,
          isNumber: price && !isNaN(price) ? true : false,
          prefix: '$',
        },
        {
          name: 'Derisked',
          amount: latestDeriskOrder ? `${latestDeriskOrder.minDeriskX}x` : 'Not derisked',
          derisked: !!latestDeriskOrder,
          coloredAmount: false,
          increase: !!latestDeriskOrder,
        },
        {
          name: 'Mcap',
          amount: chartData.cap,
        },
        {
          name: 'Perfomance',
          amount: performance ? `${performance.toFixed(2)}x` : '-',
        },
        {
          name: 'PNL',
          amount: isBalanceVisible
              ? pnlInfo?.pnl
                  ? formatPnl(pnlInfo.pnl)
                  : '-'
              : '*****',
          coloredAmount: isBalanceVisible ? !!pnlInfo?.pnl : false,
        },
        {
          name: 'Balance',
          amount: isBalanceVisible
              ? currentTokenData?.balance_float
                ? formatNumber(currentTokenData.balance_float).value.toString()
                : '-'
              : '*****',
        },
        {
          name: '24h Volume',
          amount: chartData.volume,
        },
      ]);
      setHasEntry(!!avgEntryPriceInUsd);
      setChartData({
        increase: increase || false,
        change: changeText,
        cap: tokenInfo?.data?.marketCap ? `$${formatNumber(tokenInfo.data.marketCap).value}` : '-',
        volume: tokenInfo?.data?.volume24 ? `$${formatNumber(tokenInfo.data.volume24).value}` : '-',
      });

      if (pnlInfo?.cummulativeNativeTransfer && pnlInfo?.nativePrice) {
        setPrincipal(Math.abs(pnlInfo.cummulativeNativeTransfer) * pnlInfo.nativePrice);
      }
    };

    setData();
  }, [isBalanceVisible, tokenInfo, pnlInfo, tokenDatas, latestDeriskOrder, chartData.cap, chartData.volume, tokenId]);

  useEffect(() => {
    if (!deriskOrders || deriskOrders.length === 0) {
      setLatestDeriskOrder(null);
      return;
    }

    const relatedOrders = deriskOrders.filter((order) => order.tokenId.toLowerCase() === tokenId.toLowerCase());

    if (relatedOrders.length === 0) {
      setLatestDeriskOrder(null);
      return;
    }

    const latestOrder = relatedOrders.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )[0];
    setLatestDeriskOrder(latestOrder);
  }, [deriskOrders]);

  useEffect(() => {
    if (tokenInfoResult && tokenInfoResult.data) {
      setTokenInfo(tokenInfoResult.data);
    }
  }, [tokenInfoResult]);

  useEffect(() => {
    setDeriskData({
      tokenAddress: tokenAddress,
      chain: chain,
      principal: principal,
      latestDeriskOrder: latestDeriskOrder,
    });
  }, [tokenAddress, chain, principal, latestDeriskOrder]);

  return (
    <section className="portfolio-asset-section">
      <div className="widgets-column widgets-column-1-wrap">
        <div className="widget-wrap widget-portfolio-strategy-wrap">
          <WidgetProfileStrategy data={totalPortfolioData} />
        </div>
        <div
          className="widget-wrap strategy-button-wrap"
          onClick={() => (isStrategyModal ? setIsStrategyModalOpen(true) : null)}>
          <div className="icon">
            <img src={strategyIcon} alt="Icon" />
          </div>
          <div className="text">Strategy</div>
        </div>
        <div className="widget-wrap widget-assets-list-wrap">
          <WidgetAssetsList data={tokenDatas} />
        </div>
      </div>
      <div className="widgets-column widgets-column-2-wrap">
        <div className="widgets-asset-info-wrap">
          <WidgetsAssetInfo
            data={{
              info: tokenInfo,
              cards: pnlStats,
            }}
          />
        </div>
        <PanelGroup className="resize-panel-group-wrap" autoSaveId="asset-chart-history-resize" direction="vertical">
          <Panel className="panel-wrap" collapsible={true} defaultSize={20} order={1}>
            <div className="panel-content-wrap">
              <div className="widget-wrap widget-asset-chart-wrap">
                <WidgetAssetChart data={chartData} tokenAddress={tokenAddress} chain={chain} />
              </div>
            </div>
          </Panel>
          <ResizeHandle />
          <Panel className="panel-wrap" collapsible={true} defaultSize={20} order={2}>
            <div className="panel-content-wrap">
              <div className="widget-wrap widget-asset-history-wrap">
                <WidgetAssetHistory data={walletHistory} />
              </div>
            </div>
          </Panel>
        </PanelGroup>
      </div>
      {isStrategyModal ? (
        <Modal
          open={isStrategyModalOpen}
          onClose={() => setIsStrategyModalOpen(false)}
          className="modal-wrap modal-strategy-wrap">
          <div className="modal-content-wrap">
            <div className="close-wrap" onClick={() => setIsStrategyModalOpen(false)}>
              <img src={closeIcon} alt="Close" />
            </div>
            <StrategySection hasEntry={hasEntry} handleSwapClick={handleSwapClick} />
          </div>
        </Modal>
      ) : (
        <StrategySection hasEntry={hasEntry} handleSwapClick={handleSwapClick} />
      )}
      <NewWalletModal open={isNewWalletModalOpen} onClose={() => setIsNewWalletModalOpen(false)} />
    </section>
  );
};

export default Asset;
