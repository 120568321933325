import { AxiosResponse } from 'axios';
import { ApiResponse, TokenInfo, TokenSearchResult } from './interfaces';
import { api, ENDPOINTS } from './endpoints';

// Fetch token data
export const fetchTokenData = async (tokenAddress: string, chain: number): Promise<ApiResponse<TokenInfo>> => {
  const response: AxiosResponse<ApiResponse<TokenInfo>> = await api.get(ENDPOINTS.TOKEN_DATA, {
    params: { tokenAddress, chain },
    withCredentials: true,
  });
  return response.data;
};

// Search tokens
export const searchTokens = async (input: string): Promise<ApiResponse<TokenSearchResult[]>> => {
  const response: AxiosResponse<ApiResponse<TokenSearchResult[]>> = await api.get(ENDPOINTS.TOKEN_SEARCH, {
    params: { input },
    withCredentials: true,
  });
  return response.data;
};
