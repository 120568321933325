import { BlockchainDictionary } from './interfaces';

export const BLOCKCHAIN_EVM = 'evm';
export const BLOCKCHAIN_SOLANA = 'solana';
export const BLOCKCHAIN_UNKNOWN = 'unknown';
export const BLOCKCHAIN_IDS = {
  ETHEREUM: 1,
  CRONOS: 25,
  BSC: 56,
  POLYGON: 137,
  FANTOM: 250,
  BASE: 8453,
  ARBITRUM: 42161,
  AVALANCHE: 43114,
  SOLANA: 1399811149,
} as const;
export type BlockchainIds = (typeof BLOCKCHAIN_IDS)[keyof typeof BLOCKCHAIN_IDS];
export const AVAILABLE_CHAINS = [
  BLOCKCHAIN_IDS.ETHEREUM,
  BLOCKCHAIN_IDS.CRONOS,
  BLOCKCHAIN_IDS.BSC,
  BLOCKCHAIN_IDS.POLYGON,
  BLOCKCHAIN_IDS.FANTOM,
  BLOCKCHAIN_IDS.BASE,
  BLOCKCHAIN_IDS.ARBITRUM,
  BLOCKCHAIN_IDS.AVALANCHE,
  BLOCKCHAIN_IDS.SOLANA,
];
export const BLOCKCHAIN_TYPES: { [chainId: number]: string } = {
  [BLOCKCHAIN_IDS.ETHEREUM]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.CRONOS]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.BSC]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.POLYGON]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.FANTOM]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.BASE]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.ARBITRUM]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.AVALANCHE]: BLOCKCHAIN_EVM,
  [BLOCKCHAIN_IDS.SOLANA]: BLOCKCHAIN_SOLANA,
};
export const BLOCKCHAIN_DATA: BlockchainDictionary = {
  [BLOCKCHAIN_IDS.ETHEREUM]: {
    nativeTokenSymbol: 'ETH',
    wrappedAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    moralisName: 'eth',
    coingeckoName: 'ethereum',
    networkName: 'ETH',
    hexId: '0x1',
    id: 1,
  },
  [BLOCKCHAIN_IDS.CRONOS]: {
    nativeTokenSymbol: 'CRO',
    wrappedAddress: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    moralisName: 'cronos',
    coingeckoName: 'cronos',
    networkName: 'CRONOS',
    hexId: '0x19',
    id: 25,
  },
  [BLOCKCHAIN_IDS.BSC]: {
    nativeTokenSymbol: 'BNB',
    wrappedAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    moralisName: 'bsc',
    coingeckoName: 'binance-smart-chain',
    networkName: 'BSC',
    hexId: '0x38',
    id: 56,
  },
  [BLOCKCHAIN_IDS.POLYGON]: {
    nativeTokenSymbol: 'MATIC',
    wrappedAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    moralisName: 'matic',
    coingeckoName: 'polygon-pos',
    networkName: 'MATIC',
    hexId: '0x89',
    id: 137,
  },
  [BLOCKCHAIN_IDS.FANTOM]: {
    nativeTokenSymbol: 'FTM',
    wrappedAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    moralisName: 'fantom',
    coingeckoName: 'fantom',
    networkName: 'FANTOM',
    hexId: '0xfa',
    id: 250,
  },
  [BLOCKCHAIN_IDS.BASE]: {
    nativeTokenSymbol: 'ETH',
    wrappedAddress: '0x4200000000000000000000000000000000000006',
    moralisName: 'base',
    coingeckoName: 'base',
    networkName: 'BASE',
    hexId: '0x2105',
    id: 8453,
  },
  [BLOCKCHAIN_IDS.ARBITRUM]: {
    nativeTokenSymbol: 'ETH',
    wrappedAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    moralisName: 'arbitrum',
    coingeckoName: 'arbitrum-one',
    networkName: 'ARBITRUM',
    hexId: '0xa4b1',
    id: 42161,
  },
  [BLOCKCHAIN_IDS.AVALANCHE]: {
    nativeTokenSymbol: 'AVAX',
    wrappedAddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    moralisName: 'avalanche',
    coingeckoName: 'avalanche-2',
    networkName: 'AVALANCHE',
    hexId: '0xa86a',
    id: 43114,
  },
  [BLOCKCHAIN_IDS.SOLANA]: {
    nativeTokenSymbol: 'SOL',
    wrappedAddress: 'So11111111111111111111111111111111111111112',
    moralisName: 'solana',
    coingeckoName: 'solana',
    networkName: 'SOL',
    hexId: '0x536F6C4D',
    id: 1399811149,
  },
};
export const nativeAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'.toLowerCase();
export const nativeSolAddress = 'SoLaNa111111111111111111111111111111111111111';
export const nativeSolAddressLowercase = nativeSolAddress.toLowerCase();
