import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BacktestTokenData } from '../../services/api/apes/interfaces';
import { getTokenId } from '../../utils/tokens';

interface AssetsListItem {
  thumbnail: string | undefined;
  symbol: string;
  name: string;
  tokenAddress: string;
  chain: string;
}

interface AssetsListProps {
  data: BacktestTokenData[];
  page?: string;
  name?: string;
}

const WidgetBacktestAssetsList: FC<AssetsListProps> = ({ data, name }) => {
  const [isFullView, setIsFullView] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tokenAddress = pathname.split(`/ai-strategies/`)[1]?.split('/').pop() || '';
  const chain = parseInt(pathname.split(`/ai-strategies/`)[1]?.split('/')[0], 10);
  const tokenId = getTokenId(tokenAddress, chain);
  const isMobileListView = useMediaQuery('(max-width: 991.98px)');
  const [assetsData, setAssetsData] = useState<AssetsListItem[]>([]);

  const handleSetActiveAsset = (item: AssetsListItem) => {
    const { symbol, name, thumbnail, tokenAddress, chain } = item;
    const selectedAssetData = { symbol, name, thumbnail, tokenAddress, chain };
    setIsFullView(false);
    navigate(`/ai-strategies/${chain}/${tokenAddress}`);
    localStorage.setItem('selectedAssetData', JSON.stringify(selectedAssetData));
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setAssetsData(
      data.map((item: BacktestTokenData) => {
        return {
          name: item.name,
          symbol: item.symbol,
          thumbnail: item.logo,
          tokenAddress: item.tokenAddress,
          chain: item.chain.toString(),
        };
      }),
    );
  }, [data]);

  return (
    <div className="text-wrap">
      <div className="name-wrap">{name ? name : 'My assets'}</div>
      <div className={`list-wrap ${isFullView ? 'full' : ''}`}>
        {assetsData?.map((item: AssetsListItem, index) => {
          const { thumbnail, symbol, name, chain, tokenAddress } = item;
          const isCurrentAsset = getTokenId(tokenAddress, chain) === tokenId;

          return (
            <div
              className={`list-item ${isCurrentAsset ? 'active' : ''}`}
              onClick={
                isCurrentAsset && isMobileListView ? () => setIsFullView(!isFullView) : () => handleSetActiveAsset(item)
              }
              key={index}>
              <div className="headline-wrap">
                <div className="token-wrap">
                  <div className="logo-wrap">
                    <img src={thumbnail} alt="Logo" />
                  </div>
                  <div className="text-wrap">
                    <div className="symbol-wrap">{symbol}</div>
                    <div className="asset-name-wrap">{name}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WidgetBacktestAssetsList;
