import { AxiosResponse } from 'axios';
import {
  ApiResponse,
  UserSettings,
  Portfolio,
  PortfolioDistribution,
  PortfolioHistory,
  PortfolioPnlData,
  PortfolioTotalValue,
  PortfolioTransactions,
  UserDetails,
  DeriskOrder,
  TokenData,
  TokenWithWallets,
  UserScore,
} from './interfaces';
import { api, ENDPOINTS } from './endpoints';

// Fetch user details
export const fetchUserDetails = async (): Promise<ApiResponse<UserDetails>> => {
  const response: AxiosResponse<ApiResponse<UserDetails>> = await api.get(ENDPOINTS.USER_DETAILS, {
    withCredentials: true,
  });
  return response.data;
};

// Fetch user portfolio assets
export const fetchUserPortfolioAssets = async (targetWalletAddress?: string): Promise<ApiResponse<Portfolio>> => {
  const response: AxiosResponse<ApiResponse<Portfolio>> = await api.get(ENDPOINTS.PORTFOLIO_ASSETS, {
    params: { targetWalletAddress },
    withCredentials: true,
  });
  return response.data;
};

// Fetch user portfolio value history
export const fetchUserPortfolioHistory = async (
  startDate?: number,
  endDate?: number,
): Promise<ApiResponse<PortfolioHistory[]>> => {
  const response: AxiosResponse<ApiResponse<PortfolioHistory[]>> = await api.get(ENDPOINTS.PORTFOLIO_HISTORY, {
    params: { startDate, endDate },
    withCredentials: true,
  });
  return response.data;
};

// Fetch total portfolio value
export const fetchTotalPortfolioValue = async (): Promise<ApiResponse<PortfolioTotalValue>> => {
  const response: AxiosResponse<ApiResponse<PortfolioTotalValue>> = await api.get(ENDPOINTS.PORTFOLIO_TOTAL_VALUE, {
    withCredentials: true,
  });
  return response.data;
};

// Fetch portfolio distribution
export const fetchPortfolioDistribution = async (
  targetWalletAddress?: string,
): Promise<ApiResponse<PortfolioDistribution>> => {
  const response: AxiosResponse<ApiResponse<PortfolioDistribution>> = await api.get(ENDPOINTS.PORTFOLIO_DISTRIBUTION, {
    params: { targetWalletAddress },
    withCredentials: true,
  });
  return response.data;
};

// Fetch portfolio transactions
export const fetchPortfolioTransactions = async (
  targetWalletAddress?: string,
  page?: number,
  limit?: number,
): Promise<ApiResponse<PortfolioTransactions>> => {
  const response: AxiosResponse<ApiResponse<PortfolioTransactions>> = await api.get(ENDPOINTS.PORTFOLIO_TRANSACTIONS, {
    params: { targetWalletAddress, page, limit },
    withCredentials: true,
  });
  return response.data;
};

// Fetch portfolio pnl
export const fetchPortfolioPnl = async (
  tokenAddress: string,
  chain: number,
): Promise<ApiResponse<PortfolioPnlData>> => {
  const response: AxiosResponse<ApiResponse<PortfolioPnlData>> = await api.get(ENDPOINTS.PORTFOLIO_PNL_DATA, {
    params: { tokenAddress, chain },
    withCredentials: true,
  });
  return response.data;
};

// Fetch user settings
export const fetchUserSettings = async (): Promise<ApiResponse<UserSettings>> => {
  const response: AxiosResponse<ApiResponse<UserSettings>> = await api.get(ENDPOINTS.USER_SETTINGS, {
    withCredentials: true,
  });
  return response.data;
};

// Fetch user derisk orders
export const fetchUserDeriskOrders = async (): Promise<ApiResponse<DeriskOrder[]>> => {
  const response: AxiosResponse<ApiResponse<DeriskOrder[]>> = await api.get(ENDPOINTS.USER_DERISK_ORDERS, {
    withCredentials: true,
  });
  return response.data;
};

// Fetch average entry points for a list of tokens
export const fetchAverageEntryPrices = async (
  tokenData: TokenData[],
): Promise<ApiResponse<{ [key: string]: number }>> => {
  const tokensWithWallets: TokenWithWallets[] = tokenData.map(
    (token) =>
      ({
        tokenId: token.token_id,
        walletAddresses: token.wallet_addresses.map((wallet) => wallet.address),
      }) as TokenWithWallets,
  );

  const response: AxiosResponse<ApiResponse<{ [key: string]: number }>> = await api.get(
    ENDPOINTS.PORTFOLIO_AVG_ENTRY_PRICES,
    {
      params: { tokens: tokensWithWallets },
      withCredentials: true,
    },
  );
  return response.data;
};

// Fetch user score
export const fetchUserScore = async (): Promise<ApiResponse<UserScore>> => {
  const response: AxiosResponse<ApiResponse<UserScore>> = await api.get(ENDPOINTS.USER_SCORE, {
    withCredentials: true,
  });
  return response.data;
};
