import { getAccountInfo } from './api/solana-rpc';
import {
  BLOCKCHAIN_DATA,
  BLOCKCHAIN_EVM,
  BLOCKCHAIN_IDS,
  BLOCKCHAIN_SOLANA,
  BLOCKCHAIN_TYPES,
  BLOCKCHAIN_UNKNOWN,
  nativeAddress,
  nativeSolAddress,
} from './constants';
import { client } from './providers';
import { formatEther, isAddress } from 'viem';

interface WalletValidationResult {
  isValid: boolean;
  type: string;
}

export const getEthereumBalance = async (address: string | `0x${string}`) => {
  if (!address.startsWith('0x')) {
    throw new Error('Invalid Ethereum address format');
  }

  const balance = await client.getBalance({
    address: address as `0x${string}`,
  });

  return balance;
};

export const formatBalance = (balance: bigint | string, numDecimals: number = 0) => {
  return Number(formatEther(BigInt(balance))).toFixed(numDecimals);
};

export const getWrappedAddress = (blockchainId: number) => {
  return BLOCKCHAIN_DATA[blockchainId].wrappedAddress;
};

export const getNativeAddress = (blockchainId: number, lowerCased: boolean = true) => {
  const blockchainType = BLOCKCHAIN_TYPES[blockchainId];
  let address;

  switch (blockchainType) {
    case BLOCKCHAIN_EVM:
      address = nativeAddress;
      break;
    case BLOCKCHAIN_SOLANA:
      address = nativeSolAddress;
      break;
    default:
      throw new Error('Unsupported blockchain type');
  }

  return lowerCased ? address.toLowerCase() : address;
};

export const getChainscanTokenUrl = (chain: number | string, address: string) => {
  chain = parseInt(chain.toString(), 10);

  switch (chain) {
    case BLOCKCHAIN_IDS.ETHEREUM:
      return `https://etherscan.io/token/${address}`;
    case BLOCKCHAIN_IDS.CRONOS:
      return `https://cronoscan.com/token/${address}`;
    case BLOCKCHAIN_IDS.BSC:
      return `https://bscscan.com/token/${address}`;
    case BLOCKCHAIN_IDS.POLYGON:
      return `https://polygonscan.com/token/${address}`;
    case BLOCKCHAIN_IDS.FANTOM:
      return `https://ftmscan.com/token/${address}`;
    case BLOCKCHAIN_IDS.BASE:
      return `https://basescan.org/token/${address}`;
    case BLOCKCHAIN_IDS.ARBITRUM:
      return `https://arbiscan.io/token/${address}`;
    case BLOCKCHAIN_IDS.AVALANCHE:
      return `https://cchain.explorer.avax.network/token/${address}`;
    default:
      return `https://etherscan.io/token/${address}`;
  }
};

export const getChainscanTxUrl = (chain: number, hash: string) => {
  chain = parseInt(chain.toString(), 10);

  switch (chain) {
    case BLOCKCHAIN_IDS.ETHEREUM:
      return `https://etherscan.io/tx/${hash}`;
    case BLOCKCHAIN_IDS.CRONOS:
      return `https://cronoscan.com/tx/${hash}`;
    case BLOCKCHAIN_IDS.BSC:
      return `https://bscscan.com/tx/${hash}`;
    case BLOCKCHAIN_IDS.POLYGON:
      return `https://polygonscan.com/tx/${hash}`;
    case BLOCKCHAIN_IDS.FANTOM:
      return `https://ftmscan.com/tx/${hash}`;
    case BLOCKCHAIN_IDS.BASE:
      return `https://basescan.org/tx/${hash}`;
    case BLOCKCHAIN_IDS.ARBITRUM:
      return `https://arbiscan.io/tx/${hash}`;
    case BLOCKCHAIN_IDS.AVALANCHE:
      return `https://cchain.explorer.avax.network/tx/${hash}`;
    default:
      return `https://etherscan.io/tx/${hash}`;
  }
};

export const getBlockchainType = (chainId: number | string) => {
  chainId = parseInt(chainId.toString(), 10);
  const blockchainType = BLOCKCHAIN_TYPES[chainId];
  if (!blockchainType) return BLOCKCHAIN_EVM;
  return blockchainType;
};

export const isNativeToken = (address: string, chain: number | string): boolean => {
  const lowercasedAddress = address.toLowerCase();
  const nativeAddress = getNativeAddress(parseInt(chain.toString(), 10), true);
  return lowercasedAddress === nativeAddress;
};

export const wrapTokenAddressIfNeeded = (tokenAddress: string, chain: number) => {
  let parsedTokenAddress = tokenAddress.toLowerCase();

  if (isNativeToken(tokenAddress, chain)) {
    parsedTokenAddress = BLOCKCHAIN_DATA[chain].wrappedAddress.toLowerCase();
  }

  return parsedTokenAddress;
};

export const isValidEthereumAddress = (address: string) => {
  return isAddress(address);
};

export const isValidSolanaAddress = async (address: string) => {
  // This fails for some reason.
  // const wallet = new PublicKey(address);
  // const isValid = PublicKey.isOnCurve(wallet.toString());
  // const isValidBytes = PublicKey.isOnCurve(wallet.toBytes());

  // Instead we call getAccountInfo to check if the address is valid.
  try {
    const walletInfo = await getAccountInfo(address);
    return walletInfo.value !== null;
  } catch {
    return false;
  }
};

export const validateEthereumAddress = (address: string): WalletValidationResult => {
  const isValid = isValidEthereumAddress(address);

  return {
    isValid,
    type: isValid ? BLOCKCHAIN_EVM : BLOCKCHAIN_UNKNOWN,
  };
};

export const validateSolanaAddress = async (address: string): Promise<WalletValidationResult> => {
  const isValid = await isValidSolanaAddress(address);

  return {
    isValid,
    type: isValid ? BLOCKCHAIN_SOLANA : BLOCKCHAIN_UNKNOWN,
  };
};

export const validateBlockchainAddress = async (
  address: string,
  validators: Array<(address: string) => Promise<WalletValidationResult> | WalletValidationResult>,
): Promise<WalletValidationResult> => {
  for (const validator of validators) {
    const result = await validator(address);
    if (result.isValid) return result;
  }

  return { isValid: false, type: BLOCKCHAIN_UNKNOWN };
};

export const validateEthOrSolAddress = (address: string): Promise<WalletValidationResult> => {
  return validateBlockchainAddress(address, [validateEthereumAddress, validateSolanaAddress]);
};

export const getChainNameById = (chainId: number | string) => {
  chainId = parseInt(chainId.toString(), 10);

  switch (chainId) {
    case BLOCKCHAIN_IDS.ETHEREUM:
      return 'Ethereum';
    case BLOCKCHAIN_IDS.CRONOS:
      return 'Cronos';
    case BLOCKCHAIN_IDS.BSC:
      return 'Binance Smart Chain';
    case BLOCKCHAIN_IDS.POLYGON:
      return 'Polygon';
    case BLOCKCHAIN_IDS.FANTOM:
      return 'Fantom';
    case BLOCKCHAIN_IDS.BASE:
      return 'Base';
    case BLOCKCHAIN_IDS.ARBITRUM:
      return 'Arbitrum';
    case BLOCKCHAIN_IDS.AVALANCHE:
      return 'Avalanche';
    case BLOCKCHAIN_IDS.SOLANA:
      return 'Solana';
    default:
      return 'Not Supported';
  }
};

export const getChainUniswapName = (chain: number) => {
  chain = parseInt(chain.toString(), 10);

  switch (chain) {
    case BLOCKCHAIN_IDS.ETHEREUM:
      return 'mainnet';
    case BLOCKCHAIN_IDS.BSC:
      return 'bnb';
    case BLOCKCHAIN_IDS.POLYGON:
      return 'polygon';
    case BLOCKCHAIN_IDS.BASE:
      return 'base';
    case BLOCKCHAIN_IDS.ARBITRUM:
      return 'arbitrum';
    case BLOCKCHAIN_IDS.AVALANCHE:
      return 'avalanche';
    default:
      return 'mainnet';
  }
};

export const getExchangeUrl = (chain: number | string, tokenAddress: string) => {
  chain = parseInt(chain.toString(), 10);

  switch (chain) {
    case BLOCKCHAIN_IDS.ETHEREUM:
    case BLOCKCHAIN_IDS.BSC:
    case BLOCKCHAIN_IDS.POLYGON:
    case BLOCKCHAIN_IDS.BASE:
    case BLOCKCHAIN_IDS.ARBITRUM:
    case BLOCKCHAIN_IDS.AVALANCHE:
      return `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=${tokenAddress}&chain=${getChainUniswapName(chain)}`;
  }
};
