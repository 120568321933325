import React, { FC, useState } from 'react';
import Modal from '@mui/material/Modal';

import closeIcon from '../../assets/img/modal-close-icon.svg';
import { addWallet } from '../../services/api/apes/userSettings';
import { toastError, toastSuccess } from '../../utils/toastUtils';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../services/api/apes/interfaces';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const NewWalletModal: FC<ModalProps> = ({ open, onClose }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddWallet = async () => {
    if (!inputValue) {
      return;
    }

    try {
      await addWallet(inputValue);
      toastSuccess('Wallet added successfully');
    } catch (e: unknown) {
      const error = e as AxiosError<ApiResponse<null>>;
      const reqError = error.response?.data.error;
      const valErrors = error.response?.data.errors;
      let errorText = 'Unknown error occurred';

      if (valErrors && valErrors.length) {
        errorText = valErrors[0].msg;
      } else {
        errorText = reqError || errorText;
      }

      toastError(errorText);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="modal-wrap">
      <div className="modal-content-wrap">
        <div className="close-wrap" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="headline-wrap">New Wallet</div>
        <div className="form-wrap">
          <input type="text" placeholder="Enter wallet address" onChange={(e) => setInputValue(e.target.value)} />
          <button onClick={handleAddWallet} className={!inputValue ? 'disabled' : ''}>
            Add Wallet
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewWalletModal;
