import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import WidgetAssetRatio from './WidgetAssetRatio';
import WidgetAssetsTable from './WidgetAssetsTable';
import WidgetPortfolioStats from './WidgetPortfolioStats';
import WidgetProfileStrategy from './WidgetProfileStrategy';
import WidgetProfileStreak from './WidgetProfileStreak';
import NewWalletModal from './NewWalletModal';
import { TokenData } from '../services/api/apes/interfaces';
import {
  selectBalanceVisibility,
  selectPortfolio,
  selectUserMasterWalletAddress,
  selectUserProfile,
  selectUserScore,
} from '../store/slices/userSlice';
import WidgetNotificationCard, { NotificationCard } from './WidgetNotificationCard';
import { investorProfileToStrategy } from '../utils/formatter';

const Portfolio: FC = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Portfolio' });
  const masterWalletAddress = useSelector(selectUserMasterWalletAddress);
  const portfolio = useSelector(selectPortfolio);
  const userScore = useSelector(selectUserScore);
  const isBalanceVisible = useSelector(selectBalanceVisibility);
  const [tokenData, setTokenData] = useState<TokenData[]>([]);
  const [totalPortfolioData, setTotalPortfolioData] = useState<{
    balance: number;
    increase: boolean | undefined;
    change: number | undefined;
    updated: number;
  }>({
    balance: 0,
    increase: undefined,
    change: undefined,
    updated: 0,
  });
  const [isNewWalletModalOpen, setIsNewWalletModalOpen] = useState(false);
  const [activeMobileTab, setActiveMobileTab] = useState('portfolio-stats');
  const [notificationCards, setNotificationCards] = useState<NotificationCard[]>([]);
  const userProfile = useSelector(selectUserProfile);
  const strategy = userProfile?.investorProfile
    ? investorProfileToStrategy(userProfile?.investorProfile).toLowerCase()
    : null;

  useEffect(() => {
    setNotificationCards([]);
  }, []);

  useEffect(() => {
    if (!portfolio.data) {
      setTokenData([]);
      return;
    }

    setTokenData(portfolio.data.portfolio);
    setTotalPortfolioData({
      balance: portfolio.data.totalPortfolioValue,
      increase: portfolio.data.portfolioPercentualChange >= 0,
      change: portfolio.data.portfolioPercentualChange,
      updated: portfolio.data.updateTime,
    });
  }, [portfolio]);

  return (
    <section className="portfolio-main-section">
      <div className={`widget-wrap widget-portfolio-strategy-wrap ${strategy}`}>
        <WidgetProfileStrategy data={masterWalletAddress && tokenData.length ? totalPortfolioData : null} />
      </div>
      <div className="widget-wrap widget-profile-streak-wrap">
        <WidgetProfileStreak userScore={userScore} />
      </div>
      <div className="widgets-tabs-wrap">
        <div
          className={`item-tab ${activeMobileTab === 'portfolio-stats' ? 'active' : ''}`}
          onClick={() => setActiveMobileTab('portfolio-stats')}>
          Portfolio Stats
        </div>
        <div
          className={`item-tab ${activeMobileTab === 'asset-ratio' ? 'active' : ''}`}
          onClick={() => setActiveMobileTab('asset-ratio')}>
          Asset Ratio
        </div>
        <div
          className={`item-tab ${activeMobileTab === 'advice' ? 'active' : ''}`}
          onClick={() => setActiveMobileTab('advice')}>
          Advice
        </div>
      </div>
      <div
        className={`widget-wrap widget-portfolio-stats-wrap widgets-tabs-item ${activeMobileTab === 'portfolio-stats' ? 'widgets-tabs-item-active' : ''}`}>
        <WidgetPortfolioStats />
      </div>
      <div
        className={`widget-wrap widget-asset-ratio-wrap widgets-tabs-item ${activeMobileTab === 'asset-ratio' ? 'widgets-tabs-item-active' : ''}`}>
        <WidgetAssetRatio />
      </div>
      <div className={`widget-wrap widget-assets-table-wrap ${!masterWalletAddress ? 'no-data' : ''}`}>
        <WidgetAssetsTable data={masterWalletAddress ? tokenData : []} isLoading={portfolio.isLoading} />
      </div>
      {masterWalletAddress ? (
        <div
          className={`widgets-recommendation-wrap widgets-tabs-item ${activeMobileTab === 'advice' ? 'widgets-tabs-item-active' : ''}`}>
          {notificationCards?.map((item, ix) => (
            <div className="widget-wrap widget-recommendation-wrap" key={ix}>
              <WidgetNotificationCard {...item} />
            </div>
          ))}
          {notificationCards.length === 0 && (
            <div className="widget-wrap widget-recommendation-wrap">
              <WidgetNotificationCard isEmpty={true} />
            </div>
          )}
        </div>
      ) : null}
      <NewWalletModal open={isNewWalletModalOpen} onClose={() => setIsNewWalletModalOpen(false)} />
    </section>
  );
};

export default Portfolio;
