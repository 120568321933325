export const DEFAULT_SWR_CONFIG = {
  revalidateOnMount: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshInterval: 1000 * 60 * 5,
  refreshWhenHidden: false,
  refreshWhenOffline: false,
  dedupingInterval: 1000 * 60 * 5,
  keepPreviousData: true,
};
