import { toast } from 'react-hot-toast';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const toastError = (message: string) => {
  toast.custom(
    (t) => (
      <div
        style={{
          backgroundColor: 'rgba(17, 24, 39, 0.9)',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          pointerEvents: 'auto',
          display: 'flex',
          border: '2px solid #EF4444',
          transition: 'transform 0.3s ease-out',
          transform: t.visible ? 'translateY(0)' : 'translateY(-200%)',
          color: '#F3F4F6',
          marginRight: '50px',
        }}>
        <div style={{ flex: 1, padding: '16px', display: 'flex', alignItems: 'center' }}>
          <ErrorIcon style={{ color: '#F87171', marginRight: '12px' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '14px', fontWeight: '500' }}>{message}</p>
          </div>
        </div>
        <div style={{ display: 'flex', borderLeft: '1px solid rgba(248, 113, 113, 0.5)' }}>
          <button
            onClick={() => toast.dismiss(t.id)}
            style={{
              width: '100%',
              border: 'none',
              borderRadius: '0 8px 8px 0',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '500',
              color: '#F87171',
              backgroundColor: 'rgba(17, 24, 39, 0.7)',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgba(248, 113, 113, 0.2)')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgba(17, 24, 39, 0.7)')}>
            Close
          </button>
        </div>
      </div>
    ),
    {
      position: 'top-right',
    },
  );
};

export const toastSuccess = (message: string) => {
  toast.custom(
    (t) => (
      <div
        style={{
          maxWidth: '400px',
          width: '100%',
          backgroundColor: 'rgba(17, 39, 24, 0.9)',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          pointerEvents: 'auto',
          display: 'flex',
          border: '2px solid #10B981',
          transition: 'transform 0.3s ease-out',
          transform: t.visible ? 'translateY(0)' : 'translateY(-200%)',
          color: '#F3F4F6',
          marginRight: '50px',
        }}>
        <div style={{ flex: 1, padding: '16px', display: 'flex', alignItems: 'center' }}>
          <CheckCircleIcon style={{ color: '#10B981', marginRight: '12px' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '14px', fontWeight: '500' }}>{message}</p>
          </div>
        </div>
        <div style={{ display: 'flex', borderLeft: '1px solid rgba(16, 185, 129, 0.5)' }}>
          <button
            onClick={() => toast.dismiss(t.id)}
            style={{
              width: '100%',
              border: 'none',
              borderRadius: '0 8px 8px 0',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '500',
              color: '#10B981',
              backgroundColor: 'rgba(17, 39, 24, 0.7)',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgba(16, 185, 129, 0.2)')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgba(17, 39, 24, 0.7)')}>
            Close
          </button>
        </div>
      </div>
    ),
    {
      position: 'top-right',
    },
  );
};

export const toastWarning = (message: string) => {
  toast.custom(
    (t) => (
      <div
        style={{
          maxWidth: '400px',
          width: '100%',
          backgroundColor: 'rgba(39, 24, 17, 0.9)',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          pointerEvents: 'auto',
          display: 'flex',
          border: '2px solid #F59E0B',
          transition: 'transform 0.3s ease-out',
          transform: t.visible ? 'translateY(0)' : 'translateY(-200%)',
          color: '#F3F4F6',
        }}>
        <div style={{ flex: 1, padding: '16px', display: 'flex', alignItems: 'center' }}>
          <WarningIcon style={{ color: '#F59E0B', marginRight: '12px' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '14px', fontWeight: '500' }}>{message}</p>
          </div>
        </div>
        <div style={{ display: 'flex', borderLeft: '1px solid rgba(245, 158, 11, 0.5)' }}>
          <button
            onClick={() => toast.dismiss(t.id)}
            style={{
              width: '100%',
              border: 'none',
              borderRadius: '0 8px 8px 0',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '500',
              color: '#F59E0B',
              backgroundColor: 'rgba(39, 24, 17, 0.7)',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgba(245, 158, 11, 0.2)')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgba(39, 24, 17, 0.7)')}>
            Close
          </button>
        </div>
      </div>
    ),
    {
      position: 'top-right',
    },
  );
};

export const toastInfo = (message: string) => {
  toast.custom(
    (t) => (
      <div
        style={{
          maxWidth: '400px',
          width: '100%',
          backgroundColor: 'rgba(17, 24, 39, 0.9)',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          pointerEvents: 'auto',
          display: 'flex',
          border: '2px solid #3B82F6',
          transition: 'transform 0.3s ease-out',
          transform: t.visible ? 'translateY(0)' : 'translateY(-200%)',
          color: '#F3F4F6',
        }}>
        <div style={{ flex: 1, padding: '16px', display: 'flex', alignItems: 'center' }}>
          <InfoIcon style={{ color: '#3B82F6', marginRight: '12px' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '14px', fontWeight: '500' }}>{message}</p>
          </div>
        </div>
        <div style={{ display: 'flex', borderLeft: '1px solid rgba(59, 130, 246, 0.5)' }}>
          <button
            onClick={() => toast.dismiss(t.id)}
            style={{
              width: '100%',
              border: 'none',
              borderRadius: '0 8px 8px 0',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '500',
              color: '#3B82F6',
              backgroundColor: 'rgba(17, 24, 39, 0.7)',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgba(59, 130, 246, 0.2)')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgba(17, 24, 39, 0.7)')}>
            Close
          </button>
        </div>
      </div>
    ),
    {
      position: 'top-right',
    },
  );
};
