import ReactGA from 'react-ga4';
import { Modal, useMediaQuery, Select, MenuItem, SvgIcon, InputAdornment, TextField } from '@mui/material';
import Highcharts from 'highcharts/highstock';
import Annotations from 'highcharts/modules/annotations';
import { FC, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import closeIcon from '../../assets/img/modal-close-icon.svg';
import strategyIcon from '../../assets/img/strategy-options-icon.svg';
import { BacktestData, BacktestResult, runBacktest, TokenTimestamps } from '../../services/api/apes/backtest';
import { wrapTokenAddressIfNeeded } from '../../blockchain/utils';
import { toastError } from '../../utils/toastUtils';
import { useLocation } from 'react-router-dom';
import { BLOCKCHAIN_IDS } from '../../blockchain/constants';
import { Address } from 'viem';
import { BacktestTokenData } from '../../services/api/apes/interfaces';
import { getContractAddress } from '../../blockchain/contracts/addresses';
import WidgetBacktestAssetsList from '../WidgetBacktestAssetsList';
import { getTokenId, parseTokenId } from '../../utils/tokens';

import {
  deriskConfig,
  Feel,
  ISubmitButtonState,
  PeriodUnit,
  Profile,
  Strategy,
  SubmitButtonState,
  Trigger,
} from './data';
import BacktestChart from './BacktestChart';
import StrategySection from './StrategySection';
import { useBacktestTokenTimestamps } from '../../services/api/apes/useBacktestFetchers';
import NoThumbnail from '../../assets/img/nocoin.png';
import NoData from '../../assets/img/no-backtest-data.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/ic-info.svg';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { ReactComponent as IconCalendar } from '../../assets/img/icons/ic-calendar.svg';
import { formatNumber } from '../../utils/formatter';
import { add, sub } from 'date-fns';

Annotations(Highcharts);

const Strategies: FC = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Strategies' });
  const { pathname } = useLocation();
  const tokenAddress = pathname.split('/ai-strategies/')[1]?.split('/').pop() || '';
  const chain = parseInt(pathname.split('/ai-strategies/')[1]?.split('/')[0], 10);
  const wrappedTokenAddress = wrapTokenAddressIfNeeded(tokenAddress, BLOCKCHAIN_IDS.ETHEREUM) as Address;
  const tokenId = getTokenId(wrappedTokenAddress, chain);
  const [isStrategyModalOpen, setIsStrategyModalOpen] = useState(false);
  const isStrategyModal = useMediaQuery('(max-width: 1199.98px)');
  const [allTokens, setAllTokens] = useState<TokenTimestamps>({});
  const { tokenData: backtestTokenData } = useBacktestTokenTimestamps();
  const [tokenData, setTokenData] = useState<BacktestTokenData[]>([]);
  const [selectedToken, setSelectedToken] = useState<BacktestTokenData | null>(null);
  const [selectedStrategy, setSelectedStrategy] = useState<Strategy>(Strategy.Derisk);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [selectedProfile, setSelectedProfile] = useState<Profile>(Profile.Moderate);
  const [selectedPercentChangeTrigger, setSelectedPercentChangeTrigger] = useState<number>(5);
  const [selectedMinDerisk, setSelectedMinDerisk] = useState<number>(5);
  const [selectedTakeProfit, setSelectedTakeProfit] = useState<number>(10);
  const [selectedTrailedDerisk, setSelectedTrailedDerisk] = useState<number>(10);
  const [selectedFeel, setSelectedFeel] = useState<Feel>(Feel.Bullish);
  const [backtestStrategies, setBacktestStrategies] = useState<{ strategy: string; data: BacktestData }[]>([]);
  const [backtestResults, setBacktestResults] = useState<BacktestResult[][]>([]);
  const [submitButtonState, setSubmitButtonState] = useState<ISubmitButtonState>(SubmitButtonState.Ready);
  const [lastValues, setLastValues] = useState<number[]>([]);
  const [performance, setPerformance] = useState<string>('');
  const initialInvestment = 10000;
  const periodNumber = 1;
  const periodUnit = PeriodUnit.Day;
  const trigger = Trigger.Time;

  const strategyToValue = (strategy: string) => {
    switch (strategy) {
      case Strategy.RebalanceDerisk:
        return 'rebalance';
      case Strategy.Derisk:
        return 'derisk';
      default:
        return 'Unknown';
    }
  };

  const handleSubmit = async () => {
    const strategies = ['hodl', strategyToValue(selectedStrategy)];

    try {
      const startDate = selectedStartDate.toISOString().split('T')[0];
      const endDate = selectedEndDate.toISOString().split('T')[0];
      const percentChangeTrigger = selectedPercentChangeTrigger;
      const tokens = [
        {
          name: 'USDT',
          tokenId: getTokenId(getContractAddress('USDT', BLOCKCHAIN_IDS.ETHEREUM)!, BLOCKCHAIN_IDS.ETHEREUM),
          proportion: 0,
          deriskX: undefined,
          deriskSizePercentage: 0,
          volatilityTolerancePercentage: undefined,
          feel: selectedFeel,
          stablecoin: true,
        },
        {
          name: tokenData.find((token) => token.id.toLowerCase() === tokenId.toLowerCase())?.name || '',
          tokenId,
          proportion: 1200,
          deriskX: selectedMinDerisk,
          deriskSizePercentage: selectedTakeProfit,
          volatilityTolerancePercentage: selectedTrailedDerisk,
          feel: selectedFeel,
          stablecoin: false,
        },
      ];
      const baseData = {
        name: 'backtest',
        initialInvestment,
        tokens,
        startDate,
        endDate,
        periodNumber,
        periodUnit,
      };

      const backtestData = strategies.map((strategy) => ({
        ...baseData,
        strategy,
        trigger,
        percentChangeTrigger,
      }));
      setBacktestStrategies(backtestData.map((data) => ({ strategy: data.strategy, data })));
      const promises = backtestData.map((data) => runBacktest(data));
      const results = await Promise.all(promises);

      if (results.length === 0) {
        toastError('There is no data for those dates. Try a different date range.');
        setBacktestResults([]);
        return;
      }

      const filteredResults = results.map((result) => result.data).filter((result) => result) as BacktestResult[][];
      setBacktestResults(filteredResults);
    } catch {
      toastError('There is no data for those dates. Try a different date range.');
    }
  };

  const handleShowCard = async () => {};

  useEffect(() => {
    if (!backtestTokenData) {
      return;
    }

    setAllTokens(backtestTokenData);
  }, [backtestTokenData]);

  useEffect(() => {
    // Convert token timestamps to token data
    setTokenData(
      Object.entries(allTokens).map(([tokenId, tokenData]) => {
        const { chainId, address } = parseTokenId(tokenId);

        return {
          id: tokenId,
          chain: chainId,
          name: tokenData.name,
          symbol: tokenData.symbol,
          logo: tokenData.thumbnail || NoThumbnail,
          tokenAddress: address,
          minTimestamp: tokenData.minTimestamp,
          maxTimestamp: tokenData.maxTimestamp,
        } as BacktestTokenData;
      }),
    );
  }, [allTokens]);

  useEffect(() => {
    const config = deriskConfig[selectedProfile]?.[selectedFeel];

    if (config) {
      setSelectedMinDerisk(config.minDerisk);
      setSelectedTrailedDerisk(config.trailedDerisk);
      setSelectedTakeProfit(config.takeProfit);
      setSelectedPercentChangeTrigger(config.percentChangeTrigger);
    }
  }, [selectedProfile, selectedFeel]);

  useEffect(() => {
    const lastVals = backtestResults.map((result) => result[result.length - 1].valueInUsd);
    const perf = 100 * (lastVals[1] / lastVals[0] - 1);
    const sign = perf >= 0 ? '+' : '';
    setLastValues(lastVals);
    setPerformance(!isNaN(perf) ? `${sign}${perf.toFixed(2)}%` : '...');
  }, [backtestResults]);

  useEffect(() => {
    if (!tokenAddress) {
      setSubmitButtonState(SubmitButtonState.NoToken);
      return;
    }

    setSubmitButtonState(SubmitButtonState.Ready);
  }, [tokenAddress]);

  useEffect(() => {
    setSelectedToken(tokenData.find((token) => token.id === tokenId) || null);
  }, [tokenId, tokenData]);

  useEffect(() => {
    if (!selectedToken) {
      return;
    }

    setSelectedStartDate(add(new Date(selectedToken.minTimestamp), { days: 1 }));
    setSelectedEndDate(sub(new Date(selectedToken.maxTimestamp), { days: 1 }));
  }, [selectedToken]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <section className="info-message-section">
        <div className="label-icon">
          <SvgIcon component={InfoIcon} />
        </div>
        <div className="label-text">Sandbox Mode</div>
      </section>
      <section className="portfolio-asset-section strategies-section">
        <div className="widgets-column widgets-column-1-wrap">
          <div
            className="widget-wrap strategy-button-wrap"
            onClick={() => (isStrategyModal ? setIsStrategyModalOpen(true) : null)}>
            <div className="icon">
              <img src={strategyIcon} alt="Icon" />
            </div>
            <div className="text">Strategy</div>
          </div>
          <div className="widget-wrap widget-assets-list-wrap">
            <WidgetBacktestAssetsList data={tokenData} name='Library' />
          </div>
        </div>
        <div className="widgets-column widgets-column-2-wrap">
          <div className="widget-wrap widget-backtest-data-wrap">
            {!tokenAddress && (
              <div className="no-data-wrapper">
                <img src={NoData} alt="No Data" />
                <span className="main-text">-</span>
                <span className="sub-text">Choose an asset on the left side</span>
              </div>
            )}
            {tokenAddress && (
              <>
                <div className="chart-settings-wrap">
                  <div className="token-info-wrap">
                    <img
                        className="token-logo"
                        src={tokenData.find((token) => token.id === tokenId)?.logo || NoThumbnail}
                        alt="Logo"
                    />
                    <div className="token-symbol">{tokenData.find((token) => token.id === tokenId)?.symbol || 'CT'}</div>
                    <div className="token-name">
                      {tokenData.find((token) => token.id === tokenId)?.name || 'Crypto Token'}
                    </div>
                  </div>
                  <div className="parameters-wrap">
                    <div className="item-wrap">
                      <div className="label-wrap">Profile</div>
                      <Select value={selectedProfile} onChange={(e) => setSelectedProfile(e.target.value as Profile)}>
                        {Object.values(Profile).map((profile) => (
                            <MenuItem key={profile} value={profile}>
                              {profile}
                            </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="item-wrap">
                      <div className="label-wrap">Start Date</div>
                      <MobileDatePicker
                          value={selectedStartDate}
                          onChange={(newValue) => setSelectedStartDate(newValue || new Date())}
                          slots={{
                            textField: TextField,
                          }}
                          slotProps={{
                            textField: {
                              InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <IconCalendar style={{ color: '#FFFFFF80' }} />
                                    </InputAdornment>
                                ),
                              },
                            },
                          }}
                          minDate={new Date(selectedToken?.minTimestamp || 0)}
                          maxDate={
                            new Date(
                                selectedToken?.maxTimestamp ? selectedToken?.maxTimestamp - 24 * 60 * 60 * 1000 : new Date(),
                            )
                          }
                      />
                    </div>
                    <div className="item-wrap">
                      <div className="label-wrap">End Date</div>
                      <MobileDatePicker
                          value={selectedEndDate}
                          onChange={(newDate) => setSelectedEndDate(newDate || new Date())}
                          slots={{
                            textField: TextField,
                          }}
                          slotProps={{
                            textField: {
                              InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <IconCalendar style={{ color: '#FFFFFF80' }} />
                                    </InputAdornment>
                                ),
                              },
                            },
                          }}
                          minDate={selectedStartDate}
                          maxDate={new Date(selectedToken?.maxTimestamp || new Date())}
                      />
                    </div>
                  </div>
                  <div className="results-wrap">
                    <div className="element">
                      <div className="title">Performance</div>
                      <div className="value">{`${performance}`}</div>
                    </div>
                    <div className="element">
                      <div className="title">Derisk Strategy</div>
                      <div className="value">{`${lastValues.length > 1 ? `$${formatNumber(lastValues[1]).value}` : '...'}`}</div>
                    </div>
                    <div className="element">
                      <div className="title">Hodl Strategy</div>
                      <div className="value gray">{`${lastValues.length > 0 ? `$${formatNumber(lastValues[0]).value}` : '...'}`}</div>
                    </div>
                  </div>
                </div>
                <BacktestChart
                  backtestResults={backtestResults}
                  strategies={backtestStrategies}
                  allTokens={allTokens}
                />
                <div className="chart-info-wrap">
                  <div className="item-wrap">
                    <div className="label">Feeling:</div>
                    <div className="value">Bullish</div>
                  </div>
                  <div className="item-wrap">
                    <div className="label">Min. Derisk:</div>
                    <div className="value">3x</div>
                  </div>
                  <div className="item-wrap">
                    <div className="label">Take Profit:</div>
                    <div className="value">50%</div>
                  </div>
                  <div className="item-wrap">
                    <div className="label">Trailed Derisk:</div>
                    <div className="value">30%</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {isStrategyModal ? (
          <Modal
            open={isStrategyModalOpen}
            onClose={() => setIsStrategyModalOpen(false)}
            className="modal-wrap modal-strategy-wrap">
            <div className="modal-content-wrap">
              <div className="close-wrap" onClick={() => setIsStrategyModalOpen(false)}>
                <img src={closeIcon} alt="Close" />
              </div>
              <StrategySection
                selectedStrategy={selectedStrategy}
                setSelectedStrategy={setSelectedStrategy}
                selectedPercentChangeTrigger={selectedPercentChangeTrigger}
                setSelectedPercentChangeTrigger={setSelectedPercentChangeTrigger}
                selectedMinDerisk={selectedMinDerisk}
                setSelectedMinDerisk={setSelectedMinDerisk}
                selectedTakeProfit={selectedTakeProfit}
                setSelectedTakeProfit={setSelectedTakeProfit}
                selectedTrailedDerisk={selectedTrailedDerisk}
                setSelectedTrailedDerisk={setSelectedTrailedDerisk}
                selectedFeel={selectedFeel}
                setSelectedFeel={setSelectedFeel}
                handleSubmit={handleSubmit}
                handleShowCard={handleShowCard}
                submitButtonState={submitButtonState}
                enabled={tokenAddress !== ''}
              />
            </div>
          </Modal>
        ) : (
          <StrategySection
            selectedStrategy={selectedStrategy}
            setSelectedStrategy={setSelectedStrategy}
            selectedPercentChangeTrigger={selectedPercentChangeTrigger}
            setSelectedPercentChangeTrigger={setSelectedPercentChangeTrigger}
            selectedMinDerisk={selectedMinDerisk}
            setSelectedMinDerisk={setSelectedMinDerisk}
            selectedTakeProfit={selectedTakeProfit}
            setSelectedTakeProfit={setSelectedTakeProfit}
            selectedTrailedDerisk={selectedTrailedDerisk}
            setSelectedTrailedDerisk={setSelectedTrailedDerisk}
            selectedFeel={selectedFeel}
            setSelectedFeel={setSelectedFeel}
            handleSubmit={handleSubmit}
            handleShowCard={handleShowCard}
            submitButtonState={submitButtonState}
            enabled={tokenAddress !== ''}
          />
        )}
      </section>
    </LocalizationProvider>
  );
};

export default Strategies;
