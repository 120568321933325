import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SIWESession } from '@web3modal/siwe';
import { UserSettings, UserDetails, DeriskOrder, Portfolio, UserScore } from '../../services/api/apes/interfaces';

interface PortfolioState {
  data: Portfolio | null;
  isLoading: boolean;
  isError: boolean;
}

interface UserState {
  walletAddress: string | null;
  balance: string | null;
  session: SIWESession | null;
  isAuthenticated: boolean;
  isBalanceVisible: boolean;
  loading: boolean;
  error: string | null;
  profile: UserDetails | null;
  score: UserScore | null;
  selectedWalletAddress: string | null;
  settings: UserSettings | null;
  deriskOrders: DeriskOrder[] | null;
  portfolio: PortfolioState;
}

const initialState: UserState = {
  walletAddress: null,
  balance: null,
  session: null,
  isAuthenticated: false,
  isBalanceVisible: JSON.parse(localStorage.getItem('isBalanceVisible') || 'true'),
  loading: false,
  error: null,
  profile: null,
  selectedWalletAddress: null,
  settings: null,
  deriskOrders: null,
  portfolio: {
    data: null,
    isLoading: false,
    isError: false,
  },
  score: null,
};

interface UserPayload {
  walletAddress: string | null;
  session: SIWESession;
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserPayload>) => {
      state.walletAddress = action.payload.walletAddress;
      state.session = action.payload.session;
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
    },
    logout: (state) => {
      state.walletAddress = null;
      state.session = null;
      state.isAuthenticated = false;
      state.error = null;
      state.loading = false;
    },
    setBalance: (state, action: PayloadAction<string>) => {
      state.balance = action.payload;
    },
    setBalanceVisibility: (state, action: PayloadAction<boolean>) => {
      state.isBalanceVisible = action.payload;
    },
    setProfile: (state, action: PayloadAction<UserDetails>) => {
      state.profile = action.payload;
    },
    setUserScore: (state, action: PayloadAction<UserScore>) => {
      state.score = action.payload;
    },
    setSelectedWalletAddress: (state, action: PayloadAction<string>) => {
      state.selectedWalletAddress = action.payload;
    },
    setUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.settings = action.payload;
    },
    setDeriskOrders: (state, action: PayloadAction<DeriskOrder[]>) => {
      state.deriskOrders = action.payload;
    },
    setPortfolio: (state, action: PayloadAction<PortfolioState>) => {
      state.portfolio.data = action.payload.data;
      state.portfolio.isLoading = action.payload.isLoading;
      state.portfolio.isError = action.payload.isError;
    },
  },
});

export const {
  login,
  logout,
  setBalance,
  setProfile,
  setUserScore,
  setBalanceVisibility,
  setSelectedWalletAddress,
  setUserSettings,
  setDeriskOrders,
  setPortfolio,
} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export const selectUserProfile = (state: RootState) => state.user.profile;
export const selectUserMasterWalletAddress = (state: RootState) => state.user.walletAddress;
export const selectBalanceVisibility = (state: RootState) => state.user.isBalanceVisible;
export const selectSelectedWalletAddress = (state: RootState) => state.user.selectedWalletAddress;
export const selectUserSettings = (state: RootState) => state.user.settings;
export const selectDeriskOrders = (state: RootState) => state.user.deriskOrders;
export const selectPortfolio = (state: RootState) => state.user.portfolio;
export const selectUserScore = (state: RootState) => state.user.score;

export default userSlice.reducer;
