export default [
  {
    constant: false,
    inputs: [
      {
        name: '_feeCollector',
        type: 'address',
      },
    ],
    name: '',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    constant: false,
    inputs: [
      {
        name: 'account',
        type: 'address',
      },
    ],
    name: 'AddressInsufficientBalance',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [],
    name: 'ECDSAInvalidSignature',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [
      {
        name: 'length',
        type: 'uint256',
      },
    ],
    name: 'ECDSAInvalidSignatureLength',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [
      {
        name: 's',
        type: 'bytes32',
      },
    ],
    name: 'ECDSAInvalidSignatureS',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [],
    name: 'FailedInnerCall',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [],
    name: 'INVALID_SIGNATURE',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [],
    name: 'INVALID_TX',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [],
    name: 'NOT_AUTHORIZED',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [],
    name: 'ORDER_ALREADY_EXECUTED',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [],
    name: 'ORDER_EXPIRED',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'error',
  },
  {
    constant: false,
    inputs: [
      {
        name: 'wallet',
        type: 'address',
      },
      {
        name: 'token',
        type: 'address',
      },
      {
        name: 'nonce',
        type: 'uint256',
      },
    ],
    name: 'Derisked',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'event',
  },
  {
    constant: true,
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [
      {
        name: '',
        type: 'bytes32',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'PERMIT_TYPEHASH',
    outputs: [
      {
        name: '',
        type: 'bytes32',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: 'userWallet',
        type: 'address',
      },
      {
        name: 'token',
        type: 'address',
      },
      {
        name: 'nonce',
        type: 'uint256',
      },
      {
        name: 'signatureDeadline',
        type: 'uint256',
      },
      {
        name: 'amountIn',
        type: 'uint256',
      },
      {
        name: 'minAmountOut',
        type: 'uint256',
      },
      {
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: 'derisk',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'feeCollector',
    outputs: [
      {
        name: '',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    name: 'feesPerTier',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: 'userWallet',
        type: 'address',
      },
    ],
    name: 'getFeesBps',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'address',
      },
    ],
    name: 'isExecutor',
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'address',
      },
      {
        name: '',
        type: 'address',
      },
    ],
    name: 'lastExecutedNonce',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [
      {
        name: '',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: 'executor',
        type: 'address',
      },
      {
        name: 'value',
        type: 'bool',
      },
    ],
    name: 'setExecutor',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_feeCollector',
        type: 'address',
      },
    ],
    name: 'setFeeCollector',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_fee1',
        type: 'uint256',
      },
      {
        name: '_fee2',
        type: 'uint256',
      },
      {
        name: '_fee3',
        type: 'uint256',
      },
      {
        name: '_fee4',
        type: 'uint256',
      },
    ],
    name: 'setFees',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'setOwner',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_stakingContract',
        type: 'address',
      },
    ],
    name: 'setStakingContract',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'stakingContract',
    outputs: [
      {
        name: '',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    name: 'tiers',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    payable: true,
    stateMutability: 'payable',
    type: 'fallback',
  },
] as const;
