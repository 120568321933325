import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
} from 'material-react-table';
import { formatNumber } from '../../utils/formatter';
import { isMobile } from '../../utils/isMobile';
import { TokenData } from '../../services/api/apes/interfaces';
import { TokenLabel } from './TokenLabel';
import { ChangeLabelDetailed } from './ChangeLabelDetailed';
import logoColored from '../../assets/img/derisk-auto.svg';
import logoUncolored from '../../assets/img/derisk-manual.svg';
import logoOutlined from '../../assets/img/derisk-no.svg';
import { Tooltip } from '@mui/material';
import { ChangeLabelSimple } from './ChangeLabelSimple';
import { useSelector } from 'react-redux';
import {selectBalanceVisibility, selectDeriskOrders} from '../../store/slices/userSlice';
import { emptyData, EmptyTokenData } from './emptyData';

interface Props {
  data: TokenData[];
  isLoading: boolean;
}

const WidgetAssetsTable: FC<Props> = ({ data, isLoading }) => {
  const navigate = useNavigate();
  const deriskOrders = useSelector(selectDeriskOrders);
  const isBalanceVisible = useSelector(selectBalanceVisibility);

  const handleAddWallet = () => {
    navigate('/settings');
  };

  const columns = useMemo<MRT_ColumnDef<TokenData>[]>(() => {
    const desktopColumns: MRT_ColumnDef<TokenData>[] = [
      {
        header: 'Order',
        size: 30,
        id: 'zerocol',
        accessorFn: (originalRow) => {
          if (deriskOrders?.map((x) => x.tokenId.toLowerCase()).includes(originalRow.token_id.toLowerCase())) {
            const orders = deriskOrders.filter((x) => x.tokenId.toLowerCase() === originalRow.token_id.toLowerCase());
            const isAnyActiveAndAutomatic = orders.some((x) => x.executed === false && x.automatic === true);

            if (isAnyActiveAndAutomatic) {
              return (
                <div className="logo-wrap">
                  <img src={logoColored} alt="Logo" />
                </div>
              );
            } else {
              return (
                <div className="logo-wrap">
                  <img src={logoUncolored} alt="Logo" />
                </div>
              );
            }
          } else {
            return (
              <div className="logo-wrap">
                <img src={logoOutlined} alt="Logo" />
              </div>
            );
          }
        },
      },
      {
        accessorKey: 'symbol',
        header: 'Asset',
        size: 180,
        Cell: ({ row }) => {
          const { name, symbol, thumbnail, totalPortfolioPercentage } = row.original;
          return <TokenLabel name={name} symbol={symbol} imageUrl={thumbnail} percentage={totalPortfolioPercentage} />;
        },
      },
      {
        header: 'Price',
        accessorFn: (originalRow) => {
          const { value, sub, rest } = formatNumber(originalRow.usd_price);

          return (
            <div className="basic-text-wrap assets-table-price-wrap">
              <Tooltip title={`$${originalRow.usd_price}`} placement="top-start">
                <span>
                  ${value}
                  {sub ? <sub>{sub}</sub> : ''}
                  {rest ? rest : ''}
                </span>
              </Tooltip>
              <ChangeLabelSimple
                value={originalRow.usd_price_24hr_percent_change.toFixed(2) + '%'}
                increase={originalRow.usd_price_24hr_percent_change > 0}
              />
            </div>
          );
        },
        size: 145,
      },
      {
        header: 'Amount',
        accessorFn: (originalRow) => {
          const { value, sub, rest } = formatNumber(originalRow.balance_formatted);

          return (
            <div className="basic-text-wrap">
              {!originalRow?.isBalanceHidden ? (
                  <Tooltip title={originalRow.balance_formatted} placement="top-start">
                    <span>
                      {value}
                      {sub ? <sub>{sub}</sub> : ''}
                      {rest ? rest : ''}
                    </span>
                  </Tooltip>
              ) : (
                  <span>*****</span>
              )}
            </div>
          );
        },
        size: 145,
      },
      {
        header: 'Value',
        accessorFn: (originalRow) => {
          const { value, sub, rest } = formatNumber(originalRow.usd_value);

          return (
            <div className="basic-text-wrap assets-table-price-wrap">
              {!originalRow?.isBalanceHidden ? (
                <Tooltip title={`$${originalRow.usd_value}`} placement="top-start">
                  <span>
                    ${value}
                    {sub ? <sub>{sub}</sub> : ''}
                    {rest ? rest : ''}
                  </span>
                </Tooltip>
              ) : (
                <span>$*****</span>
              )}
            </div>
          );
        },
        size: 120,
      },
      {
        header: 'Performance',
        accessorFn: (originalRow) => {
          const { usd_price, entry_price } = originalRow;

          const formattedValue = entry_price ? Number(formatNumber(usd_price / entry_price).value) : null;

          return (
            <div className="basic-text-wrap assets-table-price-wrap">
              {formattedValue ? (
                formattedValue >= 1 ? (
                  <span className="green">{formattedValue.toFixed(2)}x</span>
                ) : (
                  <span className="red">{formattedValue.toFixed(2)}x</span>
                )
              ) : (
                <span>-</span>
              )}
            </div>
          );
        },
        size: 120,
      },
    ];
    const mobileColumns: MRT_ColumnDef<TokenData>[] = [
      {
        header: '',
        size: 10,
        id: 'zerocol',
        accessorFn: (originalRow) => {
          if (deriskOrders?.map((x) => x.tokenId.toLowerCase()).includes(originalRow.token_id.toLowerCase())) {
            const orders = deriskOrders.filter((x) => x.tokenId.toLowerCase() === originalRow.token_id.toLowerCase());
            const isAnyActiveAndAutomatic = orders.some((x) => x.executed === false && x.automatic === true);

            if (isAnyActiveAndAutomatic) {
              return (
                <div className="logo-wrap">
                  <img src={logoColored} alt="Logo" />
                </div>
              );
            } else {
              return (
                <div className="logo-wrap">
                  <img src={logoUncolored} alt="Logo" />
                </div>
              );
            }
          } else {
            return (
              <div className="logo-wrap">
                <img src={logoOutlined} alt="Logo" />
              </div>
            );
          }
        },
      },
      {
        accessorKey: 'symbol',
        header: 'Asset',
        size: 100,
        Cell: ({ row }) => (
          <TokenLabel name={row.original.name} symbol={row.original.symbol} imageUrl={row.original.thumbnail} />
        ),
      },
      {
        header: 'Price',
        accessorKey: 'usd_price',
        Cell: ({ row }) => {
          const valueTop = () => {
            const { value, sub, rest } = formatNumber(row.original.usd_price);
            return (
              <span>
                ${value}
                {sub ? <sub>{sub}</sub> : ''}
                {rest ? rest : ''}
              </span>
            );
          };

          const valueBottom = () => {
            const { value, sub, rest } = formatNumber(row.original.usd_price_24hr_percent_change);
            return (
              <span>
                (${value}
                {sub ? <sub>{sub}</sub> : ''}
                {rest ? rest : ''})
              </span>
            );
          };

          const valueBottomPercentage = () => {
            const { value, sub, rest } = formatNumber(row.original.usd_price_24hr_percent_change);
            return (
              <span>
                {value}
                {sub ? <sub>{sub}</sub> : ''}
                {rest ? rest : ''}%
              </span>
            );
          };

          return (
            <ChangeLabelDetailed
              valueTop={valueTop()}
              valueBottom={valueBottom()}
              valueBottomPercentage={valueBottomPercentage()}
              increase={row.original.usd_price_24hr_percent_change > 0}
            />
          );
        },
      },
    ];
    return isMobile() ? mobileColumns : desktopColumns;
  }, [deriskOrders]);

  const emptyColumns = useMemo<MRT_ColumnDef<EmptyTokenData>[]>(() => {
    const desktopColumns: MRT_ColumnDef<EmptyTokenData>[] = [
      {
        header: 'Order',
        size: 30,
        id: 'zerocol',
        accessorFn: (originalRow) => {
          return (
            <div className="logo-wrap">
              <img src={originalRow.order} alt="Logo" />
            </div>
          );
        },
      },
      {
        accessorKey: 'symbol',
        header: 'Asset',
        size: 180,
        Cell: ({ row }) => {
          return <img src={row.original.thumbnail} />;
        },
      },
      {
        header: 'Price',
        accessorFn: (originalRow) => {
          return <span className="no-cell-data">{originalRow.usd_price}</span>;
        },
        size: 145,
      },
      {
        header: 'Amount',
        accessorFn: (originalRow) => {
          return <span className="no-cell-data">{originalRow.balance_formatted}</span>;
        },
        size: 145,
      },
      {
        header: 'Value',
        accessorFn: (originalRow) => {
          return <span className="no-cell-data">{originalRow.usd_value}</span>;
        },
        size: 120,
      },
      {
        header: 'Performance',
        accessorFn: (originalRow) => {
          return <span className="no-cell-data">{originalRow.entry_price}</span>;
        },
        size: 120,
      },
    ];
    const mobileColumns: MRT_ColumnDef<EmptyTokenData>[] = [
      {
        header: '',
        size: 10,
        id: 'zerocol',
        accessorFn: (originalRow) => {
          return (
            <div className="logo-wrap">
              <img src={originalRow.order} alt="Logo" />
            </div>
          );
        },
      },
      {
        accessorKey: 'symbol',
        header: 'Asset',
        size: 100,
        Cell: ({ row }) => {
          return <img src={row.original.thumbnail} />;
        },
      },
      {
        header: 'Price',
        accessorKey: 'usd_price',
        Cell: ({ row }) => {
          return <span className="no-cell-data">{row.original.usd_price}</span>;
        },
      },
    ];
    return isMobile() ? mobileColumns : desktopColumns;
  }, []);

  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: isMobile() ? 5 : 10,
  });

  const enablePagination = isMobile();
  const handleLoadMoreClick = () => {
    setPagination({
      ...pagination,
      pageSize: pagination.pageSize + 4,
    });
  };

  const table = useMaterialReactTable({
    columns,
    data: isBalanceVisible ? data : data.map((item: TokenData) => {
      return {
        ...item,
        isBalanceHidden: true,
      }
    }),
    defaultDisplayColumn: { enableResizing: true },
    enableBottomToolbar: false,
    enableColumnVirtualization: true,
    enableGlobalFilterModes: false,
    enablePagination: false,
    enableColumnPinning: false,
    enableTopToolbar: false,
    enableColumnResizing: false,
    enableRowVirtualization: false,
    enableColumnActions: false,
    enableRowActions: false,
    enableCellActions: false,
    onSortingChange: setSorting,
    state: { isLoading, sorting, pagination },
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 100 },
    columnVirtualizerOptions: { overscan: 1000 },
    enableExpandAll: false,
    renderDetailPanel: ({ row }) =>
      isMobile() ? (
        <div className="asset-amount-mobile-wrap">
          <div className="amount-label">Amount:</div>
          <div className="amount-value">{!row.original?.isBalanceHidden ? formatNumber(row.original.balance_formatted).value : '*****'}</div>
        </div>
      ) : null,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const { name, symbol, thumbnail, token_address: tokenAddress, chain } = row.original;
        const selectedAssetData = { name, symbol, thumbnail };
        navigate(`/asset/${chain}/${tokenAddress}`, { state: { row: row.original } });
        localStorage.setItem('selectedAssetData', JSON.stringify(selectedAssetData));
      },
    }),
    positionExpandColumn: 'last',
  });

  const emptyTable = useMaterialReactTable({
    columns: emptyColumns,
    data: emptyData,
    defaultDisplayColumn: { enableResizing: true },
    enableBottomToolbar: false,
    enableColumnVirtualization: true,
    enableGlobalFilterModes: false,
    enablePagination: enablePagination,
    enableColumnPinning: true,
    enableTopToolbar: false,
    enableColumnResizing: false,
    enableRowVirtualization: false,
    enableColumnActions: false,
    state: { isLoading, sorting, pagination },
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 100 },
    columnVirtualizerOptions: { overscan: 1000 },
    enableExpandAll: false,
  });

  return (
    <>
      <div className="name-wrap">My Assets</div>
      <MaterialReactTable table={table} />
      {isMobile() && (
        <button onClick={handleLoadMoreClick} className="btn-load-more">
          Load 4 more
        </button>
      )}
    </>
  );
};

export default WidgetAssetsTable;
