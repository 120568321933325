import { useDispatch, useSelector } from 'react-redux';
import { selectUserMasterWalletAddress, setPortfolio } from '../../store/slices/userSlice';
import { useEffect } from 'react';
import { useUserPortfolioAssets } from '../../services/api/apes/useUserFetchers';

const DataLayer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const masterWalletAddress = useSelector(selectUserMasterWalletAddress);
  const {
    data: portfolioData,
    isLoading: isPortfolioLoading,
    isError: isPortfolioError,
  } = useUserPortfolioAssets(masterWalletAddress);

  useEffect(() => {
    dispatch(
      setPortfolio({
        data: portfolioData?.data || null,
        isLoading: isPortfolioLoading,
        isError: isPortfolioError,
      }),
    );
  }, [dispatch, isPortfolioError, isPortfolioLoading, portfolioData]);

  return <>{children}</>;
};

export default DataLayer;
