import { env } from './env';
import { defaultWagmiConfig } from '@web3modal/wagmi';
import { mainnet } from 'wagmi/chains';

export const wcProjectId = env.WALLETCONNECT_PROJECT_ID;

export const wcMetadata = {
  name: 'Apescreener DApp',
  description:
    'Apescreener is an AI Portfolio Advisor and tracker, which helps you build, grow, and liquidate your portfolio.',
  url: 'https://apescreener.xyz',
  icons: ['https://beta.apescreener.xyz/logo192.png'],
};

export const wagmiConfig = defaultWagmiConfig({
  chains: [mainnet] as const,
  projectId: wcProjectId,
  metadata: wcMetadata,
  auth: {
    email: false,
    socials: [],
    showWallets: true,
    walletFeatures: true,
  },
});
