import {FC} from 'react';
import {PanelResizeHandle} from 'react-resizable-panels';

const ResizeHandle: FC = () => (
    <PanelResizeHandle className="resize-handle-wrap">
        <div className="resize-handle-inner">
            <div className="line-wrap"></div>
            <div className="icon-wrap">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="24" y="23.5" width="23" height="23" rx="11.5" transform="rotate(180 24 23.5)" stroke="white" stroke-opacity="0.15"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2071 13.7071C15.8166 14.0976 15.1834 14.0976 14.7929 13.7071L12.5 11.4142L10.2071 13.7071C9.81658 14.0976 9.18342 14.0976 8.79289 13.7071C8.40237 13.3166 8.40237 12.6834 8.79289 12.2929L11.7929 9.29289C12.1834 8.90237 12.8166 8.90237 13.2071 9.29289L16.2071 12.2929C16.5976 12.6834 16.5976 13.3166 16.2071 13.7071Z" fill="white"/>
                </svg>
            </div>
        </div>
    </PanelResizeHandle>
)

export default ResizeHandle;
