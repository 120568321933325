import axios from 'axios';
import { ENDPOINTS } from './endpoints';
import { ApiResponse } from './interfaces';

export interface TokenTimestampData {
  name: string;
  symbol: string;
  thumbnail: string;
  minTimestamp: number;
  maxTimestamp: number;
}

export interface TokenTimestamps {
  [key: string]: TokenTimestampData;
}

export interface IStrategy {
  name: string;
  strategy: string;
  data: BacktestData;
}

export interface BacktestData {
  name: string;
  initialInvestment: number;
  tokens: Token[];
  startDate: string;
  endDate: string;
  periodNumber: number;
  periodUnit: string;
  strategy: string;
  trigger?: string;
  percentChangeTrigger: number;
}

export interface BacktestResult {
  date: string;
  tokens: Token[];
  valueInUsd: number;
  iteration: number;
  rebalanced?: boolean;
  derisked?: boolean;
}

export interface Token {
  tokenId: string;
  name?: string;
  proportion: number;
  stablecoin?: boolean;
  deriskX?: number;
  deriskSizePercentage?: number;
  volatilityTolerancePercentage?: number;
  feel: string;
  volume?: number;
  balance?: number;
  price?: number;
  initialPrice?: number;
  maxPrice?: number;
  valueInUsd?: number;
  deriskPrice?: number;
  priceChange?: number;
  derisked?: boolean;
  deriskedAtX?: number;
  correlationPriceVolume?: number;
}

export interface WalletToken {
  avg_buy_price_usd: string;
  avg_cost_of_quantity_sold: string;
  avg_sell_price_usd: string;
  count_of_trades: number;
  decimals: string;
  logo: string;
  name: string;
  possible_spam: boolean;
  realized_profit_percentage: number;
  realized_profit_usd: string;
  symbol: string;
  token_address: string;
  total_buys: number;
  total_sells: number;
  total_sold_usd: string;
  total_tokens_bought: string;
  total_tokens_sold: string;
  total_usd_invested: string;
  first_trade_hash?: string;
  first_trade_timestamp?: string;
}

export const fetchTokenTimestamps = async () => {
  const response = await axios.get<
    Record<string, { name: string; symbol: string; thumbnail: string; minTimestamp: number; maxTimestamp: number }>
  >(ENDPOINTS.BACKTEST_TOKEN_TIMESTAMPS, {
    withCredentials: true,
  });
  const normalizedResponse: {
    [key: string]: {
      name: string;
      symbol: string;
      thumbnail: string;
      minTimestamp: number;
      maxTimestamp: number;
    };
  } = {};
  const data = response.data;
  Object.keys(data).forEach((key) => {
    normalizedResponse[key.toLowerCase()] = data[key];
  });
  return normalizedResponse;
};

export const fetchPremadeSimulations = async () => {
  const response = await axios.get<ApiResponse<IStrategy[]>>(ENDPOINTS.BACKTEST_PREMADE_SIMULATIONS, {
    withCredentials: true,
  });
  return response.data;
};

export const addBacktestToken = async (tokenAddress: string): Promise<{ success: boolean; error?: string }> => {
  const response = await axios.post(ENDPOINTS.BACKTEST_ADD_TOKEN, { tokenAddress }, { withCredentials: true });
  return response.data;
};

export const fetchWalletTokens = async (walletAddress: string) => {
  const response = await axios.get<WalletToken[]>(ENDPOINTS.WALLET_TRADES, {
    params: { walletAddress },
    withCredentials: true,
  });
  return response;
};

export const runBacktest = async (data: BacktestData) => {
  const response = await axios.post<ApiResponse<BacktestResult[]>>(ENDPOINTS.BACKTEST_RUN, data, {
    withCredentials: true,
  });
  return response.data;
};
