import useSWR from 'swr';
import { fetchTokenData } from './tokenFetchers';
import { DEFAULT_SWR_CONFIG } from './config';

// Fetch token data
export const useTokenInfo = (tokenAddress: string, chain: number) => {
  const shouldFetch = Boolean(tokenAddress) && Boolean(chain);

  const { data, error, isLoading } = useSWR(
    shouldFetch ? `tokenInfo-${tokenAddress}-${chain}` : null,
    () => fetchTokenData(tokenAddress, chain),
    DEFAULT_SWR_CONFIG,
  );

  return {
    tokenInfo: data,
    isLoading: isLoading,
    isError: !!error,
  };
};
