import * as z from 'zod';

const createEnv = () => {
  const EnvSchema = z.object({
    WALLETCONNECT_PROJECT_ID: z.string(),
    RPC_URLS_MAINNET: z.string(),
    APES_BACKEND_URL: z.string(),
    ALCHEMY_API_KEY: z.optional(z.string()),
    INFURA_PROJECT_ID: z.optional(z.string()),
    ETHERSCAN_API_KEY: z.optional(z.string()),
    DERISK_ROUTER_ADDRESS: z.string(),
  });

  const envVars = Object.entries(process.env).reduce<Record<string, string>>((acc, [key, value]) => {
    if (key.startsWith('REACT_APP_')) {
      acc[key.replace('REACT_APP_', '')] = value as string;
    }
    return acc;
  }, {});

  const parsedEnv = EnvSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided.
The following variables are missing or invalid:
${Object.entries(parsedEnv.error.flatten().fieldErrors)
  .map(([k, v]) => `- ${k}: ${v}`)
  .join('\n')}
`,
    );
  }

  const data = parsedEnv.data;
  const rpcUrlsArray = data.RPC_URLS_MAINNET.split(',').map((url) => url.trim());

  return {
    ...data,
    RPC_URLS_MAINNET: rpcUrlsArray,
  };
};

export const env = createEnv();
