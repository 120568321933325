import { FC, MouseEvent, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DateRangeDropdown from './DateRangeDropdown';
import { useUserPortfolioHistory } from '../../services/api/apes/useUserFetchers';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { PortfolioHistory } from '../../services/api/apes/interfaces';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/ic-info.svg';
import { SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { set, sub } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const WidgetPortfolioStats: FC = () => {
  const navigate = useNavigate();
  const today = new Date();
  const todayStartOfTheDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
  const oneWeekAgo = sub(todayStartOfTheDay, { days: 7 });
  const oneMonthAgo = sub(todayStartOfTheDay, { months: 1 });
  const oneYearAgo = sub(todayStartOfTheDay, { years: 1 });
  const [startDate, setStartDate] = useState<number>(oneWeekAgo.getTime());
  const [endDate, setEndDate] = useState<number>(today.getTime());
  const { portfolioHistory, isLoading } = useUserPortfolioHistory(
    useSelector((state: RootState) => state.user.walletAddress),
    startDate,
    endDate,
  );
  const [portfolioHistoryData, setPortfolioHistoryData] = useState<PortfolioHistory[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [amountsUsd, setAmountsUsd] = useState<number[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<number>(7);
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [minAmount, setMinAmount] = useState<number>(0);

  const handleAddWallet = () => {
    navigate('/settings');
  };

  const switchDateRange = (days: number) => {
    switch (days) {
      case 7:
        setStartDate(oneWeekAgo.getTime());
        setEndDate(today.getTime());
        break;
      case 30:
        setStartDate(oneMonthAgo.getTime());
        setEndDate(today.getTime());
        break;
      case 365:
        setStartDate(oneYearAgo.getTime());
        setEndDate(today.getTime());
        break;
    }
  };

  const handleDateRangeClick = (e: MouseEvent<HTMLButtonElement>) => {
    const days = parseInt(e.currentTarget.value);
    setSelectedDateRange(days);
    switchDateRange(days);
  };

  useEffect(() => {
    if (!portfolioHistory || !portfolioHistory.data) {
      setPortfolioHistoryData([]);
    }

    if (portfolioHistory && portfolioHistory.data) {
      setPortfolioHistoryData(portfolioHistory.data);
    }
  }, [portfolioHistory]);

  useEffect(() => {
    if (portfolioHistoryData.length) {
      const dates = portfolioHistoryData.map((item) => item.date);
      const datesConverted = dates.map((date) => {
        const newDate = new Date(date);
        return `${newDate.toLocaleString('default', { month: 'short' })} ${newDate.getDate()}`;
      });
      const amountsUsd = portfolioHistoryData.map((item) => Math.round(item.value)).filter((item) => item > 0);
      setMaxAmount(Math.max(...amountsUsd));
      setMinAmount(Math.min(...amountsUsd));
      setDates(datesConverted);
      setAmountsUsd(amountsUsd);
    }
  }, [portfolioHistoryData]);

  const options: Highcharts.Options = {
    chart: {
      height: 180,
      backgroundColor: 'transparent',
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
      spacingBottom: 10,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      categories: dates,
      title: {
        text: undefined,
      },
      labels: {
        rotation: 0,
        step: Math.round(amountsUsd.length / 5),
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      gridLineColor: '#334155',
      gridLineWidth: 1,
      gridLineDashStyle: 'Dash',
      min: minAmount - minAmount * 0.1,
      max: maxAmount + maxAmount * 0.1,
      startOnTick: false,
      endOnTick: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, 'rgba(143, 255, 0, 0.07)'],
            [1, 'rgba(143, 255, 0, 0)'],
          ],
        },
        lineWidth: 1,
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Amount',
        type: 'area',
        data: amountsUsd,
        pointPlacement: 'on',
        color: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 0,
          },
          stops: [
            [0, '#00CDDA'],
            [1, '#8FFF00'],
          ],
        },
      },
    ],
    legend: {
      enabled: false,
    },
  };

  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Box sx={{ display: 'flex' }}>
          <CircularProgress style={{ color: '#34D399' }} />
        </Box>
      </div>
    );
  }

  return (
    <>
      <div className="headline-wrap">
        <div className="name-wrap">Portfolio Stats</div>
        {amountsUsd.length > 1 && (
          <>
            <div className="dropdown-wrap">
              <DateRangeDropdown onDateRangeChange={(days) => switchDateRange(days)} />
            </div>
            <div className="buttons-wrap">
              <button onClick={handleDateRangeClick} className={selectedDateRange === 7 ? 'active' : ''} value="7">
                7D
              </button>
              <button onClick={handleDateRangeClick} className={selectedDateRange === 30 ? 'active' : ''} value="30">
                1M
              </button>
              <button onClick={handleDateRangeClick} className={selectedDateRange === 365 ? 'active' : ''} value="365">
                1Y
              </button>
            </div>
          </>
        )}
      </div>

      {amountsUsd.length > 1 && (
        <div className="chart-wrap">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
      {amountsUsd.length <= 1 && (
        <div className="no-data-wrap">
          <span className="no-data-icon">
            <SvgIcon component={InfoIcon} />
          </span>
          <span>Add a wallet</span>
          <span>to start seeing information</span>
          <div className="button-wrap">
            <button onClick={handleAddWallet}>Add wallet</button>
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetPortfolioStats;
